export const ADMIN = 'ADMIN';
export const CLIENTADMIN = 'CLIENTADMIN';
export const LEARNER = 'LEARNER';

export function token() {
    let userObject = JSON.parse(window.localStorage.getItem('user'));
    if (userObject !== null) {
        return userObject.token;
    }
}

export function getUsername() {
    let userObject = JSON.parse(window.localStorage.getItem('user'));
    if (userObject !== null) {
        return userObject.email;
    }
}

export function isloggedIn() {
    let userObject = JSON.parse(window.localStorage.getItem('user'));
    if (userObject !== null) {
        return true;
    }

    return false;
}

export function getRole() {
    let userObject = JSON.parse(window.localStorage.getItem('user'));
    if (userObject !== null) {
        return userObject.role;
    }
    else {
        return null;
    }
}

export function userIsInRole(roleName) {
    let userObject = JSON.parse(window.localStorage.getItem('user'));
    if (userObject !== null) {
        return userObject.role === roleName || userObject.secondaryRole === roleName;
    }
    else {
        return null;
    }
}

export function getUserId() {
    let userObject = JSON.parse(window.localStorage.getItem('user'));
    if (userObject !== null) {
        return userObject.id;
    }
}
