import axios from 'axios';
import { baseUrl } from '../Infrastructure/Config/ApiConfig';
import { token } from './AuthService';

const controllerName = 'Account';

export const loginAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/Login`, data);
    return response.data;
}

export const resetPasswordAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/PasswordReset`, data);
    return response.data;
}

export const passwordResetRequestAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/PasswordResetRequest`, data);
    return response.data;
}

export const createNewClientAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/CreateClient`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const clientCreateUserAsync = async (data, role) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/Create${role}`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });

    return response.data;
}

export const getMyAccountAsync = async () => {
    const response = await axios.get(`${baseUrl()}${controllerName}/MyAccount`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const updateAccountAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/UpdateAccount`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const updatePasswordAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/UpdatePassword`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const confirmAccountGetAsync = async (userId) => {
    const response = await axios.get(`${baseUrl()}${controllerName}/ConfirmAccount/${userId}`);
    return response.data;
}

export const confirmAccountAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/ConfirmAccount`, data);
    return response.data;
}

export const confirmLearnerAccountAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/ConfirmLearnerAccount`, data);
    return response.data;
}

export const resendActivationEmailAsync = async data => {
    const response = await axios.post(`${baseUrl()}${controllerName}/ResendActivationEmail`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getAccountRegisterDetailsAsync = async () => {
    const response = await axios.get(`${baseUrl()}${controllerName}/GetAccountRegisterDetails`);
    return response.data;
}
