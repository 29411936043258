import React from 'react';

const StatsAnswer = ({ answer, percentage }) => {
    return (
        <>
            <div className="row stat-answer">
                <div className="col-6">
                    {answer.value}
                </div>
                <div className="col-6">
                    <div className="progress mt-1">
                        <div className="progress-bar" role="progressbar" style={{ "width": percentage }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            {answer.numberOfSelections}
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default StatsAnswer;