import React, { useState } from 'react';
import Card from '../Components/Card/Card';
import PrimaryButton from './../Components/PrimaryButton/PrimaryButton';
import { showToast } from '../../Infrastructure/Notifications/ToastrConfig';
import Button from './../Components/Button/Button';
import { updatePasswordAsync } from '../../Services/AccountService';

const UpdatePassword = () => {

    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: '',
        repeatPassword: ''
    });

    const [editMode, setEditMode] = useState(true);

    const toggleEditMode = () => {
        setEditMode(!editMode);
    }

    const handleChange = evt => {
        setPasswordData({ ...passwordData, [evt.target.name]: evt.target.value });
    }

    const savePassword = async () => {
        if (passwordData.newPassword !== passwordData.repeatPassword || passwordData.newPassword === '' || passwordData.repeatPassword === '') {
            showToast(false, "Passwords do not match");
            return;
        }
        let response = await updatePasswordAsync(passwordData);
        if (response.success) {
            showToast(true, "Successfully updated password");
        }
        else {
            showToast(false, response.errors[0].error);
        }
        toggleEditMode();
    }

    return (
        <div className="col-12 form">
            <Card title="Update Password">
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label>Current Password</label>
                        <input type="password" onChange={handleChange} className="form-control" name="currentPassword" placeholder="Current password" disabled={editMode} />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label>New Password</label>
                        <input type="password" onChange={handleChange} className="form-control" name="newPassword" placeholder="New password" disabled={editMode} />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Confirm New Password</label>
                        <input type="password" onChange={handleChange} className="form-control" name="repeatPassword" placeholder="Confirm new password" disabled={editMode} />
                    </div>
                </div>

                {editMode ?
                    <PrimaryButton text="Edit" func={toggleEditMode} classes="float-right mt-2" />
                    :
                    <>
                        <Button text="Update Password" func={savePassword} classes="btn-success float-right mt-2" />
                        <PrimaryButton text="Cancel" func={toggleEditMode} classes="float-right mt-2 mr-3" />
                    </>
                }
            </Card>
        </div>
    );
};

export default UpdatePassword;