import React, { useState } from 'react';
import { showToast } from '../../Infrastructure/Notifications/ToastrConfig';
import { clientCreateUserAsync } from '../../Services/AccountService';
import Card from '../../Shared/Components/Card/Card';
import PrimaryButton from '../../Shared/Components/PrimaryButton/PrimaryButton';
import Header from './../../Shared/Components/Header/Header';
import { useHistory } from 'react-router-dom';
import { validEmailRegex } from './../../Infrastructure/Config/Regexes';

const CreateLearner = () => {
    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        email: ''
    });
    const [errors, setErrors] = useState([]);
    const history = useHistory();

    const handleChange = async evt => {
        setState({ ...state, [evt.target.name]: evt.target.value });
    }

    const handleValidation = () => {
        let fields = state;
        let errors = {};
        let formIsValid = true;

        //firstName
        if (!fields["firstName"]) {
            formIsValid = false;
            errors["firstName"] = "Please enter a first name";
        }

        //lastName
        if (!fields["lastName"]) {
            formIsValid = false;
            errors["lastName"] = "Please enter a last name";
        }

        //email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Please enter an email address";
        } else {
            if (!validEmailRegex.test(fields["email"])) {
                formIsValid = false;
                errors["email"] = "Please enter a valid email address";
            }
        }

        setErrors(errors);
        return formIsValid;
    }

    const handleSubmit = async () => {
        if (handleValidation()) {
            let data = {
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email
            }
            let response = await clientCreateUserAsync(data, "learner");
            if (response.success) {
                showToast(true, "Learner created");
                history.push("/manage-learners");
            }
            else {
                showToast(false, response.errors[0].error);
            }
        }
    }

    return (
        <>
            <Header showClose="true" heading="Create Learner" subheading="Fill out the details below and an email will be sent for the learner to complete registration" icon="user" />
            <div className="container-fluid content">
                <Card title="Details">
                    <div className="form">
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label>First name</label>
                                <input value={state.firstName} className="form-control" name="firstName" id="firstName" onChange={handleChange} />
                                <small className="errorMessage">{errors.firstName}</small>
                            </div>
                            <div className="form-group col-md-6">
                                <label>Last name</label>
                                <input value={state.lastName} className="form-control" name="lastName" id="lastName" onChange={handleChange} />
                                <small className="errorMessage">{errors.lastName}</small>
                            </div>
                            <div className="form-group col-md-6">
                                <label>Email</label>
                                <input value={state.email} className="form-control" name="email" id="email" onChange={handleChange} />
                                <small className="errorMessage">{errors.email}</small>
                            </div>
                        </div>
                        <div className="form-group">
                            <PrimaryButton text="Create" func={handleSubmit} classes="float-right mt-2" />
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default CreateLearner;