import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import moment from 'moment';
import { DATE_FORMAT } from '../../../Infrastructure/Config/DateConfig';

const CourseBadge = () => {
    let location = useLocation();
    const [learnerName, setLearnerName] = useState('');

    useEffect(() => {
        let storedUser = JSON.parse(window.localStorage.getItem("user"));
        setLearnerName(storedUser.firstName + ' ' + storedUser.lastName);
    }, [])

    return (
        <div className="row mt-5">
            <div className="col-sm-6 offset-sm-3 col-md-4 offset-md-4 text-center my-5 shadow p-5">
                <img alt={location.state.course.title} className="img-fluid mb-5" src={location.state.course.badgeImageUrl} />
                <h5 className='mb-1'>{learnerName}</h5>
                <p className='mb-1'>Successfully completed</p>
                <strong><h5 className='mb-1'>{location.state.course.title}</h5></strong>
                <p className='mb-1'>on {moment(location.state.completedDate).format(DATE_FORMAT)}</p>
            </div>
        </div>
    );
};

export default CourseBadge;