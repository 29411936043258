import React from 'react';
import PrimaryButton from './../../../../../Shared/Components/PrimaryButton/PrimaryButton';

const Upload = props => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="form">
                    <div className="form-group">
                        <label>Title</label>
                        <input className="form-control" name="title" id="title" onChange={props.handleChange} />
                        <small className="errorMessage">{props.errors.title}</small>
                    </div>
                    <div className="form-group">
                        <label>Description</label>
                        <textarea style={{ "minHeight": "150px" }} className="form-control" name="description" id="description" onChange={props.handleChange} />
                        <small className="errorMessage">{props.errors.description}</small>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label>Reference</label>
                            <input className="form-control" name="reference" id="reference" onChange={props.handleChange} />
                            <small className="errorMessage">{props.errors.reference}</small>
                        </div>
                        <div className="form-group col-md-6">
                            <label>SCORM File</label>
                            <div className="custom-file">
                                <input className="custom-file-input" name="file" id="file" type="file" accept=".zip" onChange={props.handleFileChange} />
                                <label className="custom-file-label">{props.file === null ? 'Browse' : props.file.name}</label>
                                <small className="errorMessage">{props.errors.file}</small>
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Badge</label>
                            <div className="custom-file">
                                <input className="custom-file-input" name="badge" id="badge" type="file" accept=".png,.jpg,.jpeg" onChange={props.handleFileChange} />
                                <label className="custom-file-label">{props.badge === null ? 'Browse' : props.badge.name}</label>
                                <small className="errorMessage">{props.errors.badge}</small>
                            </div>
                        </div>
                    </div>
                    <PrimaryButton text="Upload" func={props.handleSubmit} classes="float-right mt-3" />
                </div>
            </div>
        </div>
    );
};

export default Upload;