import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getLearningPathwaysAsync } from '../../../Services/CourseService';
import Button from '../../../Shared/Components/Button/Button';
import Card from '../../../Shared/Components/Card/Card';
import Table from '../../../Shared/Components/Table';
import Header from './../../../Shared/Components/Header/Header';

const LearningPathways = () => {

    const [pathways, setPathways] = useState([]);

    useEffect(() => {
        fetchPathways();
    }, []);

    const fetchPathways = async () => {
        let result = await getLearningPathwaysAsync();
        if (result.learningPathways !== null) {
            setPathways(result.learningPathways);
        }
    }

    const headings = () => {
        return (
            <>
                <th>Name</th>
                <th>Number of courses</th>
                <th></th>
            </>
        )
    }

    const rowData = () => {
        return (
            <>
                {pathways.length > 0 ?
                    pathways.map(
                        (pw, i) =>
                            <tr key={i}>
                                <td>
                                    {pw.name}
                                </td>
                                <td>
                                    {pw.courses.length}
                                </td>
                                <td>
                                    <Link to={`/learning-pathway/${pw.id}`}>
                                        <span className="view fas fa-eye float-right mr-3" title="View"></span>
                                    </Link>
                                </td>
                            </tr>
                    )
                    :
                    <tr>
                        <td className="no-data" colSpan="4">
                            No learning pathways to currently display
                    </td>
                    </tr>
                }
            </>
        )
    }

    return (
        <>
            <Header heading="Learning Pathways" subheading="Current list of all available pathways" icon="layers" />
            <div className="container-fluid content">
                <div className="row">
                    <div className="col-12">
                        <Card>
                            <div className="float-left">
                                <Button text="Create New" classes="btn btn-success" link="/create-pathway" />
                            </div>
                        </Card>
                        <Card>
                            <div className="col-12 p-0">
                                {/* <div className="form-group float-left">
                                    <input name="searchTerm" className="form-control" placeholder="Search" />
                                </div>
                                <div className="form-group float-right ml-3">
                                    <select className="form-control" value="10" name="pageSize">
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={30}>30</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={250}>250</option>
                                        <option value={500}>500</option>
                                    </select>
                                </div> */}
                            </div>
                            <Table headings={headings} rowData={rowData} />
                        </Card>
                    </div>
                </div>
            </div>
        </ >
    );
};

export default LearningPathways;