import React, { useState } from 'react';
import { loginAsync } from '../../Services/AccountService';
import ForgotPassword from './ForgotPassword';
import LoginBox from './LoginBox';

const Login = props => {
    const LOGIN = "login";
    const FORGOT = "forgot";

    const [state, setState] = useState({ username: "", password: "" });
    const [componentState, setComponentState] = useState(LOGIN);
    const [message, setMessage] = useState("");

    const login = async () => {
        let loginData = {
            username: state.username,
            password: state.password
        }

        let loginResponse = await loginAsync(loginData);
        if (loginResponse.succeeded) {
            window.localStorage.setItem("user", JSON.stringify(loginResponse.user));
            props.setIsLoggedIn(true);
        }
        else {
            setMessage(loginResponse.message);
        }
    }

    const handleChange = async evt => {
        setState({ ...state, [evt.target.name]: evt.target.value });
    }

    return (
        <div className="row flex-box-container">
            {componentState === LOGIN ?
                <LoginBox login={login}
                    handleChange={handleChange}
                    forgot={() => { setComponentState(FORGOT) }}
                    message={message}
                />
                :
                <ForgotPassword cancel={() => { setComponentState(LOGIN) }} />
            }
        </div>
    );
};

export default Login;