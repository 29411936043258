import React, { useEffect, useState } from 'react';
import './App.scss';
import Platform from './Platform';
import AnnonymousRoutes from './Routes/AnonnymousRoutes';
import Loading from './Shared/Loading';
import TSAFavicon from './Assets/tsa-favicon.ico';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (window.location.href.includes('thesustainabilityacademy')) {
      let link = document.querySelector("link[rel~='icon']");
      link.href = TSAFavicon;
      document.title = "TheSustainabilityAcademy | eLearning"
    }
    let storedUser = JSON.parse(window.localStorage.getItem("user"));

    if (storedUser !== null) {
      if (storedUser.expires === undefined || storedUser.expires < Date.now()) {
        window.localStorage.removeItem("user");
        setIsLoggedIn(false);
        setIsLoading(false);
      }
      setIsLoggedIn(true);
      setIsLoading(false);
    }
    else {
      setIsLoggedIn(false);
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      {
        isLoading ?
          <Loading />
          :
          <>
            {isLoggedIn ?
              <Platform setIsLoggedIn />
              :
              <AnnonymousRoutes setIsLoggedIn={setIsLoggedIn} />
            }
          </>
      }
    </>
  );
};

export default App;