import React, { useEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import { Router, Switch } from 'react-router-dom';
import MainLayout from './Layout/MainLayout';
import { ADMIN, CLIENTADMIN, LEARNER, userIsInRole } from './Services/AuthService';
import AdminRoutes from './Routes/AdminRoutes';
import ClientRoutes from './Routes/ClientRoutes';
import LearnerRoutes from './Routes/LearnerRoutes';
import ThemeContextProvider from './Infrastructure/Context/ThemeContext';
import { getThemeAsync } from './Services/ThemeService';
import Loading from './Shared/Loading';
import '../node_modules/bootstrap/dist/js/bootstrap';
import LogoutModal from './Shared/Account/LogoutModal';

const history = createBrowserHistory();

const Platform = ({ setIsLoggedIn }) => {

    const [theme, setTheme] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getClientTheme();
        setTimeout(() => {
            setLoading(false);
        }, 2500)
    }, [])

    const getClientTheme = async () => {
        let response = await getThemeAsync();
        if (response.success) {
            setTheme(response.config)
            let link = document.querySelector("link[rel~='icon']");
            link.href = response.config.faviconUrl;
            let storedUser = JSON.parse(window.localStorage.getItem("user"));
            document.title = storedUser.firstName + " " + storedUser.lastName + " | eLearning";
        }
        else {
            window.localStorage.removeItem("user");
            window.location.href = `/`;
            setIsLoggedIn(false)
        }
    }

    return (
        <>
            <Router history={history}>
                <ThemeContextProvider theme={theme}>
                    <LogoutModal />
                    {loading ?
                        <Loading />
                        :
                        <MainLayout>
                            <Switch>
                                {userIsInRole(ADMIN) && <AdminRoutes />}
                                {userIsInRole(CLIENTADMIN) && <ClientRoutes />}
                                {userIsInRole(LEARNER) && <LearnerRoutes />}
                            </Switch>
                        </MainLayout>
                    }
                </ThemeContextProvider>
            </Router>
        </>
    );
};

export default Platform;