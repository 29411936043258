import React from 'react';

const LogoutModal = props => {

    const logOut = () => {
        window.localStorage.removeItem("user");
        window.location.href = `/`;
    }

    return (
        <div className="modal fade" id="logoutModal" aria-hidden="true">
            <div className="modal-dialog modal-sm">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">Logout</h5>
                    </div>
                    <div className="modal-body">
                        <p>Are you sure you want to log out?</p>
                        <button data-dismiss="modal" className="btn btn-success float-right" onClick={logOut}>Logout</button>
                        <button data-dismiss="modal" className="btn btn-danger float-right mr-3">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LogoutModal;