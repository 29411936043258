import React from 'react';
// import LearnerElearningNav from './Navs/LearnerElearningNav';

const LearnerMenu = props => {
    return (
        <>
            {/* <LearnerElearningNav context={props.context} manipulateColours={props.manipulateColours} /> */}
        </>
    );
};

export default LearnerMenu;