import React from 'react';

const Modal = ({ title, modalId, bodyText, buttonText, buttonFunc }) => {
    return (

        <div className="modal fade" id={modalId} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <span className="fa fa-times clickable" data-dismiss="modal" aria-label="Close"></span>
                    </div>
                    <div className="modal-body">
                        {bodyText}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                        <button type="button" onClick={buttonFunc} data-dismiss="modal" className="btn btn-success">{buttonText}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;