import React, { useEffect, useState } from 'react';
import Table from '../../Shared/Components/Table';
import Card from '../../Shared/Components/Card/Card';
import Header from '../../Shared/Components/Header/Header';
import { getClientMonthlyOverviewAsync, getMonthlyOverviewAsync } from '../../Services/ReportService';

const Reporting = () => {

    const [clients, setClients] = useState([])
    const [toDate, setToDate] = useState(null)
    const [fromDate, setFromDate] = useState(null)

    useEffect(() => {
    }, [])

    const getOverview = async () => {
        if (fromDate !== null && toDate !== null) {
            let response = await getMonthlyOverviewAsync(fromDate, toDate);
            setClients(response.rows);
        }
        else {
            alert('Please select both a from and to date')
        }
    }

    const handleToChange = evt => {
        setToDate(evt.target.value)
    }

    const handleFromChange = evt => {
        setFromDate(evt.target.value)
    }

    const handleDownload = async (clientId, clientName) => {
        await getClientMonthlyOverviewAsync(clientId, fromDate, toDate, clientName);
    }

    const headings = () => {
        return (<>
            <th>Client name</th>
            <th>Number of learners</th>
            <th>Learner limit</th>
            <th>Courses started this period</th>
            <th>Courses completed this period</th>
            <th className="text-right">Report</th>
        </>)
    }

    const rowData = () => {
        return (
            <>
                {clients.length > 0 ?
                    clients.map(
                        (c, i) =>
                            <tr key={i}>
                                <td>{c.name}</td>
                                <td>{c.numberOfLearners}</td>
                                <td>{c.maximumNumberOfLearners}</td>
                                <td>{c.coursesStartedThisPeriod}</td>
                                <td>{c.coursesCompletedThisPeriod}</td>
                                <td className="text-center">
                                    <span onClick={() => { handleDownload(c.clientId, c.name) }} className="fa fa-download"></span>
                                </td>
                            </tr>
                    )
                    :
                    <tr>
                        <td className="no-data" colSpan="6">
                            No results to display
                        </td>
                    </tr>
                }
            </>)
    }
    return (
        <>
            <Header heading="Clients" subheading="Client reporting" icon="customer" />
            <div className="container-fluid content">
                <Card title="Courses overview">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="form-group float-left ml-3">
                                <input type="date" name="toDate" onChange={handleFromChange} className="form-control" />
                            </div>
                            <div className="form-group float-left ml-3">
                                <input type="date" name="fromDate" onChange={handleToChange} className="form-control" />
                            </div>
                            <div className="form-group float-right mr-3">
                                <button className="btn btn-primary" onClick={getOverview}>Apply</button>
                            </div>
                        </div>
                        <div className="col-12">
                            <Table headings={headings} rowData={rowData} />
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default Reporting;