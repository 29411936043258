import React, { useEffect, useState } from 'react';
import { getDashboardAsync } from '../../Services/DashboardService';
import Header from './../../Shared/Components/Header/Header';
import CompletionBadge from '../ELearning/Courses/CompletionBadge';
import Card from '../../Shared/Components/Card/Card';
import Table from '../../Shared/Components/Table';
import { Link } from 'react-router-dom';
import { getStatus } from '../../Infrastructure/Utilities/LearnerProgressHelper';
import { Line } from 'rc-progress';
import { getStatusBadge } from '../../Infrastructure/Utilities/LearnerProgressHelper';

const LearnerDashboard = () => {
    const [dashboard, setDashboard] = useState({ courses: [{ course: { badgeImageUrl: '' } }], learningPathways: [] });

    useEffect(() => {
        fetchDashboard();
    }, []);

    const fetchDashboard = async () => {
        let response = await getDashboardAsync("Learner");
        setDashboard(response);
    }

    const headings = () => {
        return (<>
            <th>Course name</th>
            <th>Status</th>
            <th>Result</th>
            <th>Progress</th>
            <th>score</th>
            <th></th>
        </>)
    }

    const rowData = () => {
        return (
            <>
                {dashboard.courses.map(
                    (x, i) =>
                        <tr key={i}>
                            <td>{x.course !== null && x.course.title}</td>
                            <td>
                                {getStatus(x.completedDateTime, x.startedDateTime)}
                            </td>
                            <td>{getStatusBadge(x.lessonStatus)}</td>
                            <td><Line percent={x.progress} strokeWidth="3" strokeColor="#009fdf" strokeLinecap="round" /></td>
                            <td>{Math.round(x.scorePercentage)}%</td>
                            <td>
                                <Link to={`/engage/${x.course !== null && x.course.id}`}>
                                    <span className="view fas fa-eye float-right mr-3" title="View"></span>
                                </Link>
                            </td>
                        </tr>
                )
                }
            </>
        )
    }

    return (
        <>
            <Header heading="Dashboard" subheading="Current overview of the eLearning analytics" icon="bar-chart" />
            <div className="container-fluid content">
                <div className="row">
                    <div className="col-12">
                        <Card title="Courses" >
                            <Table headings={headings} rowData={rowData} />
                        </Card>
                    </div>
                    <div className="col-12">
                        <Card title="Badges" >
                            {dashboard.courses.map(
                                (c, i) => <CompletionBadge key={i} completed={c.lessonStatus === 4} image={c.course !== null ? c.course.badgeImageUrl : ''} courseId={c.course !== null && c.course.id} />
                            )}
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LearnerDashboard;