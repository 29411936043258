import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Shared/Components/Header/Header';
import Button from './../../Shared/Components/Button/Button';
import Card from '../../Shared/Components/Card/Card';
import { getAllBlogPostsAsync } from '../../Services/BlogService';
import Table from '../../Shared/Components/Table';

const Blog = () => {

    const [pageNumber, setpageNumber] = useState(1);
    const [blogPosts, setBlogPosts] = useState([]);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        const getPosts = async () => {
            let response = await getAllBlogPostsAsync(pageNumber);
            setBlogPosts(response.blogPosts);
            setTotalPages(response.totalPages);
        }

        getPosts();
    }, [pageNumber])

    const headings = () => {
        return (<>
            <th>Title</th>
            <th>Url</th>
            <th>Published</th>
            <th></th>
        </>)
    }

    const rowData = () => {
        return (<>
            {blogPosts.length > 0 ?
                blogPosts.map(
                    (u, i) =>
                        <tr key={i}>
                            <td>
                                {u.title}
                            </td>
                            <td>
                                {u.urlSlug}
                            </td>
                            <td>
                                {u.published ? "Yes" : "No"}
                            </td>
                            <td>
                                <span className="view fas fa-trash float-right mr-3" onClick={() => { alert("Needs a modal") }} title="Delete"></span>
                                <Link to={`blog/posts/${u.urlSlug}`}>
                                    <span className="view fas fa-eye float-right mr-3" title="View"></span>
                                </Link>
                            </td>
                        </tr>
                )
                :
                <tr>
                    <td className="no-data" colSpan="4">
                        No blog posts to display
                    </td>
                </tr>
            }
        </>)
    }
    return (
        <>
            <Header heading="Blog" subheading="Create and edit blogposts" icon="folder" />
            <div className="container-fluid content">
                <Card>
                    <div className="float-left">
                        <Button text="Create New" classes="btn btn-success" link="blog/new-post" />
                    </div>
                </Card>
                <Card title="Posts">
                    <Table headings={headings} rowData={rowData} />
                </Card>
            </div>
        </>
    );
};

export default Blog;