import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PrimaryButton from '../../Shared/Components/PrimaryButton/PrimaryButton';
import Table from '../../Shared/Components/Table';
import Header from './../../Shared/Components/Header/Header';
import Card from './../../Shared/Components/Card/Card';
import Button from './../../Shared/Components/Button/Button';
import { getUsersAsync } from '../../Services/UserService';

const Users = () => {

    const [users, setUsers] = useState([]);

    const [state, setState] = useState({
        pageNumber: 1,
        pageSize: 10,
        searchTerm: '',
        totalUsers: 0
    });

    useEffect(() => {
        const search = async () => {
            let response = await getUsersAsync(state.pageNumber, state.pageSize, state.searchTerm);
            if (state.pageNumber === 1) {
                setUsers(response.users)
            }
            else {
                setUsers(u => ({ users: u.concat(response.users) }));
            }
            setState(s => ({
                totalUsers: response.totalPages,
                pageNumber: s.pageNumber,
                pageSize: s.pageSize,
                searchTerm: s.searchTerm
            }))
        }
        search();
    }, [state.pageSize, state.pageNumber, state.searchTerm]);

    const handleChange = async evt => {
        setState({ ...state, [evt.target.name]: evt.target.value });
    }

    const handlePageSizeChange = async evt => {
        setState({ ...state, pageSize: evt.target.value, pageNumber: 1 });
    }

    const loadMore = () => {
        if (users.length <= state.totalusers) {
            setState({ ...state, pageNumber: state.pageNumber + 1 });
        }
    }

    const headings = () => {
        return (<>
            <th>First name</th>
            <th>Last name</th>
            <th>Email</th>
            <th></th>
        </>)
    }

    const rowData = () => {
        return (<>
            {users.length > 0 ?
                users.map(
                    (u, i) =>
                        <tr key={i}>
                            <td>
                                {u.firstName}
                            </td>
                            <td>
                                {u.lastName}
                            </td>
                            <td>
                                {u.email}
                            </td>
                            <td>
                                <Link to={`/user/${u.urlToken}`}>
                                    <span className="view fas fa-eye float-right mr-3" title="View"></span>
                                </Link>
                            </td>
                        </tr>
                )
                :
                <tr>
                    <td className="no-data" colSpan="4">
                        No users to currently display
                    </td>
                </tr>
            }
        </>)
    }

    return (
        <>
            <Header heading="Manage Admin Users" subheading="Current list of all admin users" icon="network" />
            <div className="container-fluid content">
                <Card>
                    <div className="float-left">
                        <Button text="Create New" classes="btn btn-success" link="/create-user" />
                    </div>
                </Card>

                <Card>
                    <div className="col-12 p-0">
                        <div className="form-group float-left">
                            <input onChange={handleChange} name="searchTerm" className="form-control" placeholder="Search" />
                        </div>
                        <div className="form-group float-right ml-3">
                            <select className="form-control" value={state.pageSize} onChange={handlePageSizeChange} name="pageSize">
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={250}>250</option>
                                <option value={500}>500</option>
                            </select>
                        </div>
                    </div>
                    <Table headings={headings} rowData={rowData} />
                    <div className="mt-3 text-center">
                        <div className="small mb-3">
                            <span>Showing {users.length} of {state.totalUsers}</span>
                        </div>
                        <PrimaryButton text="Load more"
                            classes="clickable "
                            func={loadMore}
                            disabled={users.length >= state.totalUsers} />
                    </div>
                </Card>
            </div>
        </>
    );
};

export default Users;