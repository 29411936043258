import React, { useEffect, useState } from 'react';
import { getWellnessOverviewAsync } from '../../Services/ReportService';
import { getAllWellnessResultsAsync, getOrganisationsAsync, getWellnessResultsByOrgAsync } from '../../Services/WellnessService';
import Card from '../../Shared/Components/Card/Card';
import Header from '../../Shared/Components/Header/Header';
import Table from '../../Shared/Components/Table';

const Wellness = () => {
    const [organisations, setOrganisations] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState('');

    const [results, setResults] = useState({
        overallWellnessNumber: 0,
        questionnaires: [{
            questions: [],
            participant: { firstName: '', lastName: '', email: '', organisation: '' },
            wellnessNumber: 0
        }]
    });

    useEffect(() => {
        const getOrgs = async () => {
            let response = await getOrganisationsAsync();
            setOrganisations(response);
        }
        getOrgs();

    }, [])

    useEffect(() => {

        if (selectedOrg === '') {
            const getAll = async () => {
                let response = await getAllWellnessResultsAsync();
                setResults(response);
            }
            getAll();
        }
        else {
            const getForOrg = async () => {
                let response = await getWellnessResultsByOrgAsync(selectedOrg);
                setResults(response);
            }
            getForOrg();
        }
    }, [selectedOrg])

    const handleChange = evt => setSelectedOrg(evt.target.value);

    const headings = () => {
        return (
            <>
                <th>Name</th>
                <th>Email</th>
                <th>Organisation</th>
                <th>Wellness Number</th>
            </>)
    }

    const rowData = () => {
        return (
            <>
                {results.questionnaires.length > 0 ?
                    results.questionnaires.map(
                        (r, i) =>
                            <tr key={i}>
                                <td>{r.participant.firstName} {r.participant.lastName}</td>
                                <td>{r.participant.email}</td>
                                <td>{r.participant.organisation}</td>
                                <td>{r.wellnessNumber}</td>
                            </tr>
                    )
                    :
                    <tr>
                        <td className="no-data" colSpan="6">
                            No results to display
                        </td>
                    </tr>
                }
            </>)
    }

    const downloadReport = async () => {
        await getWellnessOverviewAsync()
    }

    return (
        <>
            <Header heading="Wellness" subheading="Wellness questionnaire results" icon="stats-up" />
            <div className="container-fluid content insights">
                <Card title="Results">
                    <div className="row">
                        <div className="col-sm-12 col-md-4 pb-3">
                            <select value={selectedOrg} onChange={handleChange}>
                                <option value="">Not selected</option>
                                {organisations.map((o, i) => <>
                                    <option key={i} value={o}>{o}</option>
                                </>)}
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-4 pb-3 text-center">
                            <button onClick={downloadReport} className='btn btn-primary'>Download results</button>
                        </div>
                        <div className="col-sm-12 col-md-4 pb-3 text-center">
                            <h4>Average wellness number</h4>
                            <h3>{results.overallWellnessNumber}</h3>
                        </div>
                        <div className="col-12">
                            <Table headings={headings} rowData={rowData} />
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default Wellness;