import React, { useEffect, useState } from 'react';
import StatsAnswer from './StatsAnswer';
import './StatQuestion.scss';
import Card from './../../../../Shared/Components/Card/Card';

const StatsQuestion = ({ question }) => {

    const calculatePercentage = (a) => {
        let totalAnswers = 0;

        question.answers.forEach(answer => {
            totalAnswers += answer.numberOfSelections;
        });
        return a.numberOfSelections / totalAnswers * 100 + "%";
    }

    return (
        <Card title={question.value}>
            <div className="row mb-4">
                <div className="col-12 stats-feedback ">
                    {question.answers.map((a, i) =>
                        <StatsAnswer key={i} answer={a} percentage={calculatePercentage(a)} />
                    )
                    }
                </div>
            </div>
        </Card>
    );
};

export default StatsQuestion;