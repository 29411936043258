import React from 'react';
import Logo from '../../Assets/AcademyOneLogoBlack.png';
import './Login.scss';
import Card from './../Components/Card/Card';
import TSALogo from '../../Assets/TSALogo.png';

const LoginBox = props => {
    return (
        <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4 mx-auto flex-box">
            <Card classes="login-box">
                <div className="text-center">
                    {window.location.href.includes('thesustainabilityacademy') ?
                        <img alt="Login logo" className="img-fluid login-logo" src={TSALogo} />
                        :
                        <img alt="Login logo" className="img-fluid login-logo" src={Logo} />
                    }
                </div>
                <div className="text-center mb-3">
                    <span className="error">{props.message}</span>
                </div>
                <div className="form-group">
                    <input className="form-control" placeholder="Email address" name="username" id="username" onChange={props.handleChange} autoComplete="true" />
                </div>
                <div className="form-group pb-2">
                    <input className="form-control" placeholder="Password" name="password" id="password" type="password" onChange={props.handleChange} autoComplete="true" />
                </div>
                <div className="form-group mt-3 d-flex justify-content-between align-items-center">
                    <span className="float-left clickable forgot" onClick={props.forgot}>Forgotten Password?</span>
                    {window.location.href.includes('thesustainabilityacademy') ?
                        <button className="btn login-btn-tsa float-right" onClick={props.login}>Login</button>
                        :
                        <button className="btn login-btn float-right" onClick={props.login}>Login</button>
                    }
                </div>
            </Card >
        </div >
    );
};

export default LoginBox;