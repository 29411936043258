import React, { useEffect, useState } from 'react';
import { getStatsAsync } from '../../Services/AdminService';
import CourseStats from '../../Shared/Components/StatCard/CourseStats';
import Header from './../../Shared/Components/Header/Header';
import StatCard from './../../Shared/Components/StatCard/StatCard';

const AdminDashboard = () => {
    const [stats, setStats] = useState({ courses: [] });

    useEffect(() => {
        getDashboard();
    }, [])

    const getDashboard = async () => {
        let response = await getStatsAsync();
        setStats(response);
    }

    return (
        <>
            <Header heading="Dashboard" subheading="Current overview of the eLearning analytics" icon="bar-chart" />
            <div className="container-fluid content">
                <div className="row">
                    <StatCard
                        title="Clients"
                        stat={stats.numberOfClients}
                        subtext="Total number of Clients"
                    />
                    <StatCard
                        title="Learners"
                        stat={stats.numberOfLearners}
                        subtext="Total number of Learners"
                    />
                    <StatCard
                        title="Courses"
                        stat={stats.numberOfCourses}
                        subtext="Total number of courses"
                    />
                    <StatCard
                        title="Pathways"
                        stat={stats.numberOfPathways}
                        subtext="Total number of pathways"
                    />
                    <StatCard
                        title="Logins"
                        stat={stats.numberOfLoginsThisMonth}
                        subtext="Number of Logins this month"
                    />
                    <StatCard
                        title="Courses Started"
                        stat={stats.coursesStartedThisMonth}
                        subtext="Courses started this month"
                    />
                    <StatCard
                        title="Completed Courses"
                        stat={stats.coursesCompletedThisMonth}
                        subtext="Courses completed this month"
                    />
                    <CourseStats courses={stats.courses} />
                </div>
            </div>
        </>
    );
};

export default AdminDashboard;