import React from 'react';
import { Route } from 'react-router-dom';
import ClientDashboard from '../Pages/Dashboard/ClientDashboard';
import Engage from '../Pages/ELearning/Courses/Engage';
import FeedbackStats from '../Pages/ELearning/FeedbackForms/FeedbackStats';
import ClientLearningPathway from '../Pages/ELearning/LearningPathways/ClientLearningPathway';
import ClientLearningPathways from '../Pages/ELearning/LearningPathways/ClientLearningPathways';
import PrivacyPolicy from '../Pages/GDPR/PrivacyPolicy';
import CreateLearner from '../Pages/Learners/CreateLearner';
import Learner from '../Pages/Learners/Learner';
import LearnerProgress from '../Pages/Learners/LearnerProgress';
import ManageLearners from '../Pages/Learners/ManageLearners';
import UploadLearners from '../Pages/Learners/UploadLearners';
import ThemeSettings from '../Pages/Settings/ThemeSettings';
import CreateUser from '../Pages/User/CreateUser';
import User from '../Pages/User/User';
import Users from '../Pages/User/Users';
import MyAccount from '../Shared/Account/MyAccount';

const ClientRoutes = () => {
    return (
        <>
            <Route exact path="/" component={ClientDashboard} />
            <Route exact path="/my-account" component={MyAccount} />

            <Route exact path="/settings/theme" component={ThemeSettings} />

            <Route exact path="/manage-learners" component={ManageLearners} />
            <Route exact path="/learner-progress" component={LearnerProgress} />
            <Route exact path="/upload-learners" component={UploadLearners} />
            <Route exact path="/learner/:urlToken" component={Learner} />
            <Route exact path="/create-learner" component={CreateLearner} />

            <Route exact path="/feedback-stats/:id" component={FeedbackStats} />

            <Route exact path="/learning-pathways" component={ClientLearningPathways} />
            <Route exact path="/learning-pathway/:id" component={ClientLearningPathway} />

            <Route exact path="/user/:urlToken" component={User} />
            <Route exact path="/users" component={Users} />
            <Route exact path="/create-user" component={CreateUser} />

            <Route exact path="/gdpr/privacy" component={PrivacyPolicy} />
            <Route exact path="/engage/:id" component={Engage} />

        </>
    );
};

export default ClientRoutes;