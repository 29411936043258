export const getStatusBadge = (status) => {
    if (status === 4) {
        return <span className="badge badge-success">Passed</span>
    }
    else if (status === 3) {
        return <span className="badge badge-danger">Failed</span>
    }
    else {
        return <span className="badge badge-warning">Assessment Not Taken</span>
    }
}

export const getStatus = (completedDateTime, startedDateTime) => {
    if (completedDateTime !== null) {
        return <span className="badge badge-success">Completed</span>
    }
    else if (startedDateTime === null) {
        return <span className="badge badge-danger">Not Started</span>
    }
    else {
        return <span className="badge badge-warning">In Progress</span>
    }
}