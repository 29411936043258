import React, { useContext } from 'react';
import { ThemeContext } from '../../Infrastructure/Context/ThemeContext';
import { ADMIN, CLIENTADMIN, LEARNER, userIsInRole } from '../../Services/AuthService';
import AdminMenu from './Navigation/AdminMenu';
import './Sidebar.scss';
import DashboardNav from './Navigation/Navs/DashboardNav';
import ClientMenu from './Navigation/ClientMenu';
import AccountDetailsNav from './Navigation/Navs/AccountDetailsNav';
import { lightOrDark } from '../../Infrastructure/ManipulateColors';
import LearnerMenu from './Navigation/LearnerMenu';

const Sidebar = () => {
    const context = useContext(ThemeContext);

    const renderMenu = () => {
        if (userIsInRole(ADMIN)) {
            return <AdminMenu context={context} manipulateColours={lightOrDark} />
        }
        if (userIsInRole(CLIENTADMIN)) {
            return <ClientMenu context={context} manipulateColours={lightOrDark} />
        }
        if (userIsInRole(LEARNER)) {
            return <LearnerMenu context={context} manipulateColours={lightOrDark} />
        }
    }

    return (
        <>
            <div id="sidebar" className="" style={{ "background": context.primaryNavColour }} >
                <div className="sidebar-logo" style={{ borderBottom: `1px solid ${lightOrDark(context.primaryNavColour)}` }}>
                    <img alt="sidebar-logo" className="logo mx-auto d-block img-fluid p-4" src={context.logoUrl} />
                </div>
                <div className="sidebar-menu">
                    <ul>
                        <AccountDetailsNav context={context} manipulateColours={lightOrDark} />
                        <DashboardNav context={context} />
                        {renderMenu()}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Sidebar;