import React from 'react';
import Header from './../Components/Header/Header';
import UpdatePassword from './UpdatePassword';
import UpdateUserDetails from './Components/UpdateUserDetails';

const MyAccount = () => {
    return (
        <>
            <Header heading="Account Details" subheading="Update your details" icon="user" />
            <div className="container-fluid content">
                <div className="row">
                    <UpdateUserDetails />
                    <UpdatePassword />
                </div>
            </div>
        </>
    );
};

export default MyAccount;