import React from 'react';
import { NavLink } from 'react-router-dom';
import LineIcon from 'react-lineicons';
import { blackOrWhite, menuHover } from '../../../../Infrastructure/ManipulateColors';

const ClientsNav = props => {
    return (
        <>
            <li data-toggle="collapse" href="#clientsDropdown" role="button" style={{ "--hover-color": menuHover(props.context.primaryNavColour) }}>
                <div className="chev">
                    <LineIcon name="chevron-right" style={{ color: props.context.sidebarIconColour }} />
                </div>
                <NavLink to="#" activeClassName='sidebar-active'>
                    <LineIcon name="customer" style={{ color: props.context.sidebarIconColour }} />
                    <span className="item" style={{ color: props.context.primaryNavColour ? blackOrWhite(props.context.primaryNavColour) : "black" }}>Clients</span>
                </NavLink>
            </li>

            <div className="collapse" id="clientsDropdown">
                <div className="sidebar-submenu">
                    <ol style={{ "backgroundColor": props.manipulateColours(props.context.primaryNavColour) }}>
                        <li style={{ "--sub-menu-hover-color": menuHover(props.manipulateColours(props.context.primaryNavColour)), "--ol-color": blackOrWhite(props.context.primaryNavColour) }}><NavLink activeClassName='sidebar-active' to="/clients"><span>Clients</span></NavLink></li>
                        <li style={{ "--sub-menu-hover-color": menuHover(props.manipulateColours(props.context.primaryNavColour)), "--ol-color": blackOrWhite(props.context.primaryNavColour) }}><NavLink activeClassName='sidebar-active' to="/create-client"><span>Create Client</span></NavLink></li>
                    </ol>
                </div>
            </div>
        </>
    );
};

export default ClientsNav;