import axios from 'axios';
import { baseUrl } from '../Infrastructure/Config/ApiConfig';
import { token } from './AuthService';

const controllerName = 'RegistrationDetails';

export const addItemAsync = async (name, type) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/add${type}`, { name: name }, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const deleteItemAsync = async (id, type) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/remove${type}`, { id: id }, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}