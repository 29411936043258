import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../../Infrastructure/Context/ThemeContext';
import './Button.scss';

const Button = ({ link, func, text, disabled, classes, dataTarget, properties }) => {
    const context = useContext(ThemeContext);

    const FuncButton = () => {
        return (
            <button
                className={`btn ${classes}`}
                style={{
                    color: context.primaryNavFontColour
                }}
                onClick={func}
                disabled={disabled}
            >
                {text}
            </button>
        )
    }

    const LinkButton = () => {
        return (
            <Link to={link}>
                <button
                    className={`btn ${classes}`}
                    style={{
                        color: context.primaryNavFontColour,
                    }}
                >
                    {text}
                </button>
            </Link>
        )
    }

    const ModalButton = () => {
        return (
            <button
                className={`btn ${classes}`}
                style={{
                    color: context.primaryNavFontColour
                }}
                disabled={disabled}
                data-target={`#${dataTarget}`}
                data-toggle={"modal"}
            >
                {text}
            </button>
        )
    }

    const PropsButton = () => {
        return (
            <Link to={{ pathname: link, state: properties }}>

                <button
                    className={`btn ${classes}`}
                    style={{
                        color: context.primaryNavFontColour
                    }}
                    disabled={disabled}
                    data-target={`#${dataTarget}`}
                    data-toggle={"modal"}
                >
                    {text}
                </button>
            </Link>
        )
    }

    const renderButton = () => {
        if (properties !== undefined) {
            return <PropsButton />
        }
        if (link === undefined && func === undefined) {
            return <ModalButton />
        }
        if (dataTarget === undefined && func === undefined) {
            return <LinkButton />
        }
        if (dataTarget === undefined && link === undefined) {
            return <FuncButton />
        }
    }

    return (
        <>
            {renderButton()}
        </>
    );
};

export default Button;

