import axios from 'axios';
import { baseUrl } from '../Infrastructure/Config/ApiConfig';
import { getRole, token } from './AuthService';
import download from 'downloadjs';

const base = `${baseUrl()}User`;

export const getUsersAsync = async (pageNumber, pageSize, searchTerm) => {

    let searchQuery = '';
    if (searchTerm !== '') {
        searchQuery = '&searchTerm=' + searchTerm
    }

    const response = await axios.get(`${base}/get${getRole()}users/?pageNumber=${pageNumber}&pageSize=${pageSize}${searchQuery}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });

    return response.data;
}

export const getUserByIdAsync = async (userId) => {
    const response = await axios.get(`${base}/Get${getRole()}UserById/${userId}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getUserByUrlTokenAsync = async (urlToken) => {
    const response = await axios.get(`${base}/Get${getRole()}UserByUrlToken/${urlToken}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const deleteUserAsync = async data => {
    const response = await axios.post(`${base}/DeleteUser`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const updateUserAsync = async data => {
    const response = await axios.post(`${base}/UpdateUser`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const deleteUsersAsync = async (userIds) => {

    const response = await axios.post(`${base}/deleteusers`, userIds, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });

    return response.data;
}

export const archiveUsersAsync = async (userIds) => {

    const response = await axios.post(`${base}/archive`, userIds, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });

    return response.data;
}

export const exportUsersAsync = async (userIds) => {

    const response = await axios.post(`${base}/exportusers`, userIds, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    })
        .then(response => {
            const content = response.headers['content-type'];
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let yyyy = today.getFullYear();
            today = dd + '-' + mm + '-' + yyyy;

            const fileName = `Learner-Export-${today}.csv`
            download(response.data, fileName, content);
            return true;
        })
        .catch(
            error => console.log(error)
        )

    return response;
}