import React, { useContext } from 'react';
import { ThemeContext } from '../Infrastructure/Context/ThemeContext';

const Loading = () => {
    const context = useContext(ThemeContext);

    return (
        <div className="content flex-box-container">
            <div className="loading loading-container">
                {context !== undefined &&
                    <div className="col-10 col-sm-8 col-md-6 col-lg-4 mx-auto">
                        <div className="text-center">
                            {context.loadingLogoUrl &&
                                <img className={" img-fluid"} src={context.loadingLogoUrl} alt="loading" style={{ maxHeight: "250px" }} />
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Loading;