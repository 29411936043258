import React, { useState } from 'react';
import Table from '../../../Shared/Components/Table';
import { Link } from 'react-router-dom';
import { assignClientToPathwayAsync, removePathwayFromClientAsync } from './../../../Services/CourseService';
import { showToast } from '../../../Infrastructure/Notifications/ToastrConfig';
import Card from './../../../Shared/Components/Card/Card';
import PrimaryButton from './../../../Shared/Components/PrimaryButton/PrimaryButton';
import Modal from '../../../Shared/Components/Modal/Modal';
import { getCompletedPathwaysAsync } from '../../../Services/ReportService';

const AssignedPathways = ({ pathways, learningPathways, clientId, getClient }) => {

    const [learningPathwayId, setLearningPathwayId] = useState('');
    const [pathwayToRemove, setPathwayToRemove] = useState({});

    const assignToPathway = async () => {

        let data = {
            clientId: clientId,
            learningPathwayId: learningPathwayId
        }

        let response = await assignClientToPathwayAsync(data);
        if (response) {
            showToast(true, "Assigned pathway to Client");
            setLearningPathwayId('');
        }
        else {
            showToast(false, "Something went wrong");
            setLearningPathwayId('');
        }
        getClient();
    }

    const removeFromPathway = async () => {

        let data = {
            clientId: clientId,
            learningPathwayId: pathwayToRemove.id
        }

        let response = await removePathwayFromClientAsync(data);
        if (response) {
            showToast(true, "Pathway removed from Client")
        }
        else {
            showToast(false, "Something went wrong")
        }
        getClient();
    }

    const handleChange = evt => {
        setLearningPathwayId(evt.target.value);
    }


    const downloadReport = async (id) => {
        getCompletedPathwaysAsync(clientId, id);
    }

    const headings = () => {
        return (<>
            <th>Name</th>
            <th></th>
            <th></th>
        </>)
    }

    const rowData = () => {
        return (<>
            {pathways.length > 0 ?
                pathways.map(
                    (u, i) =>
                        <tr key={i}>
                            <td>
                                {u.name}
                            </td>
                            <td>
                                <span onClick={() => { downloadReport(u.id) }} className="view fas fa-download float-right mr-5" title="Download report"></span>
                            </td>
                            <td>
                                <Link to={`/learning-pathway/${u.id}`}>
                                    <span className="view fas fa-eye float-right mr-3" title="View"></span>
                                </Link>
                                <span data-target="#removePathway" data-toggle="modal" onClick={() => { setPathwayToRemove(u) }} className="fa fa-trash"></span>
                            </td>
                        </tr>
                )
                :
                <tr>
                    <td className="no-data" colSpan="4">
                        No pathways have yet been assigned to this client
                    </td>
                </tr>
            }
        </>)
    }

    return (
        <>
            <Modal
                modalId="removePathway"
                bodyText={`Are you sure you want to remove ${pathwayToRemove.name} from this client?`}
                buttonFunc={removeFromPathway}
                buttonText="Remove"
                title={`Remove Leaning pathway`}
            />
            <div className="col-12">
                <Card title="Assigned Pathways">
                    <div className="col-4 p-0 float-left mb-3 pb-1">
                        <div className="input-group">
                            <select value={learningPathwayId} onChange={handleChange} name="learningPathwayId" className="form-control">
                                <option value=''>Assign pathway...</option>
                                {learningPathways.map(lp =>
                                    <option value={lp.id}>{lp.name}</option>
                                )}
                            </select>
                            <div className="input-group-append">
                                <PrimaryButton text="Assign" func={assignToPathway} classes="action-btn" />
                            </div>
                        </div>
                    </div>
                    <Table rowData={rowData} headings={headings} />
                </Card>
            </div>
        </>
    );
};

export default AssignedPathways;