import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Line } from 'rc-progress';
import { getCourseProgressAsync, getClientProgressCoursesAsync } from './../../../Services/CourseService';
import { ThemeContext } from './../../../Infrastructure/Context/ThemeContext';
import Card from './../../../Shared/Components/Card/Card';
import Table from './../../../Shared/Components/Table';
import { getStatusBadge, getStatus } from './../../../Infrastructure/Utilities/LearnerProgressHelper';
import PaginationControls from '../../../Shared/Components/PaginationControls';
import Button from '../../../Shared/Components/Button/Button';

const ClientLearnerProgress = ({ clientId }) => {
    const [course, setCourse] = useState({});
    const [hasLearnerProgress, setHasLearnerProgress] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [courseId, setCourseId] = useState(null);
    const [courses, setCourses] = useState([]);
    const context = useContext(ThemeContext);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [stats, setStats] = useState({});

    useEffect(() => {
        if (clientId !== undefined) {
            getCourses();
        }
    }, [clientId]);

    useEffect(() => {
        const getCourse = async () => {
            if (courseId !== null) {
                let response = await getCourseProgressAsync(courseId, clientId, pageNumber);
                setCourse(response);
                setTotalPages(response.totalPages);
            }
        }
        if (courses.length > 0) {
            getCourse();
        }
    }, [courseId, courses, pageNumber]);

    useEffect(() => {
        if (course.courses !== undefined) {
            setIsLoaded(true);
            setHasLearnerProgress(true);
        }
        setIsLoaded(true);
    }, [course]);

    const updateCourseId = (evt) => {
        setCourseId(evt.target.value);
    }

    const getCourses = async () => {
        let response = await getClientProgressCoursesAsync(clientId);
        if (response.courses.length > 0) {
            setCourses(response.courses);
            setCourseId(response.courses[0].id);
        }
        else {

        }
    }

    const nextPage = () => {
        if (pageNumber < totalPages) {
            setPageNumber(pageNumber + 1);
        }
    }

    const previousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }

    const headings = () => {
        return (
            <>
                <th>Name</th>
                <th>Status</th>
                <th>Course Progress</th>
                <th>Score</th>
                <th>Result</th>
                <th>No. of Restarts</th>
                <th></th>
            </>
        )
    }

    const rowData = () => {
        return (
            <>
                {course.courses.map(
                    (c, i) =>
                        <tr key={i}>
                            <td>
                                {c.learner.name}
                            </td>
                            <td>
                                {getStatus(c.completedDateTime, c.startedDateTime)}
                            </td>
                            <td>
                                <Line percent={c.progress} strokeWidth="2" strokeColor={context.primaryButtonColour} />
                            </td>
                            <td>{c.scorePercentage}%</td>
                            <td>{getStatusBadge(c.lessonStatus)}</td>
                            <td>{c.numberOfRestarts}</td>
                            <td>
                                <Link to={`/learner/${c.learner.urlToken}`}>
                                    <span className="view fas fa-eye float-right mr-3" title="View"></span>
                                </Link>
                            </td>
                        </tr>
                )}
            </>
        )
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        {isLoaded && hasLearnerProgress &&
                            <Card title="Learner Progress" classes="mb-5 course-item">
                                <div className="form-group float-left">
                                    <select value={course.id} onChange={updateCourseId} name="courseId" className="form-control">
                                        <option value="00000000-0000-0000-0000-000000000000">Select course...</option>
                                        {courses.map(
                                            (d, i) => (
                                                <option key={i} value={d.id}>{d.title}</option>
                                            )
                                        )
                                        }
                                    </select>
                                </div>
                                {course.feedbackFormId !== null &&
                                    <div className="form-group float-left pl-5">
                                        <Button link={`/feedback-stats/${course.feedbackFormId}/${clientId}`} text="View feedback" classes={"btn btn-primary"} />
                                    </div>
                                }
                                <div className="form-group float-right">
                                    <strong><p><span style={{ "color": "red" }}>Not started: {course.stats.notStarted}</span> - <span style={{ "color": "Orange" }}>In progress: {course.stats.inProgress}</span> - <span style={{ "color": "green" }}> Completed: {course.stats.completed}</span></p></strong>
                                </div>
                                <Table headings={headings} rowData={rowData} />
                                <PaginationControls next={nextPage} previous={previousPage} total={totalPages} current={pageNumber} />
                            </Card>

                        }
                        {isLoaded && !hasLearnerProgress &&
                            <Card classes="text-center p-5">
                                <p style={{ fontWeight: "700", textTransform: "uppercase", fontSize: "0.88rem" }}>No Learner Progress to display</p>

                                <span>You have not yet been assigned any pathways or none of your learners have logged in</span>
                            </Card>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientLearnerProgress;