import axios from 'axios';
import { baseUrl } from '../Infrastructure/Config/ApiConfig';
import { token } from './AuthService';

const controllerName = 'Community';

export const getCommunitiesAsync = async (cilentId) => {
    const response = await axios.get(`${baseUrl()}${controllerName}/client/${cilentId}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getCommunityAsync = async (communityId) => {
    const response = await axios.get(`${baseUrl()}${controllerName}/community/${communityId}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const createCommunityAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const createpostAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/post`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const likePostAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/like`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const unlikePostAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/unlike`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getCommentsAsync = async (postId) => {
    const response = await axios.get(`${baseUrl()}${controllerName}/comment/${postId}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const commentOnPostAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/comment`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const deleteCommentAsync = async (commentId) => {
    const response = await axios.delete(`${baseUrl()}${controllerName}/comment/${commentId}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const deletePostAsync = async (postId) => {
    const response = await axios.delete(`${baseUrl()}${controllerName}/post/${postId}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const deleteCommunityAsync = async (communityId) => {
    const response = await axios.delete(`${baseUrl()}${controllerName}/community/${communityId}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}