import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getClientByUrlTokenAsync, getClientUsersAsync } from '../../Services/AdminService';
import Card from '../../Shared/Components/Card/Card';
import PaginationControls from '../../Shared/Components/PaginationControls';
import Header from './../../Shared/Components/Header/Header';
import StatCard from '../../Shared/Components/StatCard/StatCard';
import AssignedPathways from './Components/AssignedPathways';
import ClientUsers from './Components/ClientUsers';
import ClientActions from './Components/ClientActions';
import ClientLearnerProgress from './Components/ClientLearnerProgress';

const Client = () => {
    const [client, setClient] = useState({ assignedLearningPathways: [] });
    const [learningPathways, setLearningPathways] = useState([]);
    const [users, setUsers] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const { urlToken } = useParams();
    const [forceChange, setForceChange] = useState(false);

    useEffect(() => {
        const getClient = async () => {
            let client = await getClientByUrlTokenAsync(urlToken);
            setClient(client);
            setLearningPathways(client.learningPathwayDropDown);
        }
        getClient();
    }, [forceChange, urlToken]);

    useEffect(() => {
        if (client.id !== undefined) {
            const getClientUsers = async () => {
                let response = await getClientUsersAsync(pageNumber, client.id);
                setUsers(response.users);
                setTotalPages(response.totalPages);
            }
            getClientUsers();
        }
    }, [pageNumber, urlToken, client]);

    const nextPage = () => {
        if (pageNumber < totalPages) {
            setPageNumber(pageNumber + 1);
        }
    }

    const previousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }

    const ForceReload = () => {
        setForceChange(!forceChange);
    }

    return (
        <>
            <Header heading={client.name} icon="apartment" />
            <div className="container-fluid content">
                <div className="row">
                    <StatCard
                        title="Learners"
                        stat={client.numberOfLearners}
                        subtext="Current number of Learners"
                        colour="orange"
                    />
                    <StatCard
                        title="Maximum Learners"
                        stat={client.maximumNumberOfLearners}
                        subtext="Maximum number of Learners"
                        colour="orange"
                    />
                    <StatCard
                        title="Pathways"
                        stat={client.assignedLearningPathways.length}
                        subtext="Current number of assigned pathways"
                        colour="orange"
                    />

                    <ClientActions client={client} getClient={ForceReload} />

                    <AssignedPathways pathways={client.assignedLearningPathways} learningPathways={learningPathways} clientId={client.id} getClient={ForceReload} />

                    <div className="col-12">
                        <Card title="Current Users">
                            <div className="row">
                                <div className="col-12">
                                    <ClientUsers users={users} getClient={ForceReload} />
                                </div>
                                <PaginationControls previous={previousPage} next={nextPage} current={pageNumber} total={totalPages} />
                            </div>
                        </Card>
                    </div>

                    <ClientLearnerProgress clientId={client.id} />
                </div>
            </div>
        </>
    );
};

export default Client;