import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { showToast } from '../../Infrastructure/Notifications/ToastrConfig';
import { confirmAccountAsync } from '../../Services/AccountService';
import Card from './../Components/Card/Card';

const ConfirmAccount = props => {
    const history = useHistory();
    const { match } = props;
    const { userId, token } = match.params;

    const [details] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [errors, setErrors] = useState([]);

    const handleChange = evt => {
        if (evt.target.name === 'password') {
            setPassword(evt.target.value);
        }
        else {
            setPasswordRepeat(evt.target.value);
        }
    }

    const handleSubmit = async () => {
        if (password !== passwordRepeat || password === '' || passwordRepeat === '') {
            setErrors([{ error: "Passwords do not match" }]);
            return;
        }
        else {
            setErrors([]);
        }

        let data = {
            encodedToken: token,
            password: password,
            passwordRepeat: passwordRepeat,
            userId: userId
        }

        let response = await confirmAccountAsync(data);

        if (response.success) {
            showToast(true, "Success, account created")
            history.push(`/`);
        }
        else {
            showToast(false, response.errors[0].error);
        }
    }

    useEffect(() => {
        async function fetchData() {
            // let response = await confirmAccountGetAsync(userId);
            // setDetails(response);
        };
        fetchData();
    }, [userId]);

    return (
        <div className="row flex-box-container">
            <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4 mx-auto flex-box">
                <Card classes="login-box">
                    <div className="text-center">
                        <img className="img-fluid login-logo" src="https://academyone.blob.core.windows.net/images/academy-one-rev-%20no%20background.png" alt="Client logo" />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input onChange={handleChange} name="password" type="password" className="form-control" />
                    </div>
                    <div className="form-group">
                        <label>Repeat Password</label>
                        <input onChange={handleChange} name="passwordRepeat" type="password" className="form-control" />
                    </div>
                    <ul>
                        {errors.map(
                            (e, i) => <li className="error" key={i}>{e.error}</li>
                        )}
                    </ul>
                    <button onClick={handleSubmit} className="btn login-btn float-right" style={{ "color": details.textColour, "background": details.buttonColour }}>Set password</button>
                </Card>
            </div>
        </div>
    );
};

export default ConfirmAccount;