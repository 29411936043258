import axios from 'axios';
import { baseUrl } from '../Infrastructure/Config/ApiConfig';
import { token } from './AuthService';

const base = `${baseUrl()}Admin`;

export const getAllClientsAsync = async (pageNumber) => {
    const response = await axios.get(`${base}/GetAllClients/${pageNumber}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getClientByIdAsync = async clientId => {
    const response = await axios.get(`${base}/GetClientById/${clientId}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getClientByUrlTokenAsync = async urlToken => {
    const response = await axios.get(`${base}/GetClientByUrlToken/${urlToken}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getStatsAsync = async () => {
    const response = await axios.get(`${base}/GetStats`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const pauseClientAsync = async data => {
    const response = await axios.post(`${base}/PauseClient`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const unpauseClientAsync = async data => {
    const response = await axios.post(`${base}/UnPauseClient`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const deleteClientAsync = async data => {
    const response = await axios.post(`${base}/DeleteClient`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const archiveClientAsync = async data => {
    const response = await axios.post(`${base}/ArchiveClient`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}


export const updateMaxLearnersAsync = async data => {
    const response = await axios.post(`${base}/UpdateMaxLearners`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const assignLearnerClaimAsync = async data => {
    const response = await axios.post(`${base}/AssignLearnerClaim`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const assignAdminClaimAsync = async data => {
    const response = await axios.post(`${base}/AssignAdminClaim`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getClientUsersAsync = async (pageNumber, clientId) => {
    const response = await axios.get(`${base}/GetAllUsersForClient/${pageNumber}/${clientId}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const clientListAsync = async () => {
    const response = await axios.get(`${base}/ClientList`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}