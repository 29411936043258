import axios from 'axios';
import { baseUrl } from '../Infrastructure/Config/ApiConfig';
import { token } from './AuthService';

const base = `${baseUrl()}Client`;

export const searchLearnersAsync = async (pageNumber, pageSize, searchTerm) => {

    let searchQuery = '';
    if (searchTerm !== '') {
        searchQuery = '&searchTerm=' + searchTerm
    }

    const response = await axios.get(`${base}/Learners/?pageNumber=${pageNumber}&pageSize=${pageSize}${searchQuery}`,
        {
            headers: {
                'Authorization': `Bearer ${token()}`
            }
        });

    return response.data;
}

export const previewUsersCsvAsync = async file => {
    const response = await axios.post(`${base}/PreviewUpload`, file, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });

    return response.data;
}

export const uploadUsersCsvAsync = async data => {
    const response = await axios.post(`${base}/UploadUsers`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });

    return response.data;
}

export const getLearnerByIdAsync = async (id) => {
    const response = await axios.get(`${base}/GetUserById/${id}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });

    return response.data;
}

export const getLearnerByUrlTokenAsync = async (urlToken) => {
    const response = await axios.get(`${base}/GetUserByUrlToken/${urlToken}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });

    return response.data;
}