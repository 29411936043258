import React from 'react';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import Sidebar from './Components/Sidebar';

const MainLayout = props => {
    return (
        <div className="appBody">
            <Sidebar />
            <div id="main-container" className="main-container menu-toggle">
                <Navbar />
                <div className="content-container">
                    {props.children}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default MainLayout;