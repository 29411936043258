import React, { useEffect, useState } from 'react';
import { getThemeAsync, updateThemeAsync } from '../../Services/ThemeService';
import Card from '../../Shared/Components/Card/Card';
import PrimaryButton from '../../Shared/Components/PrimaryButton/PrimaryButton';
import Header from './../../Shared/Components/Header/Header';
import './ThemeSettings.scss';
import LineIcon from 'react-lineicons';
import Title from './../../Shared/Components/Title/Title';

const ThemeSettings = () => {
    const [state, setState] = useState({
        primaryButtonColour: "",
        secondaryButtonColour: "",
        primaryNavColour: "",
        secondaryNavColour: "",
        primaryNavFontColour: "",
        secondaryNavFontColour: "",
        iconColour: "",
        favicon: "",
        sidebarIconColour: ""
    });

    useEffect(() => {
        getCurrentTheme();
    }, []);

    const getCurrentTheme = async () => {
        let response = await getThemeAsync();
        setState(response.config);
    }

    const handleChange = async evt => {
        setState({ ...state, [evt.target.name]: evt.target.value });
    }

    const handleFileChange = evt => {
        let value = evt.target.files[0];
        let logoUrl = URL.createObjectURL(value);
        let name = evt.target.name;
        let fileName = evt.target.name + 'FileName';
        let url = evt.target.name + 'Url';

        setState({ ...state, [name]: value, [url]: logoUrl, [fileName]: evt.target.files[0].name });
    }

    const handleSubmit = async () => {
        let form = new FormData();
        form.append("primaryNavColour", state.primaryNavColour);
        form.append("secondaryNavColour", state.secondaryNavColour);
        form.append("primaryButtonColour", state.primaryButtonColour);
        form.append("secondaryButtonColour", state.secondaryButtonColour);
        form.append("primaryNavFontColour", state.primaryNavFontColour);
        form.append("secondaryNavFontColour", state.secondaryNavFontColour);
        form.append("iconColour", state.iconColour);
        form.append("sidebarIconColour", state.sidebarIconColour);
        form.append("logo", state.logo);
        form.append("loadingLogo", state.loadingLogo);
        form.append("favicon", state.favicon);

        await updateThemeAsync(form);
        window.location.reload();
    }

    return (
        <>
            <Header heading="Theme Configuration" subheading="Custom branding for your eLearning Platform" icon="layout" />
            <div className="container-fluid content">
                <div className="row">
                    <div className="col-12 theme">
                        <Card title="Logos">
                            <div className="col-12 p-0">
                                <div className="form row">
                                    <div className="form-group col-12 col-sm-6 col-md-4 col-lg-3">
                                        <label>Sidebar Logo</label>
                                        <div className="upload-container">
                                            <div className="preview">
                                                {state.logoUrl ?
                                                    <img alt="Company logo" src={state.logoUrl} className="img-fluid" />
                                                    :
                                                    <LineIcon name="image" style={{ color: "#ccc" }} />
                                                }
                                            </div>
                                            <div className="custom-file">
                                                <input value={""} className="custom-file-input" accept=".png, .jpeg, .jpg" name="logo" id="logo" type="file" onChange={handleFileChange} />
                                                <label className="custom-file-label">{state.logoFileName ? state.logoFileName : "Browse"}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-12 col-sm-6 col-md-4 col-lg-3">
                                        <label>Loading page Logo</label>
                                        <div className="upload-container">
                                            <div className="preview">
                                                {state.loadingLogoUrl ?
                                                    <img alt="Loading logo" src={state.loadingLogoUrl} className="img-fluid" />
                                                    :
                                                    <LineIcon name="image" style={{ color: "#ccc" }} />
                                                }
                                            </div>
                                            <div className="custom-file">
                                                <input value={""} className="custom-file-input" accept=".png, .jpeg, .jpg" name="loadingLogo" id="logo" type="file" onChange={handleFileChange} />
                                                <label className="custom-file-label">{state.loadingLogoFileName ? state.loadingLogoFileName : "Browse"}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-12 col-sm-6 col-md-4 col-lg-3">
                                        <label>Favicon</label>
                                        <div className="upload-container">
                                            <div className="preview">
                                                {state.faviconUrl ?
                                                    <img alt="Favicon" src={state.faviconUrl} className="img-fluid" />
                                                    :
                                                    <LineIcon name="image" style={{ color: "#ccc" }} />
                                                }
                                            </div>
                                            <div className="custom-file">
                                                <input value={""} className="custom-file-input" accept=".png, .jpeg, .jpg" name="favicon" id="logo" type="file" onChange={handleFileChange} />
                                                <label className="custom-file-label">{state.faviconFileName ? state.faviconFileName : "Browse"}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 p-0 mt-5">
                                <div className="row ">
                                    <Title title="Colours"></Title>
                                </div>
                                <div className="form row">
                                    <div className="form-group col-12 col-sm-6 col-md-4">
                                        <label>Sidebar colour</label>
                                        <div className="colour-box">
                                            <input value={state.primaryNavColour} type='color' className="color-input form-control" name="primaryNavColour" id="primaryNavColour" onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="form-group col-12 col-sm-6 col-md-4">
                                        <label>Sidebar Icon colour</label>
                                        <div className="colour-box">
                                            <input value={state.sidebarIconColour} type='color' className="form-control" name="sidebarIconColour" id="sidebarIconColour" onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="form-group col-12 col-sm-6 col-md-4">
                                        <label>Navbar colour</label>
                                        <div className="colour-box">
                                            <input value={state.secondaryNavColour} type='color' className="form-control" name="secondaryNavColour" id="secondaryNavColour" onChange={handleChange} />
                                        </div>
                                    </div>
                                    {/* <div className="form-group col-12 col-sm-6 col-md-4">
                                        <label>Navbar font colour</label>
                                        <div className="colour-box">
                                            <input value={state.secondaryNavFontColour} type='color' className="form-control" name="secondaryNavFontColour" id="secondaryNavFontColour" onChange={handleChange} />
                                        </div>
                                    </div> */}
                                    <div className="form-group col-12 col-sm-6 col-md-4">
                                        <label>Primary button colour</label>
                                        <div className="colour-box">
                                            <input value={state.primaryButtonColour} type='color' className="form-control" name="primaryButtonColour" id="primaryButtonColour" onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="form-group col-12 col-sm-6 col-md-4">
                                        <label>Primary button font colour</label>
                                        <div className="colour-box">
                                            <input value={state.primaryNavFontColour} type='color' className="form-control" name="primaryNavFontColour" id="primaryNavFontColour" onChange={handleChange} />
                                        </div>
                                    </div>
                                    {/* <div className="form-group col-12 col-sm-6 col-md-4">
                                        <label>Secondary button colour</label>
                                        <div className="colour-box">
                                            <input value={state.secondaryButtonColour} type='color' className="form-control" name="secondaryButtonColour" id="secondaryButtonColour" onChange={handleChange} />
                                        </div>
                                    </div> */}
                                    <div className="form-group col-12 col-sm-6 col-md-4">
                                        <label>Icon &amp; contrast text colour</label>
                                        <div className="colour-box">
                                            <input value={state.iconColour} type='color' className="form-control" name="iconColour" id="iconColour" onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <PrimaryButton text="Save" func={handleSubmit} classes="float-right mt-3" />
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ThemeSettings;