import React, { useEffect, useState } from 'react';
import Card from '../../../Shared/Components/Card/Card';
import PrimaryButton from '../../../Shared/Components/PrimaryButton/PrimaryButton';
import LineIcon from 'react-lineicons';
import './post.scss';
import { commentOnPostAsync, deleteCommentAsync, deletePostAsync, getCommentsAsync, likePostAsync, unlikePostAsync } from '../../../Services/CommunityServie';
import Modal from '../../../Shared/Components/Modal/Modal';

const Post = ({ post, forceReload }) => {
    const [state, setState] = useState({ user: {} });
    const [comment, setComment] = useState("");
    const [comments, setComments] = useState([]);
    const [commentMode, setCommentMode] = useState(false);
    const [reload, setReload] = useState(false);
    const [commentToDeleteId, setCommentToDeleteId] = useState("");
    const [postToDeleteId, setPostToDeleteId] = useState("");

    useEffect(() => {
        setState(post);
        if (commentMode) {
            let getComments = async () => {
                let response = await getCommentsAsync(post.id);
                setComments(response.comments);
            }
            getComments();
        }
    }, [post, commentMode, reload]);

    const handleChange = evt => setComment(evt.target.value);

    const handleSubmit = async () => {
        if (comment !== "") {
            await commentOnPostAsync({ "postId": state.id, "text": comment });
            setComment("");
            setReload(!reload);
        }
    }


    const likePost = async () => {
        if (state.liked) {
            await unlikePostAsync({ "postId": state.id });
            setState({ ...state, likes: state.likes--, liked: false });

        }
        await likePostAsync({ "postId": state.id });
        setState({ ...state, likes: state.likes++, liked: true });
    }

    const deleteComment = async () => {
        if (commentToDeleteId !== "") {
            await deleteCommentAsync(commentToDeleteId);
            setCommentToDeleteId("");
            setReload(!reload);
        }
    }

    const deletePost = async () => {
        await deletePostAsync(state.id);
        setPostToDeleteId("");
        forceReload();
    }
    return (
        <>
            <Card>
                <Card classes="post">
                    <div className="row">
                        <div className="col-12">
                            <h1>{state.title}</h1>
                            <p>{state.text}</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 d-flex justify-content-end mr-3 pr-3">
                            <span><strong className='mr-3'>by {state.user.name}</strong> {state.likes} <span onClick={() => likePost()} className={`like ${state.liked && 'liked'}`}>
                                { } <LineIcon name="thumbs-up" /></span> <span className='comment' onClick={() => setCommentMode(!commentMode)}>{state.comments} <LineIcon name="bubble" /></span>
                            </span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <hr />
                            {state.user.id === JSON.parse(window.localStorage.getItem("user")).id &&
                                <button className="btn btn-danger float-right" onClick={() => setPostToDeleteId(state.id)} data-target={`#deletePost${state.id}`} data-toggle="modal">Delete</button>
                            }

                        </div>
                    </div>
                </Card>
                {commentMode && (comments.length > 0) && comments.map((x, i) =>
                    <>
                        <Card key={i}>
                            <div key={i} className="col-12 ">
                                <div className='p-3'>
                                    <strong><p>{x.username}</p></strong>
                                    <p>{x.text}</p>
                                    {x.userId === JSON.parse(window.localStorage.getItem("user")).id &&
                                        <button className="btn btn-danger float-right" onClick={() => setCommentToDeleteId(x.id)} data-target={`#deleteComment${x.id}`} data-toggle="modal">Delete</button>
                                    }
                                </div>
                            </div>
                        </Card>
                        <Modal
                            modalId={`deleteComment${x.id}`}
                            bodyText={`Are you sure you want to delete this comment?`}
                            buttonFunc={deleteComment}
                            buttonText="Remove"
                            title={`Delete comment`}
                        />
                    </>
                )}
                {commentMode &&
                    <Card>
                        <div className="col-12">
                            <div className="form">
                                <div className="form-group">
                                    <textarea value={comment} style={{ "minHeight": "75px" }} className="form-control" name="text" id="text" onChange={handleChange} />
                                </div>
                            </div>
                            <PrimaryButton text="Comment" func={handleSubmit} classes="float-right mt-3" />
                        </div>
                    </Card>
                }
            </Card >

            <Modal
                modalId={`deletePost${state.id}`}
                bodyText={`Are you sure you want to delete ${state.title}?`}
                buttonFunc={deletePost}
                buttonText="Remove"
                title={`Delete Post`}
            />
        </>
    );
};

export default Post;