const HOST = "elearning.academyone.co.uk";

export function baseUrl() {
    if (window.location.href.includes('localhost')) {
        //return `https://localhost:5001/api/`;
        return `https://api.${HOST}/api/`
    }
    else {
        return `https://api.${HOST}/api/`
    }
}

export function playCourseUrl() {
    if (window.location.href.includes('localhost')) {
        //return `https://localhost:5004/playcourse/`;
        return `https://content.${HOST}/playcourse/`
    }
    else {
        return `https://content.${window.location.host}/playcourse/`
    }
}

export function rteUrl() {
    if (window.location.href.includes('localhost')) {
        //return `https://localhost:5004/api/`;
        return `https://content.${HOST}/api/`;
    }
    else {
        return `https://content.${HOST}/api/`;
    }
}

export function courseProgressHubUrl() {
    if (window.location.href.includes('localhost')) {
        //return `https://localhost:5004/courseprogress`;
        return `https://content.${HOST}/courseprogress`;
    }
    else {
        return `https://content.${HOST}/courseprogress`
    }
}