import React from 'react';
import AdminUserNav from './Navs/AdminUserNav';
import ClientELearningNav from './Navs/ClientElearningNav';
import LearnerNav from './Navs/LearnerNav';
import SettingsNav from './Navs/SettingsNav';

const ClientMenu = props => {
    return (
        <>
            <SettingsNav context={props.context} manipulateColours={props.manipulateColours} />
            <AdminUserNav context={props.context} manipulateColours={props.manipulateColours} />
            <LearnerNav context={props.context} manipulateColours={props.manipulateColours} />
            <ClientELearningNav context={props.context} manipulateColours={props.manipulateColours} />
        </>
    );
};

export default ClientMenu;