import axios from 'axios';
import { baseUrl } from '../Infrastructure/Config/ApiConfig';
import { token } from './AuthService';

const base = `${baseUrl()}Blog`;

export const getAllBlogPostsAsync = async (pageNumber) => {
    const response = await axios.get(`${base}/AdminGetBlogPosts?pageNumber=${pageNumber}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getBlogPostAsync = async (urlSlug) => {
    const response = await axios.get(`${base}/AdminGetBlogPost?urlSlug=${urlSlug}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getTagsAsync = async () => {
    const response = await axios.get(`${base}/GetTags`);
    return response.data;
}

export const createBlogPostAsync = async (form) => {
    const response = await axios.post(`${base}/CreateBlogPost`, form, {
        headers: {
            'Authorization': `Bearer ${token()}`,
        }
    });
    return response.data;
}

export const updateBlogPostAsync = async (form) => {
    const response = await axios.patch(`${base}/UpdateBlogPost`, form, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const addTagToBlogPostAsync = async (data) => {
    const response = await axios.patch(`${base}/AddTagToBlogPost`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const deleteTagFromBlogPostAsync = async (data) => {
    const response = await axios.post(`${base}/DeleteTagFromBlogPost`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const deleteBlogPostAsync = async (data) => {
    const response = await axios.delete(`${base}/DeleteBlogPost`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const publishBlogPostAsync = async (data) => {
    const response = await axios.patch(`${base}/PublishBlogPost`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const markBlogPostAsDraftAsync = async (data) => {
    const response = await axios.patch(`${base}/MarkBlogPostAsDraft`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const addAttachmentAsync = async (form) => {
    const response = await axios.post(`${base}/AddAttachment`, form, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const updateAttachmentAsync = async (form) => {
    const response = await axios.post(`${base}/UpdateAttachment`, form, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const deleteAttachmentAsync = async (data) => {
    const response = await axios.delete(`${base}/DeleteAttachment`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}