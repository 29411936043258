import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllCoursesAsync } from '../../../Services/CourseService';
import Header from '../../../Shared/Components/Header/Header';
import Table from '../../../Shared/Components/Table';
import Card from './../../../Shared/Components/Card/Card';
import Button from './../../../Shared/Components/Button/Button';
import PrimaryButton from '../../../Shared/Components/PrimaryButton/PrimaryButton';

const Courses = () => {
    const [courses, setCourses] = useState([]);
    const [state, setState] = useState({ pageNumber: 0, totalCourses: 0 });

    useEffect(() => {
        const getCourses = async () => {
            let response = await getAllCoursesAsync(state.pageNumber);
            setCourses(response.courses);
            setState(s => ({ totalCourses: response.totalPages, pageNumber: s.pageNumber }))
        }
        getCourses();
    }, [state.pageNumber]);

    const loadMore = () => {
        if (courses.length <= state.totalCourses) {
            setState({ ...state, pageNumber: state.pageNumber + 1 });
        }
    }

    const headings = () => {
        return (<>
            <th>Title</th>
            <th>Reference</th>
            <th></th>
        </>)
    }

    const rowData = () => {
        return (
            <>
                {courses.length > 0 ?
                    courses.map(
                        (c, i) =>
                            <tr key={i}>
                                <td>
                                    {c.title}
                                </td>
                                <td>
                                    {c.reference}
                                </td>
                                <td>
                                    <Link to={`/course/${c.id}`}>
                                        <span className="view fas fa-eye float-right mr-3" title="View"></span>
                                    </Link>
                                </td>
                            </tr>
                    )
                    :
                    <tr>
                        <td className="no-data" colSpan="4">
                            No courses to currently display
                        </td>
                    </tr>
                }
            </>)
    }

    return (
        <>
            <Header heading="Courses" subheading="All current available courses" icon="folder" />
            <div className="container-fluid content">
                <Card>
                    <div className="float-left">
                        <Button text="Create New" classes="btn btn-success" link="/upload-course" />
                    </div>
                </Card>
                <Card>
                    {/* To Do! add page size and search */}
                    {/* <div className="col-12 p-0">
                        <div className="form-group float-left">
                            <input className="form-control" placeholder="Course title..." />
                        </div>
                        <div className="form-group float-right ml-3">
                            <select className="form-control" defaultValue="10" name="pageSize">
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={250}>250</option>
                                <option value={500}>500</option>
                            </select>
                        </div>
                    </div> */}
                    <Table headings={headings} rowData={rowData} />
                    {/* <div className="mt-3 text-center">
                        <div className="small mb-3">
                            <span>Showing {courses.length} of {state.totalPages}</span>
                        </div>
                        <PrimaryButton text="Load more"
                            classes="clickable "
                            func={loadMore}
                            disabled={courses.length === state.totalPages} />
                    </div> */}
                </Card>
            </div>
        </>
    );
};

export default Courses;