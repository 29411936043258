import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { showToast } from '../../Infrastructure/Notifications/ToastrConfig';
import { confirmLearnerAccountAsync, getAccountRegisterDetailsAsync } from '../../Services/AccountService';
import Register from '../../Assets/register.jpg';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import 'moment/locale/en-gb';
import moment from 'moment';
import { Link } from 'react-router-dom';

const ConfirmLearnerAccount = props => {
    const history = useHistory();
    const { match } = props;
    const { userId, token } = match.params;
    const [details, setDetails] = useState({ genders: [], employmentStatus: [], jobRoles: [], ethnicities: [] });
    const [state, setState] = useState({
        dateOfBirth: '',
        genderId: '',
        ethnicityId: '',
        employmentStatusId: '',
        jobRoleId: '',
        postCode: '',
        doYouKnowWhatClimateActionIs: false,
        doYouUnderstandTheRoleYouCanPlay: false,
        doYouUnderstandTheRoleYourOrganistaionCanPlay: false,
        heardOfSustainableDevelopmentGoals: false,
        agreeToTermsAndConditions: false

    });
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const handleChange = async evt => {
        setState({ ...state, [evt.target.name]: evt.target.value });
    }

    const loadData = async () => {
        let response = await getAccountRegisterDetailsAsync();
        setDetails(response);
    }

    const handleValidation = () => {
        let fields = state;
        let errors = {};
        let formIsValid = true;

        //DOB
        if (!fields["dateOfBirth"]) {
            formIsValid = false;
            errors["dateOfBirth"] = "Please enter your date of birth";
        }

        //Gender
        if (!fields["genderId"]) {
            formIsValid = false;
            errors["genderId"] = "Please select gender";
        }

        //Ethnicity
        if (!fields["ethnicityId"]) {
            formIsValid = false;
            errors["ethnicityId"] = "Please select ethnicity";
        }

        //Employment
        if (!fields["employmentStatusId"]) {
            formIsValid = false;
            errors["employmentStatusId"] = "Please select employment status";
        }

        //Job
        if (!fields["jobRoleId"]) {
            formIsValid = false;
            errors["jobRoleId"] = "Please select job role";
        }

        //Postcode
        if (!fields["postCode"]) {
            formIsValid = false;
            errors["postCode"] = "Please enter a postcode";
        } else {
            if (fields["postCode"].length <= 3) {
                formIsValid = false;
                errors["postCode"] = "Please enter a valid postcode";
            }
        }

        //Passwords
        if (!fields["password"] || !fields["passwordRepeat"]) {
            formIsValid = false;
            errors["password"] = "Please create a password ";
        } else {
            if (fields["password"] !== fields["passwordRepeat"]) {
                formIsValid = false;
                errors["password"] = "Passwords do not match";
            }
        }

        if (!fields["agreeToTermsAndConditions"]) {
            formIsValid = false;
            errors["agreeToTermsAndConditions"] = "You must have read and agreed to our terms and conditions before using this site";
        }

        setErrors(errors);
        return formIsValid;
    }

    const handleCheck = evt => {
        setState({ ...state, [evt.target.name]: evt.target.checked });
    }

    const handleSubmit = async () => {
        if (handleValidation()) {
            let data = {
                encodedToken: token,
                password: state.password,
                userId: userId,
                dateOfBirth: state.dateOfBirth,
                genderId: state.genderId,
                ethnicityId: state.ethnicityId,
                employmentStatusId: state.employmentStatusId,
                jobRoleId: state.jobRoleId,
                postCode: state.postCode,
                doYouKnowWhatClimateActionIs: state.doYouKnowWhatClimateActionIs,
                doYouUnderstandTheRoleYouCanPlay: state.doYouUnderstandTheRoleYouCanPlay,
                doYouUnderstandTheRoleYourOrganistaionCanPlay: state.doYouUnderstandTheRoleYourOrganistaionCanPlay,
                heardOfSustainableDevelopmentGoals: state.heardOfSustainableDevelopmentGoals,
                agreeToTermsAndConditions: state.agreeToTermsAndConditions,
            }

            let response = await confirmLearnerAccountAsync(data);

            if (response.success) {
                showToast(true, "Success, account created")
                history.push(`/`);
            }
            else {
                showToast(false, response.errors[0].error);
            }
        } else {
            showToast(false, "Please fix the below errors");

        }
    }

    const handleDateChange = (evt) => {
        let date = moment(evt).format('YYYY-MM-DD');
        setState({ ...state, dateOfBirth: date });
    }

    let dateData = {
        placeholder: 'Choose date',
        name: 'dateOfBirth'
    };

    return (
        <div className="row no-gutters register-container">
            <div className="d-none d-md-block col-sm-4 col-md-5 col-lg-6  register-img">
                <img className="img-fluid" src={Register} alt="Client logo" />
            </div>
            <div className="col-12 col-md-7 col-lg-6 register">
                <h2 className="pb-4" style={{ color: "#f48820" }}>Register</h2>
                <p className="pb-1" style={{ textTransform: "uppercase", fontWeight: "bold", fontSize: "0.8rem" }}>Please enter your details below to complete registration</p>
                <div className="form-row">
                    <div className="form-group col-sm-6">
                        <label>Date of birth</label>
                        <Datetime dateFormat={true} name="dateOfBirth" timeFormat={false} inputProps={dateData} locale="en-gb" onChange={handleDateChange} />
                        <small className="errorMessage">{errors.dateOfBirth}</small>
                    </div>
                    <div className="form-group col-sm-6">
                        <label>Gender</label>
                        <select onChange={handleChange} name="genderId" className="form-control">
                            <option>Please select</option>
                            {details.genders.map((x, i) =>
                                <option key={i} value={x.id}>{x.name}</option>
                            )}
                        </select>
                        <small className="errorMessage">{errors.genderId}</small>
                    </div>
                    <div className="form-group col-sm-6">
                        <label>Ethnicity</label>
                        <select onChange={handleChange} name="ethnicityId" className="form-control">
                            <option>Please select</option>
                            {details.ethnicities.map((x, i) =>
                                <option key={i} value={x.id}>{x.name}</option>
                            )}
                        </select>
                        <small className="errorMessage">{errors.ethnicityId}</small>
                    </div>
                    <div className="form-group col-sm-6">
                        <label>Employment status</label>
                        <select onChange={handleChange} name="employmentStatusId" className="form-control">
                            <option>Please select</option>
                            {details.employmentStatus.map((x, i) =>
                                <option key={i} value={x.id}>{x.name}</option>
                            )}
                        </select>
                        <small className="errorMessage">{errors.employmentStatusId}</small>
                    </div>
                    <div className="form-group col-sm-6">
                        <label>Job role</label>
                        <select onChange={handleChange} name="jobRoleId" className="form-control">
                            <option>Please select</option>
                            {details.jobRoles.map((x, i) =>
                                <option key={i} value={x.id}>{x.name}</option>
                            )}
                        </select>
                        <small className="errorMessage">{errors.jobRoleId}</small>
                    </div>
                    <div className="form-group col-sm-6">
                        <label>Postcode</label>
                        <input onChange={handleChange} name="postCode" type="text" className="form-control" autoComplete="new-password" />
                        <small className="errorMessage">{errors.postCode}</small>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-check form-check-inline ml-2">
                        <input className="form-check-input" type="checkbox" value="" name="doYouKnowWhatClimateActionIs" onChange={handleCheck} />
                        <label className="form-check-label">
                            I know what climate action is
                        </label>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-check form-check-inline ml-2">
                        <input className="form-check-input" type="checkbox" value="" name="doYouUnderstandTheRoleYouCanPlay" onChange={handleCheck} />
                        <label className="form-check-label">
                            I understand the role that I can play
                        </label>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-check form-check-inline ml-2">
                        <input className="form-check-input" type="checkbox" value="" name="doYouUnderstandTheRoleYourOrganistaionCanPlay" onChange={handleCheck} />
                        <label className="form-check-label">
                            I understand the role my organisation can play
                        </label>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-check form-check-inline ml-2">
                        <input className="form-check-input" type="checkbox" value="" name="heardOfSustainableDevelopmentGoals" onChange={handleCheck} />
                        <label className="form-check-label">
                            I have heard of The Sustainable Development Goals
                        </label>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-check form-check-inline ml-2">
                        <input className="form-check-input" type="checkbox" value="" name="agreeToTermsAndConditions" onChange={handleCheck} />
                        <label className="form-check-label">
                            I agree to this websites terms and conditions as laid out in the <Link to="/gdpr/privacy">privacy policy</Link>
                        </label>
                    </div>
                    <small className="errorMessage pl-2">{errors.agreeToTermsAndConditions}</small>
                </div>
                <div className="pb-2 pt-3">
                    <span style={{ textTransform: "uppercase", fontWeight: "bold", fontSize: "0.8rem" }}>Please create a password</span>
                </div>
                <div className="form-row">
                    <div className="form-group col-sm-6">
                        <label>Password</label>
                        <input onChange={handleChange} name="password" type="password" className="form-control" autoComplete="new-password" />
                        <small className="errorMessage">{errors.password}</small>
                    </div>
                    <div className="form-group col-sm-6">
                        <label>Repeat Password</label>
                        <input onChange={handleChange} name="passwordRepeat" type="password" className="form-control" />
                    </div>
                </div>
                <div className="form-group">
                    <button onClick={handleSubmit} className="btn login-btn float-right" style={{ "color": details.textColour, "background": details.buttonColour }}>Register</button>
                </div>
            </div>
        </div >

    );
};

export default ConfirmLearnerAccount;