import React, { useEffect, useState } from 'react';
import Card from '../../Shared/Components/Card/Card';
import Header from '../../Shared/Components/Header/Header';
import CreatePost from './Components/CreatePost';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getCommunityAsync } from '../../Services/CommunityServie';
import Post from './Components/Post';

const Community = () => {
    useEffect(() => { }, []);
    const [community, setCommunity] = useState({ posts: [] });
    const [reload, setReload] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        let getCommunity = async () => {
            let response = await getCommunityAsync(id);
            setCommunity(response.community);
        }
        getCommunity();
    }, [id, reload])

    const forceReload = () => setReload(!reload);

    return (
        <>
            <Header heading={community.name} subheading="Latest Posts" icon="folder" />
            <div className="container-fluid content">

                <Card>
                    <CreatePost communityId={id} reload={forceReload} />
                </Card>
                {community.posts.length > 0 && community.posts.map((x, i) => <Post key={i} post={x} forceReload={forceReload} />)}
            </div>
        </>
    );
};

export default Community;