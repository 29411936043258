import React from 'react';
import Table from '../Table';

import './StatCard.scss';

const CourseStats = ({ courses }) => {

    const headings = () => {
        return (<>
            <th>Title</th>
            <th>Not started</th>
            <th>In progress</th>
            <th>Completed</th>
            <th>Total</th>
        </>)
    }

    const rowData = () => {
        return (<>
            {courses.length > 0 ?
                courses.map(
                    (c, i) =>
                        <tr key={i}>
                            <td>
                                {c.title}
                            </td>
                            <td>
                                {c.notStarted}
                            </td>
                            <td>
                                {c.inProgress}
                            </td>
                            <td>
                                {c.completed}
                            </td>
                            <td>
                                {c.notStarted + c.inProgress + c.completed}
                            </td>
                        </tr>
                )
                :
                <tr>
                    <td className="no-data" colSpan="3">
                        No Courses to display
                    </td>
                </tr>
            }
        </>)
    }

    return (
        <div className="col-12">
            <div className="stat-card mb-3">
                <Table headings={headings} rowData={rowData} />
            </div>
        </div>
    );
};

export default CourseStats;