import React from 'react';
import PrimaryButton from '../../../../../Shared/Components/PrimaryButton/PrimaryButton';

const Success = ({ func }) => {
    return (
        <>
            <h1>Success</h1>
            <PrimaryButton text="Create Another" func={func} />
        </>
    );
};

export default Success;