import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { courseProgressHubUrl, playCourseUrl } from '../../../Infrastructure/Config/ApiConfig';
import { getLearnerCourseAsync } from '../../../Services/CourseService';
import Card from '../../../Shared/Components/Card/Card';
import Header from '../../../Shared/Components/Header/Header';
import PrimaryButton from '../../../Shared/Components/PrimaryButton/PrimaryButton';
import StatCard from '../../../Shared/Components/StatCard/StatCard';
import moment from 'moment';
import { getStatusBadge } from './../../../Infrastructure/Utilities/LearnerProgressHelper';
import Button from './../../../Shared/Components/Button/Button';
import Modal from './../../../Shared/Components/Modal/Modal';
import { restartCourseAsync } from './../../../Services/CourseService';
import { showToast } from '../../../Infrastructure/Notifications/ToastrConfig';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { getUserId } from '../../../Services/AuthService';
import { getCertificateDetailsAsync } from './../../../Services/CertificateService';
import jsPDF from 'jspdf';
import AbeCertLogo from '../../../Assets/abe-cert.png';
import Signature from '../../../Assets/signature.png';
import SustainabiltyAcademyLogo from '../../../Assets/The Sustanability Academy LOGO.png';
import TSALogo from '../../../Assets/TSALogo.png';
import CPD from '../../../Assets/CPD.png';

const Engage = () => {
    const [state, setState] = useState({ course: { feedbackForm: null } });
    const { id } = useParams();
    const [forceChange, setForceChange] = useState(false);
    const [hubConnection, setHubConnection] = useState(null);

    useEffect(() => {
        const getCourse = async () => {
            let response = await getLearnerCourseAsync(id);
            setState(response);
        }
        getCourse();

    }, [id, forceChange]);

    // useEffect(() => {
    //     setHubConnection(getConnection());
    // }, [])

    // useEffect(() => {
    //     if (hubConnection) {
    //         connect(hubConnection);
    //         handleProgress(hubConnection);
    //     }
    // }, [hubConnection])

    const getConnection = () => {
        return new HubConnectionBuilder()
            .withUrl(courseProgressHubUrl())
            .build();
    }

    const connect = connection => {
        connection.start()
            .then(() => { console.log("Connected to hub") })
            .catch();
    }

    const handleProgress = connection => {
        connection.on(getUserId(), (result) => {
            setState(
                s => ({
                    progress: result.progress,
                    scorePercentage: result.scorePercentage,
                    startedDateTime: result.startedDateTime,
                    lessonStatus: result.lessonStatus,
                    completedDateTime: result.completedDateTime,
                    trackingId: s.trackingId,
                    course: s.course,
                })
            )
            console.log(result);
        })
    }

    const launchCourse = async () => {
        let storedUser = JSON.parse(window.localStorage.getItem("user"));
        window.open(`${playCourseUrl()}${storedUser.id}/${id}`, state.course.title, 'toolbar=no,menubar=no,location=no')
    }

    const previewCourse = async () => {
        window.open(`${playCourseUrl()}preview/${id}`, state.course.title, 'toolbar=no,menubar=no,location=no')
    }

    const restartCourse = async () => {
        console.log(`trackingId: ${state.trackingId}`)
        let data = {
            trackingId: state.trackingId
        }

        let response = await restartCourseAsync(data);
        if (response) {
            showToast(true, "Course progress reset");
            setForceChange(!forceChange);
        }
        else {
            showToast(false, "Something went wrong")
        }
    }


    const print = async () => {
        let response = await getCertificateDetailsAsync(getUserId(), state.course.id);
        let title = response.course.title;
        let learner = response.learner;

        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(TSALogo, 'JPEG', 20, 20, 130, 40);

        pdf.setTextColor(60, 60, 60);
        pdf.setFontSize(22);
        pdf.text('CERTIFICATE OF COMPLETION', 20, 90, 'left');

        pdf.setFontSize(18);
        pdf.text('This is to certify that', 20, 108, 'left');

        pdf.setFontSize(23);
        pdf.setTextColor(60, 60, 60);
        let splitLearner = pdf.splitTextToSize(learner.name, 150);
        pdf.text(20, 128, splitLearner, 'left');

        pdf.setFontSize(18);
        pdf.setTextColor(113, 113, 113);
        pdf.text('has successfully completed', 20, 145, 'left')

        pdf.setFontSize(25);
        pdf.setTextColor(60, 60, 60);
        let splitCourseTitle = pdf.splitTextToSize(title, 150);
        pdf.text(20, 168, splitCourseTitle, 'left');

        pdf.addImage(TSALogo, 'JPEG', 25, 214, 60, 20);
        pdf.addImage(Signature, 'JPEG', 30, 234, 50, 16);

        pdf.addImage(CPD, 'JPEG', 156, 210, 30, 30);
        pdf.setFontSize(12);

        pdf.save(`${learner.name} ${title}.pdf`);
    };

    return (
        <>
            <Modal
                modalId="restart"
                buttonText="Restart"
                bodyText="Are you sure you want to restart this course? All progress will be lost."
                title="Restart course"
                buttonFunc={restartCourse}
            />
            <Header heading={state.course.title} subheading={getStatusBadge(state.lessonStatus)} icon="book" />
            <div className="container-fluid content">
                <div className="row">
                    <StatCard
                        title="Course Started"
                        stat={state.startedDateTime !== null ? moment(state.startedDateTime).fromNow() : "Not Started"}
                        subtext="Current course status"
                        colour="orange"
                        fontSize="0.9rem"
                    />
                    <StatCard
                        title="Progress"
                        stat={`${Math.round(state.progress)}%`}
                        subtext="Current course progress"
                        colour="orange"
                    />
                    <StatCard
                        title="Assessment Score"
                        stat={`${Math.round(state.scorePercentage)}%`}
                        subtext="Your score"
                        colour="orange"
                    />
                </div>
                <Card title="Description">
                    <p>{state.course.description}</p>
                    <div className="row pt-3">
                        <div className="col-12">
                            {state.lessonStatus === 4 &&
                                <>
                                    <h5 className="mb-3">Congratulations on completing <strong>{state.course.title}</strong>.</h5>
                                    <h5 className="mb-5">You can now download your certificate and view your badge below.</h5>
                                </>
                            }
                            {state.completedDateTime === null ?
                                <Button text={state.startedDateTime === null ? 'Start Course' : 'Continue'} classes="btn-success float-right" func={launchCourse} />
                                :
                                <Button text="Review Course" classes="btn-primary float-right" func={previewCourse} />
                            }

                            {state.lessonStatus === 4 &&
                                <>
                                    {
                                        (state.course.feedbackRequired && !state.feedbackComplete && state.course.feedbackForm !== null) ?
                                            <Button link={`/feedback/${state.course.feedbackForm.id}`} classes="btn-primary float-left" text={'Complete feedback'} />
                                            :
                                            <Button func={print} classes="btn-primary float-left" text={'Download Certificate'} />

                                    }
                                    <Button link="/badge" properties={state} classes="btn-primary float-left mx-3" text={'Badge'} />
                                    <div className=''>
                                        <a target="_blank" rel="noreferrer" href={`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${state.course.title}&organizationId=72763993&issueYear=${moment(state.course.completedDateTime).format("Y")}&issueMonth=${moment(state.course.completedDateTime).format("MM")}&certUrl=https://thesustainabilityacademy.co.uk/our-courses&certId=${state.course.id}`}>
                                            <img src="https://download.linkedin.com/desktop/add2profile/buttons/en_US.png " alt="LinkedIn Add to Profile button" />
                                        </a>
                                    </div>
                                </>
                            }

                            {state.startedDateTime !== null && state.lessonStatus !== 4 &&
                                <span data-target="#restart" data-toggle="modal">
                                    <Button text="Restart" classes="mr-2 btn-primary float-right" />
                                </span>
                            }
                        </div>
                    </div>
                </Card>
            </div >
        </>
    );
};

export default Engage;