import React, { useState } from 'react';
import Button from './../../../Shared/Components/Button/Button';

const FormPreview = ({ feedbackForm, submit, setFeedbackForm }) => {
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [errors, setErrors] = useState([]);

    const selectAnswer = (qId, aId) => {

        let answer = { questionId: qId, answerId: aId };
        if (containsAnswer(qId, aId)) {
            const index = selectedAnswers.findIndex(x => x.answerId === aId && x.questionId === qId);
            if (index > -1) {
                selectedAnswers.splice(index, 1);
                let newArray = [...selectedAnswers]
                setSelectedAnswers(newArray);
            }
        }
        else if (containsQuestion(qId)) {
            const index = selectedAnswers.findIndex(x => x.questionId === qId);
            if (index > -1) {
                selectedAnswers.splice(index, 1);
                let newArray = [...selectedAnswers, answer]
                setSelectedAnswers(newArray);
            }
        }
        else {
            setSelectedAnswers([...selectedAnswers, answer]);
        }
    }

    const containsAnswer = (qId, aId) => {
        var i;
        for (i = 0; i < selectedAnswers.length; i++) {
            if (selectedAnswers[i].questionId === qId && selectedAnswers[i].answerId === aId) {
                return true;
            }
        }

        return false;
    }

    const containsQuestion = (qId) => {
        var i;
        for (i = 0; i < selectedAnswers.length; i++) {
            if (selectedAnswers[i].questionId === qId) {
                return true;
            }
        }

        return false;
    }

    const validateForm = () => {
        let formErrors = [];
        feedbackForm.questions.forEach(q => {
            if (!containsQuestion(q.id)) {
                formErrors.push(q.id);
            }
        });
        feedbackForm.freeTextQuestions.forEach(q => {
            if (q.answerValue === "" || q.answerValue === null) {
                formErrors.push(q.id);
            }
        });
        setErrors(formErrors);
    }

    const handleFreeTextChange = evt => {
        let id = evt.target.id;
        let value = evt.target.value;
        let newQuestions = feedbackForm.freeTextQuestions.map(x => x);

        newQuestions.forEach(x => {
            if (x.id === id) {
                x.answerValue = value;
            }
        })

        setFeedbackForm({ ...feedbackForm, freeTextQuestions: newQuestions });
    }

    const handleSubmit = () => {
        validateForm();
        if (errors.length === 0) {

        }
    }

    return (
        <>
            <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content p-3">
                    <div className="modal-header">
                        <h5 className="modal-title">Feedback</h5>
                        <span className="fa fa-times clickable" data-dismiss="modal" aria-label="Close"></span>
                    </div>
                    <div className="modal-body">
                        {
                            feedbackForm.questions.map((q, i) =>
                                <div key={i}>
                                    {q.answers.length >= 1 &&
                                        <div className="form-row mb-3" >
                                            <div className="col-12 pb-0">
                                                <strong className='mb-0'>{q.value}</strong>
                                            </div>
                                            {q.answers.map((fa, i) =>
                                                <div key={i} className="radio form-check form-check-inline ml-1 mt-3">
                                                    <input onClick={() => { selectAnswer(q.id, fa.id) }} className="form-check-input" type="radio" value={fa.id} checked={containsAnswer(q.id, fa.id)} />
                                                    <label className="form-check-label">{fa.value}</label>
                                                </div>
                                            )}
                                            <div className={`col-12 ${errors.includes(q.id) ? "" : "d-none"}`}>
                                                <span style={{ "color": "#f10000", fontSize: "0.8rem" }}>Please select an answer</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        }
                        {
                            feedbackForm.freeTextQuestions.map((q, i) =>
                                <div className="form-row mb-3" key={i}>
                                    <div className="col-12 pb-0">
                                        <strong className='mb-2'>{q.value}</strong>
                                    </div>
                                    <div className="col-12 pb-0">
                                        <textarea id={q.id} className='form-control' onChange={handleFreeTextChange}
                                            value={q.answerValue} />
                                    </div>
                                    <div className={`col-12 ${errors.includes(q.id) ? "" : "d-none"}`}>
                                        <span style={{ "color": "#f10000", fontSize: "0.8rem" }}>Please provide an answer</span>
                                    </div>
                                </div>
                            )
                        }
                        <div className="col-12 mt-3">
                            <Button text="Submit" classes="btn-success float-right" func={handleSubmit} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FormPreview;