import React, { useEffect, useState } from 'react';
import Header from '../../Shared/Components/Header/Header';
import Card from '../../Shared/Components/Card/Card';

const DevStatus = () => {
    const [runtime, setRuntime] = useState(
        {
            Self: {
                status: "",
                data: {},
                tags: []
            },
            ApplicationDbContext: {
                status: "",
                data: {},
                tags: []
            },
            AzureServiceBus: {
                status: "",
                data: {},
                tags: []
            }
        }
    )

    const [gateway, setGateway] = useState(
        {
            Self: {
                status: "",
                data: {},
                tags: []
            },
            ApplicationDbContext: {
                status: "",
                data: {},
                tags: []
            },
            AzureServiceBus: {
                status: "",
                data: {},
                tags: []
            }
        }
    )

    // useEffect(() => {
    //     //let host = window.location.href;
    //     let host = 'elearning.qa.academyone.co.uk'
    //     fetch(`https://content.${host}/readiness`)
    //         .then(response => response.json())
    //         .then(json => setRuntime(json.entries));
    //     fetch(`https://api.${host}/readiness`)
    //         .then(response => response.json())
    //         .then(json => setGateway(json.entries));
    // }, [])

    return (
        <>
            <Header heading="DevOps" icon="user" />
            <div className="container-fluid content">
                <Card title="Public api's">
                    <div className="row">
                        <div className="col-6">
                            <strong><p>API Gateway</p></strong>
                            <p>Self: {gateway.Self.status}</p>
                            <p>Application Db Context: {gateway.ApplicationDbContext.status}</p>
                            <p>Azure Service Bus: {gateway.AzureServiceBus.status}</p>
                        </div>
                        <div className="col-6">
                            <strong><p>Runtime environment</p></strong>
                            <p>Self: {runtime.Self.status}</p>
                            <p>Application Db Context: {runtime.ApplicationDbContext.status}</p>
                            <p>Azure Service Bus: {runtime.AzureServiceBus.status}</p>
                        </div>
                    </div>
                </Card>
                <Card title="Backlog Status">
                    <div className="row">
                        <div className="col-12">
                            <img className="img-fluid" src="https://dev.azure.com/FSDigital/a88136f3-e280-4605-9c39-e5b8dcbaab7b/91ae7d66-f4ea-464e-bbc7-074f5778689c/_apis/work/boardbadge/ec130ba2-a8bf-42e8-849d-2b80d87a380f" />
                        </div>
                    </div>
                </Card>
                <Card title="Website CI/CD pipeline">
                    <div className="row">
                        <div className="col-12">
                            <img className="img-fluid" src="https://dev.azure.com/FSDigital/Academy%20One/_apis/build/status/AcademyOne.Website?repoName=AcademyOne.Website&branchName=master" />
                        </div>
                    </div>
                </Card>
                <Card title="eLearning frontend CI/CD pipeline">
                    <div className="row">
                        <div className="col-12">
                            <img className="img-fluid" src="https://dev.azure.com/FSDigital/Academy%20One/_apis/build/status/AcademyOne.ELearning?repoName=AcademyOne.ELearning&branchName=master" />
                        </div>
                    </div>
                </Card>
                <Card title="API Gateway CI/CD pipeline">
                    <div className="row">
                        <div className="col-12">
                            <img className="img-fluid" src="https://dev.azure.com/FSDigital/Academy%20One/_apis/build/status/AcademyOne.Api?repoName=AcademyOne.Api&branchName=master" />
                        </div>
                    </div>
                </Card>

                <Card title="Runtime environment CI/CD pipeline">
                    <div className="row">
                        <div className="col-12">
                            <img className="img-fluid" src="https://dev.azure.com/FSDigital/Academy%20One/_apis/build/status/AcademyOne.RunTimeEnvironment?repoName=AcademyOne.RunTimeEnvironment&branchName=master" />
                        </div>
                    </div>
                </Card>
                <Card title="Notifications API CI/CD pipeline">
                    <div className="row">
                        <div className="col-12">
                            <img className="img-fluid" src="https://dev.azure.com/FSDigital/Academy%20One/_apis/build/status/AcademyOne.Notifications?repoName=AcademyOne.Notifications&branchName=master" />
                        </div>
                    </div>
                </Card>
                <Card title="Ordering API CI/CD pipeline">
                    <div className="row">
                        <div className="col-12">
                            <img className="img-fluid" src="https://dev.azure.com/FSDigital/Academy%20One/_apis/build/status/AcademyOne.Ordering?repoName=AcademyOne.Ordering&branchName=master" />
                        </div>
                    </div>
                </Card>
                <Card title="Course management API CI/CD pipeline">
                    <div className="row">
                        <div className="col-12">
                            <img className="img-fluid" src="https://dev.azure.com/FSDigital/Academy%20One/_apis/build/status/AcademyOne.Ordering?repoName=AcademyOne.Ordering&branchName=master" />
                        </div>
                    </div>
                </Card>
                <Card title="Reporting API CI/CD pipeline">
                    <div className="row">
                        <div className="col-12">
                            <img className="img-fluid" src="https://dev.azure.com/FSDigital/Academy%20One/_apis/build/status/AcademyOne.Reporting?repoName=AcademyOne.Reporting&branchName=master" />
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default DevStatus;