import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { playCourseUrl } from '../../../Infrastructure/Config/ApiConfig';
import { showToast } from '../../../Infrastructure/Notifications/ToastrConfig';
import { deleteCourseAsync, getCourseByIdAsync, updateCourseAsync } from '../../../Services/CourseService';
import Card from '../../../Shared/Components/Card/Card';
import Header from '../../../Shared/Components/Header/Header';
import Modal from '../../../Shared/Components/Modal/Modal';
import PrimaryButton from '../../../Shared/Components/PrimaryButton/PrimaryButton';

const Course = () => {
    const [course, setCourse] = useState({ title: '', description: '', reference: '', feedbackForm: null });
    const { id } = useParams();
    const [editMode, setEditMode] = useState(false);
    const [file, setFile] = useState(null);
    const history = useHistory();
    const [uploading, setUploading] = useState(false);
    const [badge, setBadge] = useState(null);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        const getCourse = async () => {
            let response = await getCourseByIdAsync(id);
            setCourse(response);
        }
        getCourse();
    }, [id, editMode]);

    const handleChange = evt => {
        setCourse({ ...course, [evt.target.name]: evt.target.value });
    }

    const launchCourse = async () => {
        window.open(`${playCourseUrl()}preview/${course.id}`, course.title, 'toolbar=no,menubar=no,location=no')
    }

    const handleFileChange = evt => {
        setFile(evt.target.files[0]);
    }

    const handleBadgeChange = evt => {
        setBadge(evt.target.files[0]);
    }

    const handleValidation = () => {
        let fields = course;
        let errors = {};
        let formIsValid = true;

        //title
        if (!fields["title"]) {
            formIsValid = false;
            errors["title"] = "Please enter a title";
        }

        //description
        if (!fields["description"]) {
            formIsValid = false;
            errors["description"] = "Please enter a description";
        }

        //reference
        if (!fields["reference"]) {
            formIsValid = false;
            errors["reference"] = "Please add a reference";
        }

        setErrors(errors);
        return formIsValid;
    }

    const updateCourse = async () => {
        if (handleValidation()) {
            setUploading(true);
            let form = new FormData();
            form.append("CourseId", course.id);
            form.append("Reference", course.reference);
            form.append("Title", course.title);
            form.append("Description", course.description);
            form.append("ScormFile", file);
            form.append("BadgeImage", badge);

            let response = await updateCourseAsync(form);

            if (response.success) {
                showToast(true, "Course updated");
                setEditMode(false);
                setUploading(false);
            }
            else {
                showToast(false, "Something went wrong");
            }
        }
    }

    const deleteCourse = async () => {
        let data = {
            courseId: id
        }

        let response = await deleteCourseAsync(data);
        if (response) {
            showToast(true, "Course deleted");
            history.goBack();
        }
        else {
            showToast(false, "Something went wrong");
        }
    }

    return (
        <>
            <Modal buttonFunc={deleteCourse}
                modalId={"deleteCourse"}
                bodyText="Are you sure you want to delete this course?"
                title={`Delete ${course.title}`}
                buttonText="Delete"
            />
            <Header heading={course.title} subheading={course.reference} icon="book" />
            <div className="container-fluid content">
                <Card title="details">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label>Title</label>
                                <input onChange={handleChange} className="form-control" name="title" type="text" disabled={!editMode} value={course.title} />
                                <small className="errorMessage">{errors.title}</small>
                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <textarea style={{ "minHeight": "150px" }} onChange={handleChange} className="form-control" name="description" disabled={!editMode} value={course.description} />
                                <small className="errorMessage">{errors.description}</small>
                            </div>
                            <div className="form-group">
                                <label>Reference</label>
                                <input onChange={handleChange} className="form-control" name="reference" disabled={!editMode} value={course.reference} />
                                <small className="errorMessage">{errors.reference
                                }</small>
                            </div>
                            <div className="form-group">
                                <label>SCORM File</label>
                                <div className="custom-file">
                                    <input className="custom-file-input" name="file" id="file" type="file" accept=".zip" disabled={!editMode} onChange={handleFileChange} />
                                    <label className="custom-file-label">{file === null ? 'Browse' : file.name}</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Badge</label>
                                <div className="custom-file">
                                    <input className="custom-file-input" name="badge" id="badge" type="file" accept=".png,.jpeg,.jpg," disabled={!editMode} onChange={handleBadgeChange} />
                                    <label className="custom-file-label">{badge === null ? 'Browse' : badge.name}</label>
                                </div>
                            </div>
                            {!editMode ?
                                <PrimaryButton func={() => { setEditMode(true) }} text="Edit" classes="float-right mt-2" />
                                :
                                <>
                                    {uploading ?
                                        <PrimaryButton func={() => { updateCourse() }} classes="float-right mt-2" disabled={uploading}>
                                            <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                Please wait
                                            </>
                                        </PrimaryButton>
                                        :
                                        <PrimaryButton func={updateCourse} text="Save" classes="float-right mt-2" />
                                    }
                                    <PrimaryButton func={() => { setEditMode(false) }} text="Cancel" classes="mr-3 float-right mt-2" />
                                </>
                            }
                            {!editMode ?
                                <PrimaryButton text="Preview" func={launchCourse} classes="float-left mt-2" />
                                :
                                <button data-toggle="modal" data-target={`#deleteCourse`} className="btn btn-danger mt-2">Delete</button>
                            }
                        </div>
                    </div>
                </Card>
                <Card title="Feedback Form">
                    <div className="row">
                        <div className="col-12">
                            {
                                course.feedbackForm ?
                                    <p>Show form</p>
                                    :
                                    <div className="text-center">
                                        <p>This course has no active feedback form associated with it</p>
                                        <PrimaryButton func={() => { setEditMode(false) }} text="Create" classes="float-right mt-2" />
                                    </div>
                            }
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default Course;