import axios from 'axios';
import { baseUrl } from '../Infrastructure/Config/ApiConfig';
import { token } from './AuthService';

const controllerName = 'Course';

export const uploadCourseAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/UploadCourse`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const updateCourseAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/UpdateCourse`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const populateCourseDropdownAsync = async (pathwayId) => {
    let qry = '';
    if (pathwayId !== undefined) {
        qry = '?pathwayId=' + pathwayId
    }
    const response = await axios.get(`${baseUrl()}${controllerName}/populateCourseDropdown${qry}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const createPathwayAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/CreatePathway`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const updatePathwayAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/UpdateLearningPathway`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getLearningPathwaysAsync = async () => {
    const response = await axios.get(`${baseUrl()}${controllerName}/GetAllLearningPathways`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getClientLearningPathwaysAsync = async () => {
    const response = await axios.get(`${baseUrl()}${controllerName}/GetAllClientLearningPathways`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const addCoursesToPathwayAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/AddCourseToPathway`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getLearningPathwayAsync = async (id) => {
    const response = await axios.get(`${baseUrl()}${controllerName}/GetLearningPathway/${id}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const assignClientToPathwayAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/AssignPathwayToClient`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const removePathwayFromClientAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/RemovePathwayFromClient`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getAllCoursesAsync = async (pageNumber) => {
    const response = await axios.get(`${baseUrl()}${controllerName}/GetAllCourses/${pageNumber}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getCourseByIdAsync = async (courseId) => {
    const response = await axios.get(`${baseUrl()}${controllerName}/getCourseById/${courseId}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getLearnerCourseAsync = async (courseId) => {
    const response = await axios.get(`${baseUrl()}${controllerName}/GetLearnerCourse/${courseId}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getCourseProgressAsync = async (courseId, clientId, pageNumber) => {
    const response = await axios.get(`${baseUrl()}${controllerName}/GetCourseProgress?courseId=${courseId}&pageNumber=${pageNumber}&clientId=${clientId}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });

    return response.data;
}

export const getClientCoursesAsync = async () => {
    const response = await axios.get(`${baseUrl()}${controllerName}/GetClientCourses`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getClientProgressCoursesAsync = async (clientId) => {
    const response = await axios.get(`${baseUrl()}${controllerName}/GetClientProgressCourses/${clientId}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const restartCourseAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/ResetCourseProgress`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const deleteCourseAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/DeleteCourse`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const deletePathwayAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/DeletePathway`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const removeCourseFromPathwayAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/RemoveCourseFromPathway`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}