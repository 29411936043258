import React from 'react';

const Answer = ({ fa, deleteAnswerFromQuestion, isDragging }) => {
    return (

        <div className={`col-12  ${isDragging ? "answerHover" : "answer"}`}>
            <div className="float-left drag">
                <span className={`clickable float-right fa fa-bars mr-3`}></span>
            </div>
            <p className="">{fa.value}
                <span onClick={() => deleteAnswerFromQuestion(fa.id)} className="fa fa-times ml-3 clickable float-right">
                </span>
            </p>
        </div>
    );
};

export default Answer;