import React, { useState } from 'react';
import PrimaryButton from '../../../Shared/Components/PrimaryButton/PrimaryButton';
import { createpostAsync } from '../../../Services/CommunityServie';

const CreatePost = ({ communityId, reload }) => {
    const [state, setState] = useState({ title: "", text: "" });

    const handleChange = evt => setState({ ...state, [evt.target.name]: evt.target.value });
    const handleSubmit = async () => {
        if (state.title === "" || state.text === "") return alert("Please provide a title and body");
        await createpostAsync({
            ...state,
            communityId: communityId
        });
        setState({ title: "", text: "" });
        reload();
    }
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="form">
                        <div className="form-group">
                            <label>Title</label>
                            <input className="form-control" name="title" id="title" value={state.title} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Body</label>
                            <textarea style={{ "minHeight": "75px" }} value={state.text} className="form-control" name="text" id="text" onChange={handleChange} />
                        </div>
                        <PrimaryButton text="Create" func={handleSubmit} classes="float-right mt-3" />

                    </div>
                </div>
            </div>
        </>
    )
};

export default CreatePost;