import React, { createContext } from 'react';

export const ThemeContext = createContext();

const ThemeContextProvider = props => {
    return (
        <ThemeContext.Provider value={{ ...props.theme }}>
            {props.children}
        </ThemeContext.Provider>
    );
};

export default ThemeContextProvider;