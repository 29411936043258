import React, { useState } from 'react';
import { showToast } from '../../Infrastructure/Notifications/ToastrConfig';
import { createNewClientAsync } from '../../Services/AccountService';
import Header from './../../Shared/Components/Header/Header';
import Card from './../../Shared/Components/Card/Card';
import PrimaryButton from './../../Shared/Components/PrimaryButton/PrimaryButton';
import { useHistory } from 'react-router-dom';
import { validEmailRegex } from './../../Infrastructure/Config/Regexes';

const CreateClient = () => {
    const [state, setState] = useState({
        clientName: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        referalCode: '',
        maximumNumberOfLearners: 0
    });
    const [errors, setErrors] = useState([]);
    const history = useHistory();

    const handleChange = async evt => {
        setState({ ...state, [evt.target.name]: evt.target.value });
    }

    const handleValidation = () => {
        let fields = state;
        let errors = {};
        let formIsValid = true;

        //clientName
        if (!fields["clientName"]) {
            formIsValid = false;
            errors["clientName"] = "Please enter a client name";
        }

        //firstName
        if (!fields["firstName"]) {
            formIsValid = false;
            errors["firstName"] = "Please enter a first name";
        }

        //lastName
        if (!fields["lastName"]) {
            formIsValid = false;
            errors["lastName"] = "Please enter a last name";
        }

        //email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Please enter an email address";
        } else {
            if (!validEmailRegex.test(fields["email"])) {
                formIsValid = false;
                errors["email"] = "Please enter a valid email address";
            }
        }
        //phoneNumber
        if (!fields["phoneNumber"]) {
            formIsValid = false;
            errors["phoneNumber"] = "Please add a phone number";
        }

        //referalCode
        if (!fields["referalCode"]) {
            formIsValid = false;
            errors["referalCode"] = "Please add a referral code";
        }

        //numberOfLearners
        if (!fields["numberOfLearners"]) {
            formIsValid = false;
            errors["numberOfLearners"] = "Please select number of learners";
        }

        setErrors(errors);
        return formIsValid;
    }

    const handleSubmit = async () => {
        if (handleValidation()) {
            let data = {
                clientName: state.clientName,
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                phoneNumber: state.phoneNumber,
                referalCode: state.referalCode,
                maximumNumberOfLearners: state.numberOfLearners
            }

            let response = await createNewClientAsync(data);
            if (response.success) {
                showToast(true, "Client created");
                history.push("/clients");
            }
            else {
                showToast(false, response.errors[0].error);
            }
        }
    }

    return (
        <>
            <Header heading="Create Client" subheading="Fill in the deatils below and the client will recieve an email to complete registration" icon="apartment" />
            <div className="container-fluid content">
                <Card title="Details">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label>Company Name</label>
                            <input value={state.clientName} className="form-control" name="clientName" id="clientName" onChange={handleChange} />
                            <small className="errorMessage">{errors.clientName}</small>
                        </div>
                        <div className="form-group col-md-6">
                            <label>First Name</label>
                            <input value={state.firstName} className="form-control" name="firstName" id="firstName" onChange={handleChange} />
                            <small className="errorMessage">{errors.firstName}</small>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Last Name</label>
                            <input value={state.lastName} className="form-control" name="lastName" id="lastName" onChange={handleChange} />
                            <small className="errorMessage">{errors.lastName}</small>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Email</label>
                            <input value={state.email} className="form-control" name="email" id="email" onChange={handleChange} />
                            <small className="errorMessage">{errors.email}</small>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Phone Number</label>
                            <input value={state.phoneNumber} className="form-control" name="phoneNumber" id="phoneNumber" onChange={handleChange} />
                            <small className="errorMessage">{errors.phoneNumber}</small>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Referral Code</label>
                            <input value={state.referalCode} className="form-control" name="referalCode" id="referalCode" onChange={handleChange} />
                            <small className="errorMessage">{errors.referalCode}</small>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Number of Learners</label>
                            <input value={state.numberOfLearners} type="number" className="form-control" name="numberOfLearners" id="numberOfLearners" onChange={handleChange} />
                            <small className="errorMessage">{errors.numberOfLearners}</small>
                        </div>
                    </div>
                    <div className="form-group">
                        <PrimaryButton text="Create Client" func={handleSubmit} classes="float-right mt-2" />
                    </div>

                </Card>
            </div>
        </>
    );
};

export default CreateClient;