import React from 'react';

const Uploading = ({ progress }) => {
    return (
        <div className="row pt-5">
            <div className="col-12 text-center pt-5">
                <h1>Uploading</h1>
            </div>
            <div className="col-12 col-md-4 offset-md-4 text-center">
                <div
                    className="progress-bar progress-bar-info progress-bar-striped mt-5"
                    role="progressbar"
                    style={{ width: progress + "%" }}
                >
                    {progress}%
                </div>
            </div>
        </div>
    );
};

export default Uploading;