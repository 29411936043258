import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { getAllCoursesAsync } from '../../../Services/CourseService';
import {
    addAnswerToQuestionAsync,
    addQuestionToFormAsync,
    assignClientToFeedbackFormAsync,
    assignFeedbackFormToCourseAsync,
    createCopyOfFeedbackFormAsync,
    deleteAnswerFromQuestionAsync,
    deleteFeedbackFormAsync,
    deleteQuestionFromFeedbackFormAsync,
    getFeedbackFormAsync,
    RemoveClientFromFeedbackFormAsync,
    updateQuestionOrdinalsAsync
} from '../../../Services/FeedbackFormService';
import Card from '../../../Shared/Components/Card/Card';
import Header from '../../../Shared/Components/Header/Header';
import FormPreview from './FormPreview';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Question from './components/Question';
import Button from './../../../Shared/Components/Button/Button';
import './FeedbackForm.scss';
import { clientListAsync } from '../../../Services/AdminService';
import LineIcon from 'react-lineicons';
import FreeTextQuestion from './components/FreeTextQuestion';
import Modal from '../../../Shared/Components/Modal/Modal';

const FeedbackForm = () => {

    const { id } = useParams();
    const [feedbackForm, setFeedbackForm] = useState({ questions: [{ answers: [] }], freeTextQuestions: [], course: {}, clients: null });
    const [questionValue, setQuestionValue] = useState("");
    const [answerValue, setAnswerValue] = useState({});
    const history = useHistory();
    const [refreshTrigger, setRefreshTrigger] = useState(false);
    const [courses, setCourses] = useState([]);
    const [collapsed, setCollapsed] = useState(true);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [isFreeText, setIsFreeText] = useState(false);

    useEffect(() => {
        const getForm = async () => {
            let response = await getFeedbackFormAsync(id);
            setFeedbackForm(response);
        };

        const getCourses = async () => {
            let response = await getAllCoursesAsync(1);
            setCourses(response.courses);
        };
        const getClients = async () => {
            let response = await clientListAsync();
            setClients(response.clients);
        };
        getForm();
        getCourses();
        getClients();
    }, [id, refreshTrigger])

    const triggerRefresh = () => {
        setRefreshTrigger(!refreshTrigger);
    }

    const addQuestion = async () => {

        let dataToPost = {
            feedbackFormId: id,
            value: questionValue,
            ordinal: feedbackForm.questions.length,
            isFreeText: isFreeText
        }

        let resposne = await addQuestionToFormAsync(dataToPost);
        dataToPost.feedbackAnswers = [];
        dataToPost.id = resposne.id;
        triggerRefresh();
        setQuestionValue("");
    }

    const handleQuestionValueChange = event => {
        setQuestionValue(event.target.value);
    }

    const handleAnswerChange = event => {
        let answer = {
            ordianl: 1,
            value: event.target.value,
            questionId: event.target.name
        }

        setAnswerValue(answer);
    }

    const addAnswer = async () => {
        await addAnswerToQuestionAsync(answerValue);
        triggerRefresh();
        setAnswerValue({ value: "" });
    }

    const createCopy = async () => {
        let dataToPost = {
            feedbackFormId: id
        }

        let response = await createCopyOfFeedbackFormAsync(dataToPost);
        history.push(`${response.feedbackFormId}`);
    }

    const deleteQuestion = async (questionId) => {
        let data = {
            questionId: questionId,
            formId: feedbackForm.id
        }

        await deleteQuestionFromFeedbackFormAsync(data);
        triggerRefresh();
    }

    const deleteAnswerFromQuestion = async (answerId) => {
        let data = {
            answerId: answerId,
        }

        await deleteAnswerFromQuestionAsync(data);
        triggerRefresh();
    }

    const handleCheck = evt => setIsFreeText(evt.target.checked);

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        ...draggableStyle
    });

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const getListStyle = isDraggingOver => ({
        width: "100%"
    });

    const updateOrdinals = async (questions) => {
        let updatedQuestions = [];

        questions.forEach((a, i) => {
            updatedQuestions.push({ id: a.id, ordinal: i })
        });

        let data = {
            questions: updatedQuestions
        }

        await updateQuestionOrdinalsAsync(data);
    }

    const onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            feedbackForm.questions,
            result.source.index,
            result.destination.index
        );
        let updatedForm = { ...feedbackForm };
        updatedForm.questions = items;
        updateOrdinals(items);
        setFeedbackForm(updatedForm);
    }

    const onFreeTextDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            feedbackForm.freeTextQuestions,
            result.source.index,
            result.destination.index
        );
        let updatedForm = { ...feedbackForm };
        updatedForm.freeTextQuestions = items;
        updateOrdinals(items);
        setFeedbackForm(updatedForm);
    }

    const handleCourseChange = evt => {
        setFeedbackForm({ ...feedbackForm, course: { id: evt.target.value } });
    }

    const handleClientChange = evt => {
        setSelectedClient(evt.target.value);
    }

    const assign = async () => {
        let data = {
            courseId: feedbackForm.course.id,
            feedbackFormId: id
        }
        await assignFeedbackFormToCourseAsync(data);
    }

    const assignClient = async () => {
        let data = {
            feedbackFormId: feedbackForm.id,
            clientId: selectedClient
        }
        await assignClientToFeedbackFormAsync(data);
        setSelectedClient("");
        triggerRefresh();
    }

    const removeClient = async (cId) => {
        let data = {
            feedbackFormId: feedbackForm.id,
            clientId: cId
        }
        await RemoveClientFromFeedbackFormAsync(data);
        triggerRefresh();
    }


    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    }

    const deleteFeedback = async () => {

        let data = {
            id: feedbackForm.id
        }
        await deleteFeedbackFormAsync(data);
        history.push('/feedback-forms');
    }

    return (
        <>
            <Modal
                modalId="deleteFeedback"
                bodyText={`Are you sure you want to delete this feedback form?`}
                buttonFunc={deleteFeedback}
                buttonText="Delete"
                title={`Delete feedback form`}
            />
            <div className="modal fade preview" id={"preview-modal"} aria-hidden="true">
                <FormPreview id={id} feedbackForm={feedbackForm} setFeedbackForm={setFeedbackForm} />
            </div>
            <Header heading={FeedbackForm.title} subheading={"Manage feedback form"} icon="book" />
            <div className="container-fluid content feedbackform">
                <Card>
                    <Button text="Preview" classes="btn btn-primary" dataTarget="preview-modal" />
                    <Button text="Save" func={addAnswer} classes="btn-success float-right" />
                    <Button text="Create Copy" func={createCopy} classes="btn-primary mr-2 float-right" />
                    <button className='btn btn-danger mr-2 float-right' data-target="#deleteFeedback" data-toggle="modal">Delete</button>

                </Card>
                <Card title="Form Details">
                    <div className="row">
                        <div className="col-12">
                            <div className="form">
                                <label>Title</label>
                                <div className="form-group">
                                    <input className="form-control" type="text" value={feedbackForm.title} />
                                </div>
                                <label>Description</label>
                                <div className="form-group">
                                    <input className="form-control" type="text" value={feedbackForm.description} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                <Card title="Assign course">
                    <div className="row">
                        <div className="col-12">
                            <label>Select Course</label>
                            <div className="form row col-12">
                                <div className="input-group">
                                    <select className="form-control" onChange={handleCourseChange} value={feedbackForm.course === null ? "" : feedbackForm.course.id}>
                                        <option value={""}>Not assigned</option>
                                        {courses.map((c, i) => <option value={c.id} key={i}>{c.title}</option>)}
                                    </select>
                                    <div className="input-group-append">
                                        <Button text="Assign" func={assign} classes="btn-primary float-left btn-append" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                <Card title="Assign clients">
                    <div className="row">
                        <div className="col-12">
                            <label>Select Client</label>
                            <div className="form row col-12">
                                <div className="input-group">
                                    <select className="form-control" onChange={handleClientChange} value={selectedClient === null ? "" : selectedClient}>
                                        <option value={""}>Please select</option>
                                        {clients.map((c, i) => <option value={c.id} key={i}>{c.name}</option>)}
                                    </select>
                                    <div className="input-group-append">
                                        <Button text="Assign" func={assignClient} classes="btn-primary float-left btn-append" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 pt-3">
                            {feedbackForm.clients !== null && feedbackForm.clients.map((c, i) =>
                                <span key={i} className="badge badge-pill badge-primary mr-2">{c.name}
                                    <LineIcon onClick={() => removeClient(c.id)} name="cross-circle" style={{ cursor: "pointer", marginLeft: "10px", color: "#000" }} />
                                </span>
                            )}
                        </div>
                    </div>
                </Card>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >

                                {feedbackForm.questions.map((q, i) =>

                                    <Draggable key={q.id} draggableId={q.id} index={i} isDragDisabled={!collapsed}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <Question q={q} i={i}
                                                    deleteQuestion={deleteQuestion}
                                                    handleAnswerChange={handleAnswerChange}
                                                    addAnswer={addAnswer}
                                                    deleteAnswerFromQuestion={deleteAnswerFromQuestion}
                                                    answerValue={answerValue}
                                                    setFeedbackForm={setFeedbackForm}
                                                    feedbackForm={feedbackForm}
                                                    collapsed={collapsed}
                                                    toggleCollapsed={toggleCollapsed}
                                                />

                                            </div>
                                        )}
                                    </Draggable>
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                <DragDropContext onDragEnd={onFreeTextDragEnd}>
                    <Droppable droppableId="droppable2">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >

                                {feedbackForm.freeTextQuestions.map((q, i) =>

                                    <Draggable key={q.id} draggableId={q.id} index={i} isDragDisabled={!collapsed}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <FreeTextQuestion q={q} i={i}
                                                    deleteQuestion={deleteQuestion}
                                                    handleAnswerChange={handleAnswerChange}
                                                    addAnswer={addAnswer}
                                                    deleteAnswerFromQuestion={deleteAnswerFromQuestion}
                                                    answerValue={answerValue}
                                                    setFeedbackForm={setFeedbackForm}
                                                    feedbackForm={feedbackForm}
                                                    collapsed={collapsed}
                                                    toggleCollapsed={toggleCollapsed}
                                                />

                                            </div>
                                        )}
                                    </Draggable>
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                <Card title="Add new question">
                    <div className="row">
                        <div className='col-12'>
                            <div className='float-right'>
                                <label>Is free text?</label>
                                <input type="checkbox" className='ml-3' onChange={handleCheck} />
                            </div>
                        </div>
                        <div className="col-12 input-group mb-3">
                            <input className="form-control" type="text" placeholder="Add Question..." onChange={handleQuestionValueChange} value={questionValue} />
                            <div className="input-group-append">
                                <Button text="Add" func={addQuestion} classes="btn-primary btn-append float-left" />
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default FeedbackForm;