import React from 'react';
import Table from '../../../Shared/Components/Table';
import { Link } from 'react-router-dom';

const ClientUsers = ({ users }) => {

    const headings = () => {
        return (<>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th></th>
        </>)
    }

    const rowData = () => {
        return (<>
            {users.map(
                (u, i) =>
                    <tr key={i}>
                        <td>
                            {u.firstName} {u.lastName}
                        </td>
                        <td>
                            {u.email}
                        </td>
                        <td>
                            {u.identityRole.name}
                        </td>
                        <td>
                            <Link to={`/user/${u.urlToken}`}>
                                <span className="view fas fa-eye float-right mr-3" title="View"></span>
                            </Link>
                        </td>
                    </tr>
            )}
        </>)
    }

    return (
        <Table rowData={rowData} headings={headings} />
    );
};

export default ClientUsers;