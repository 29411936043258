import React, { useState } from 'react';
import { showToast } from '../../../Infrastructure/Notifications/ToastrConfig';
import { archiveClientAsync, deleteClientAsync, pauseClientAsync, unpauseClientAsync, updateMaxLearnersAsync } from './../../../Services/AdminService';
import PrimaryButton from './../../../Shared/Components/PrimaryButton/PrimaryButton';
import { useHistory } from 'react-router-dom';
import Card from './../../../Shared/Components/Card/Card';

const ClientActions = ({ client, getClient }) => {
    const [maxLearners, setMaxLearners] = useState(0);
    const history = useHistory();

    const pauseClient = async () => {

        let data = {
            clientId: client.id,
        }

        let response = await pauseClientAsync(data);
        if (response.success) {
            showToast(true, "Client paused")
            getClient();
        }
        else {
            showToast(false, response.errors[0].error)
        }
    }

    const unPauseClient = async () => {
        let data = {
            clientId: client.id,
        }

        let response = await unpauseClientAsync(data);
        if (response) {
            showToast(true, "Client unpaused");
            getClient();
        }
        else {
            showToast(false, "Something went wrong")
        }
    }

    const deleteClient = async () => {
        let data = {
            clientId: client.id,
        }

        let response = await deleteClientAsync(data);
        if (response.success) {
            showToast(true, "Client deleted");
            history.push('/clients');
        }
        else {
            showToast(false, response.errors[0].error)
        }
    }

    const archiveClient = async () => {
        let data = {
            clientId: client.id,
        }

        let response = await archiveClientAsync(data);
        if (response.success) {
            showToast(true, "Client archived");
            history.push('/clients');
        }
        else {
            showToast(false, response.errors[0].error)
        }
    }

    const updateMaxLearners = async () => {

        let data = {
            clientId: client.id,
            maxLearners: maxLearners
        }

        let response = await updateMaxLearnersAsync(data);
        if (response.success) {
            showToast(true, "Maximum number of learners updated");
            getClient();
        }
        else {
            showToast(false, response.errors[0].error);
        }
    }

    const handleMaxLearnersChange = evt => {
        setMaxLearners(evt.target.value);
    }

    return (
        <div className="col-12">
            <Card title="Actions">
                <div className="row">
                    <div className="col-4">
                        <label>Maximum number of learners</label>
                        <div className="input-group mb-3">
                            <input onChange={handleMaxLearnersChange} className="form-control" type="number" defaultValue={client.maximumNumberOfLearners} min={client.numberOfLearners} />
                            <div className="input-group-append">
                                <PrimaryButton text="Update" func={updateMaxLearners} classes="action-btn" />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group float-right">
                            {client.isPaused ?
                                <PrimaryButton text="Unpause Client" classes="mr-3 action-btn" func={unPauseClient} />
                                :
                                <PrimaryButton text="Pause Client" classes="mr-3 action-btn" func={pauseClient} />
                            }
                            <PrimaryButton text="Delete Client" func={deleteClient} classes="mr-3 action-btn" />
                            <PrimaryButton text="Archive Client" func={archiveClient} classes="mr-3 action-btn" />

                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default ClientActions;