import React, { useEffect, useState } from 'react';
import { LEARNER, userIsInRole } from '../../Services/AuthService';
import { getDashboardAsync } from '../../Services/DashboardService';
import StatCard from '../../Shared/Components/StatCard/StatCard';
import StatProgressCard from '../../Shared/Components/StatProgressCard/StatProgressCard';
import Header from './../../Shared/Components/Header/Header';
import AdminLearnerDashboard from './AdminLearnerDashboard';

const ClientDashboard = () => {
    const [stats, setStats] = useState({});

    useEffect(() => {
        getDashboard();
    }, [])

    const getDashboard = async () => {
        let response = await getDashboardAsync();
        setStats(response);
    }

    return (
        <>
            <Header heading="Dashboard" subheading={stats.clientName} icon="bar-chart" />
            <div className="container-fluid content">
                {userIsInRole(LEARNER) && <AdminLearnerDashboard />}

                <div className="row">
                    <StatProgressCard
                        title="Total Learners"
                        subtext="Current number of assigned learners"
                        percentage={stats.numberOfLearners / stats.maximumNumberOfLearners * 100}
                        stat={stats.numberOfLearners}
                        footerText="Learners"
                        footerStat={`out of ${stats.maximumNumberOfLearners}`}
                    />
                    <StatCard
                        title="Learner logins"
                        subtext="Number of learner logins this month"
                        stat={stats.learnerLoginsThisMonth}
                    />
                    <StatCard
                        title="Courses completed"
                        subtext="Number of courses completed this month"
                        stat={stats.coursesCompletedThisMonth}
                    />
                    <StatCard
                        title="Courses in progress"
                        subtext="Number of courses currently in progress"
                        stat={stats.coursesInProgress}
                    />
                    <StatCard
                        title="Courses not started"
                        subtext="Number of courses not started"
                        stat={stats.coursesNotStarted}
                    />
                    <StatCard
                        title="Number of pathways"
                        subtext="Number of assigned learning pathways"
                        stat={stats.numberOfPathways}
                    />
                </div>
                {/* <h1>Member since: {stats.clientSince}</h1> */}
            </div>
        </>
    );
};

export default ClientDashboard;