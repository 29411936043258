import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { addCoursesToPathwayAsync, deletePathwayAsync, getLearningPathwayAsync, populateCourseDropdownAsync, removeCourseFromPathwayAsync, updatePathwayAsync } from '../../../Services/CourseService';
import { Multiselect } from 'multiselect-react-dropdown';
import PrimaryButton from '../../../Shared/Components/PrimaryButton/PrimaryButton';
import Header from '../../../Shared/Components/Header/Header';
import { showToast } from '../../../Infrastructure/Notifications/ToastrConfig';
import Card from '../../../Shared/Components/Card/Card';
import Table from '../../../Shared/Components/Table';
import Modal from '../../../Shared/Components/Modal/Modal';

const LearningPathway = () => {
    const { id } = useParams();
    const [pathway, setPathway] = useState({
        courses: [],
        name: "",
        description: ""
    });
    const [courses, setCourses] = useState([]);
    const [courseIds, setCourseIds] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [forceChange, setForceChange] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState({});
    const history = useHistory();

    useEffect(() => {
        const getPathway = async () => {
            let response = await getLearningPathwayAsync(id);
            setPathway(response);
        }

        const getCourses = async () => {
            let response = await populateCourseDropdownAsync(id);
            setCourses(response.courses);
        }
        getPathway();
        getCourses();
    }, [id, forceChange])

    const handleCourseSelect = selectedCourses => {
        setCourseIds(selectedCourses);
    }

    const addCoursesToPathway = async () => {
        let data = {
            learningPathwayId: id,
            courseIds: courseIds.map(c => c.id)
        }

        let response = await addCoursesToPathwayAsync(data);
        if (response) {
            showToast(true, "Course added to pathway");
            setForceChange(!forceChange);
        }
        else {
            showToast(false, "Something went wrong");
        }
    }

    const updatePathway = async () => {
        let data = {
            learningPathwayId: pathway.id,
            name: pathway.name,
            description: pathway.description
        }
        let response = await updatePathwayAsync(data);
        if (response.success) {
            showToast(true, "Pathway updated");
        }
        else {
            showToast(false, "Something went wrong");
        }
    }

    const handleChange = evt => {
        setPathway({ ...pathway, [evt.target.name]: evt.target.value });
    }

    const headings = () => {
        return (
            <>
                <th>Title</th>
                <th>Description</th>
                <th></th>
            </>
        )
    }

    const rowData = () => {
        return (
            <>
                {pathway.courses.map(
                    (c, i) =>
                        <tr key={i}>
                            <td>
                                {c.title}
                            </td>
                            <td>
                                {c.description}
                            </td>
                            <td>
                                <Link to={`/course/${c.id}`}>
                                    <span className="view fas fa-eye float-right mr-3" title="View"></span>
                                </Link>
                                <span data-target="#removeCourse" data-toggle="modal" title="Delete" onClick={() => { setSelectedCourse(c) }} className="fa fa-trash float-right mr-3"></span>
                            </td>
                        </tr>
                )}
            </>
        )
    }

    const deletePathway = async () => {
        let data = {
            learningPathwayId: id
        }

        let response = await deletePathwayAsync(data);
        if (response) {
            showToast(true, "Pathway deleted");
            history.goBack();
        }
        else {
            showToast(false, "Something went wrong");
        }
    }

    const removeFromPathway = async () => {
        let data = {
            learningPathwayId: id,
            courseId: selectedCourse.id
        }

        let response = await removeCourseFromPathwayAsync(data);

        if (response) {
            showToast(true, "Course removed");
            setForceChange(!forceChange);
        }
        else {
            showToast(false, "Something went wrong");
        }
    }

    return (
        <>
            <Modal
                modalId="delete"
                bodyText={`Are you sure you want to delete ${pathway.name}?`}
                buttonFunc={deletePathway}
                buttonText="Delete"
                title={`Delete Leaning pathway`}
            />
            <Modal
                modalId="removeCourse"
                bodyText={`Are you sure you want to remove ${selectedCourse.title}?`}
                buttonFunc={removeFromPathway}
                buttonText="Remove"
                title={`Remove course`}
            />
            <Header heading={pathway.name} subheading={pathway.description} icon="package" />
            <div className="container-fluid content">
                <Card title="Details">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label>Name</label>
                                <input onChange={handleChange} className="form-control" name="name" type="text" disabled={!editMode} value={pathway.name} />
                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <input onChange={handleChange} className="form-control" name="description" type="text" disabled={!editMode} value={pathway.description} />
                            </div>
                            {!editMode ?
                                <PrimaryButton func={() => { setEditMode(true) }} text="Edit" classes="float-right mt-2" />
                                :
                                <>
                                    <PrimaryButton func={updatePathway} text="Save" classes="float-right mt-2" />
                                    <PrimaryButton func={() => { setEditMode(false) }} text="Cancel" classes="mr-3 float-right mt-2" />
                                    <button data-toggle="modal" data-target={`#delete`} className="btn btn-danger mt-2">Delete</button>
                                </>
                            }
                        </div>
                    </div>
                </Card>

                <Card title="Courses">
                    <div className="col-12 p-0 mb-3">
                        <label>Select Courses</label>
                        <div className="row no-gutters">
                            <div className="form-group col-6 col-sm-8 col-md-10">
                                <Multiselect
                                    options={courses}
                                    selectedValues={courseIds}
                                    onSelect={handleCourseSelect}
                                    onRemove={handleCourseSelect}
                                    displayValue="title"
                                />
                            </div>
                            <div className="form-group col-6 col-sm-4 col-md-2">
                                <PrimaryButton func={addCoursesToPathway} text="Add to Pathway" classes="float-right" />
                            </div>
                        </div>
                    </div>

                    <Table headings={headings} rowData={rowData} />
                </Card>

            </div>
        </>
    );
};

export default LearningPathway;