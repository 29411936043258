import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { publishBlogPostAsync, getBlogPostAsync, markBlogPostAsDraftAsync, addTagToBlogPostAsync, deleteTagFromBlogPostAsync, updateBlogPostAsync } from '../../Services/BlogService';
import Card from '../../Shared/Components/Card/Card';
import Header from '../../Shared/Components/Header/Header';
import Button from './../../Shared/Components/Button/Button';
import './Blog/Blog.scss';
import LineIcon from 'react-lineicons';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { showToast } from '../../Infrastructure/Notifications/ToastrConfig';

const BlogPost = () => {
    const [refreshTrigger, setRefreshTrigger] = useState(false);
    const [state, setState] = useState({ tags: [], attachments: [] });
    const { urlSlug } = useParams();
    const [changesMade, setChangesMade] = useState(false);

    useEffect(() => {
        const getPost = async () => {
            let response = await getBlogPostAsync(urlSlug);
            setState(response);
        }

        getPost();
    }, [refreshTrigger]);

    const triggerRefresh = () => {
        setRefreshTrigger(!refreshTrigger);
        setChangesMade(false);
    }

    const handleChange = evt => {
        setChangesMade(true);
        if (evt.target.name === "image") {
            setState({ ...state, imageUrl: URL.createObjectURL(evt.target.files[0]), image: evt.target.files[0] });
        }
        else {
            setState({ ...state, [evt.target.name]: evt.target.value });
        }
    }

    const updateBody = evt => {
        setChangesMade(true);
        setState({ ...state, body: evt.target.value });
    }

    const updateBlogPost = async () => {
        let form = new FormData();
        form.append("id", state.id);
        form.append("title", state.title);
        form.append("body", state.body);
        form.append("image", state.image);

        await updateBlogPostAsync(form);
        triggerRefresh();
        showToast(true, "Blog post updated");
    }

    const addAttachment = async () => {

    }

    const deleteAttachment = async () => {

    }

    const addTag = async () => {
        if (state.tag != "") {
            let data = {
                blogPostId: state.id,
                tag: state.tag
            }

            let response = await addTagToBlogPostAsync(data);
            showToast(true, `Added tag: ${state.tag}`);
            let newTags = state.tags;
            newTags.push(response);
            setState({ ...state, tag: "", tags: newTags });
        }
    }

    const deleteTag = async (tagId, tagName) => {
        let data = {
            blogPostId: state.id,
            tagId: tagId
        }
        await deleteTagFromBlogPostAsync(data);
        showToast(true, `Deleted tag: ${tagName}`);
        let newTags = state.tags.filter(t => t.id !== tagId);
        console.log(newTags);
        setState({ ...state, tags: newTags });
    }

    const publish = async () => {
        let data = {
            id: state.id
        }
        await publishBlogPostAsync(data);
        showToast(true, "Successfully published");
        triggerRefresh();
    }

    const saveAndPublish = async () => {
        updateBlogPost();
        publish();
    }

    const markAsDraft = async () => {
        let data = {
            id: state.id
        }

        await markBlogPostAsDraftAsync(data);
        showToast(true, "Successfully unpublished");
        triggerRefresh();
    }

    return (
        <>
            <Header heading="Create new blog post" subheading="Create new blog post" icon="folder" />
            <div className="container-fluid content blog">
                <Card>
                    {changesMade &&
                        <button onClick={() => { triggerRefresh() }} className='btn btn-danger float-left'>Cancel</button>
                    }
                    <div className="float-right">
                        {
                            !state.published ?
                                <>
                                    {changesMade ?
                                        <Button
                                            text="Save &amp; Publish"
                                            classes="btn btn-success float-right"
                                            func={saveAndPublish}
                                        />
                                        :
                                        <Button
                                            text="Publish"
                                            classes="btn btn-success float-right"
                                            func={publish}
                                        />
                                    }

                                    <Button
                                        text="Save Draft"
                                        classes="btn btn-success mr-2"
                                        func={updateBlogPost}
                                    />
                                </>
                                :
                                <>
                                    <Button
                                        text="Unpublish"
                                        classes="btn btn-danger float-right"
                                        func={markAsDraft}
                                    />
                                    <Button
                                        text="Update"
                                        classes="btn btn-success mr-2"
                                        func={updateBlogPost}
                                    />
                                </>
                        }
                    </div>
                </Card>
                <Card title="Details">
                    <div className="form">
                        <div className="form-row">
                            <div className="form-group col-12 mb-4">
                                <label>Title</label>
                                <input value={state.title} type="text" className="form-control" name="title" id="title" onChange={handleChange} />
                            </div>
                            <div className="form-group col-12 mb-4">
                                <label>Body</label>
                                <DefaultEditor value={state.body} onChange={updateBody} />
                            </div>
                            <div className="form-group col-12 col-sm-6 col-md-4 col-lg-4">
                                <label>Image</label>
                                <div className="upload-container">
                                    <div className="preview">
                                        {state.imageUrl ?
                                            <img className="img img-fluid" src={state.imageUrl} />
                                            :
                                            <LineIcon name="image" style={{ color: "#ccc" }} />
                                        }
                                    </div>
                                    <div className="custom-file">
                                        <input className="custom-file-input" name="image" id="image" type="file" accept=".png,.jpg,.jpeg" onChange={handleChange} />
                                        <label className="custom-file-label">{state.image === null ? 'Browse' : state.imageUrl}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>

                <Card title="Tags">
                    <div className="form">
                        <div className="form-row">
                            <div className="form-group col-12">
                                <label>Add Tags</label>
                                <div className="input-group mb-3">
                                    <input value={state.tag} type="text" placeholder="Enter tag name..." className="form-control" name="tag" id="tag" onChange={handleChange} />

                                    <div className="input-group-append">
                                        <Button
                                            text="Add tag"
                                            classes="btn btn-success btn-append"
                                            func={addTag}
                                        />
                                    </div>
                                </div>
                                {state.tags.map((t, i) =>
                                    <span key={i} className="badge badge-pill badge-info mr-2">{t.value}
                                        <LineIcon name="cross-circle" onClick={() => deleteTag(t.id, t.value)} style={{ cursor: "pointer", marginLeft: "10px", color: "#000" }} />
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default BlogPost;