import React from 'react';
import ClientsNav from './Navs/ClientsNav';
import ELearningNav from './Navs/ELearningNav';
import SettingsNav from './Navs/SettingsNav';
import WebsiteNav from './Navs/WebsiteNav';
import LearnerNav from './Navs/LearnerNav';
import AdminUserNav from './Navs/AdminUserNav';
import ReportingNav from './Navs/ReportingNav';
import CommunitiesNav from './Navs/CommunitiesNav';

const AdminMenu = props => {
    return (
        <>
            <WebsiteNav context={props.context} manipulateColours={props.manipulateColours} />
            <SettingsNav context={props.context} manipulateColours={props.manipulateColours} />
            <AdminUserNav context={props.context} manipulateColours={props.manipulateColours} />
            <ELearningNav context={props.context} manipulateColours={props.manipulateColours} />
            <LearnerNav context={props.context} manipulateColours={props.manipulateColours} />
            <ClientsNav context={props.context} manipulateColours={props.manipulateColours} />
            <ReportingNav context={props.context} manipulateColours={props.manipulateColours} />
            <CommunitiesNav context={props.context} manipulateColours={props.manipulateColours} />
        </>
    );
};

export default AdminMenu;