import React, { useEffect, useState } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import Card from '../../../Shared/Components/Card/Card';
import { createPathwayAsync, populateCourseDropdownAsync } from '../../../Services/CourseService';
import Header from './../../../Shared/Components/Header/Header';
import PrimaryButton from './../../../Shared/Components/PrimaryButton/PrimaryButton';
import { showToast } from '../../../Infrastructure/Notifications/ToastrConfig';

const CreateLearningPathway = () => {
    const [courses, setCourses] = useState([]);
    const [courseIds, setCourseIds] = useState([]);
    const [state, setState] = useState({
        name: '',
        description: ''
    });

    useEffect(() => {
        fetchCourses();
    }, []);

    const fetchCourses = async () => {
        let result = await populateCourseDropdownAsync();
        setCourses(result.courses);
    }

    const handleCourseSelect = selectedCourses => {
        setCourseIds(selectedCourses);
    }

    const handleChange = async evt => {
        setState({ ...state, [evt.target.name]: evt.target.value });
    }

    const handleSubmit = async () => {
        let ids = courseIds.map(c => c.id);
        let data = {
            name: state.name,
            description: state.description,
            courseIds: ids,
        }

        let response = await createPathwayAsync(data);
        if (response.success) {
            showToast(true, "Learning pathway created");
            setState({
                name: '',
                description: ''
            });
        }
        else {
            showToast(false, response.errors[0].error)
        }
    }

    return (
        <React.Fragment>
            <Header heading="Create Learning Pathway" subheading="Vitae elementum curabitur vitae nunc sed" icon="package" />
            <div className="container-fluid content">
                <div className="row">
                    <div className="col-12">
                        <Card title="Create new">
                            <div className="form">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input className="form-control" name="name" id="name" onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea style={{ "minHeight": "150px" }} className="form-control" name="description" id="description" onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label>Courses</label>
                                    <Multiselect
                                        options={courses}
                                        selectedValues={courseIds}
                                        onSelect={handleCourseSelect}
                                        onRemove={handleCourseSelect}
                                        displayValue="title"
                                    />
                                </div>
                                <PrimaryButton text="Create" func={handleSubmit} classes="float-right mt-2" />
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};

export default CreateLearningPathway;