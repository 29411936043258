import React, { useEffect, useState } from 'react';
import { getAccountRegisterDetailsAsync } from '../../Services/AccountService';
import { addItemAsync, deleteItemAsync } from '../../Services/RegistrationService';
import Card from '../../Shared/Components/Card/Card';
import Header from '../../Shared/Components/Header/Header';
import './RegistrationDetails.scss';
import Button from './../../Shared/Components/Button/Button';

const RegistrationDetails = () => {
    const [details, setDetails] = useState({ employmentStatus: [], jobRoles: [], ethnicities: [], genders: [] })
    const [state, setState] = useState({ gender: "", ethnicity: "", jobRole: "", employmentStatus: "" })
    const [forceLoad, setForceLoad] = useState(false)
    useEffect(() => {
        getDetails();
    }, [forceLoad])

    const getDetails = async () => {
        let response = await getAccountRegisterDetailsAsync();
        setState({ gender: "", ethnicity: "", jobRole: "", employmentStatus: "" })
        setDetails(response);
    }

    const deleteItem = async (id, type) => {
        await deleteItemAsync(id, type);
        setForceLoad(!forceLoad);
    }

    const addItem = async (type) => {
        let value = state[type];
        if (value !== "") {
            await addItemAsync(value, type);
            setForceLoad(!forceLoad);
        }
    }

    const handleChange = async evt => {
        setState({ ...state, [evt.target.name]: evt.target.value });
    }

    return (
        <>
            <Header heading="Registration details" subheading="Manage registration details" icon="graduation" />
            <div className="container-fluid content regDetails">
                <Card title="Genders">
                    {details.genders.map((g, i) => <>
                        <p key={i}>{g.name}
                            <span onClick={() => { deleteItem(g.id, "gender") }} className="fa fa-close clickable float-right" style={{ "color": "red" }}></span>
                        </p>
                    </>)}
                    <div className="input-group mb-3">
                        <input value={state.gender} type="text" className="form-control" placeholder="New gender..." name="gender" onChange={handleChange} />
                        <div className="input-group-append">
                            <Button text="Add" classes="btn-primary btn-append" func={() => { addItem("gender") }} />
                        </div>
                    </div>
                </Card>
                <Card title="Job roles">
                    {details.jobRoles.map((g, i) => <><p key={i}>{g.name} <span onClick={() => { deleteItem(g.id, "jobRole") }} className="fa fa-close clickable float-right" style={{ "color": "red" }}></span></p></>)}
                    <div className="input-group mb-3">
                        <input value={state.jobRole} type="text" className="form-control" placeholder="New job role..." name="jobRole" onChange={handleChange} />
                        <div className="input-group-append">
                            <Button text="Add" classes="btn-primary btn-append" func={() => { addItem("jobRole") }} />
                        </div>
                    </div>
                </Card>
                <Card title="Employment status'">
                    {details.employmentStatus.map((g, i) => <><p key={i}>{g.name} <span onClick={() => { deleteItem(g.id, "employmentStatus") }} className="fa fa-close clickable float-right" style={{ "color": "red" }}></span></p></>)}
                    <div className="input-group mb-3">
                        <input value={state.employmentStatus} type="text" className="form-control" placeholder="New employment status..." name="employmentStatus" onChange={handleChange} />
                        <div className="input-group-append">
                            <Button text="Add" classes="btn-primary btn-append" func={() => { addItem("employmentStatus") }} />
                        </div>
                    </div>
                </Card>
                <Card title="Ethnicities">
                    {details.ethnicities.map((g, i) => <><p key={i}>{g.name} <span onClick={() => { deleteItem(g.id, "ethnicity") }} className="fa fa-close clickable float-right" style={{ "color": "red" }}></span></p></>)}
                    <div className="input-group mb-3">
                        <input value={state.ethnicity} type="text" className="form-control" placeholder="New ethnicity..." name="ethnicity" onChange={handleChange} />
                        <div className="input-group-append">
                            <Button text="Add" classes="btn-primary btn-append" func={() => { addItem("ethnicity") }} />
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default RegistrationDetails;