import React, { useState } from 'react';
import { uploadCourseAsync } from '../../../../Services/CourseService';
import Success from './Components/Success';
import Upload from './Components/Upload';
import Uploading from './Components/Uploading';
import Error from './Components/Error';
import Header from './../../../../Shared/Components/Header/Header';
import Card from './../../../../Shared/Components/Card/Card';

const UploadCourse = () => {
    const UPLOAD = "upload";
    const UPLOADING = "uploading";
    const ERROR = "error";
    const SUCCESS = "success";

    const [componentState, setComponentState] = useState(UPLOAD);
    const [progress] = useState(0);
    const [state, setState] = useState({
        title: null,
        reference: null,
        description: null,
        file: null,
        badge: null,
    });
    const [errors, setErrors] = useState([]);

    const handleChange = async evt => {
        setState({ ...state, [evt.target.name]: evt.target.value });
    }

    const handleFileChange = evt => {
        let value = evt.target.files[0];
        let name = evt.target.name;
        setState({ ...state, [name]: value });
    }

    const handleValidation = () => {
        let fields = state;
        let errors = {};
        let formIsValid = true;

        //title
        if (!fields["title"]) {
            formIsValid = false;
            errors["title"] = "Please enter a title";
        }

        //description
        if (!fields["description"]) {
            formIsValid = false;
            errors["description"] = "Please enter a description";
        }

        //reference
        if (!fields["reference"]) {
            formIsValid = false;
            errors["reference"] = "Please add a reference";
        }

        //scormFile
        if (!fields["file"]) {
            formIsValid = false;
            errors["file"] = "Please upload a course";
        }

        //badgeImage
        if (!fields["badge"]) {
            formIsValid = false;
            errors["badge"] = "Please upload a badge image";
        }

        setErrors(errors);
        return formIsValid;
    }

    const handleSubmit = async () => {
        if (handleValidation()) {
            setComponentState(UPLOADING);
            let form = new FormData();
            form.append("title", state.title);
            form.append("reference", state.reference);
            form.append("description", state.description);
            form.append("scormFile", state.file);
            form.append("badgeImage", state.badge);

            let response = await uploadCourseAsync(form);
            if (response.success) {
                setComponentState(SUCCESS);
            }
            else {
                setComponentState(ERROR);
            }
        }
    }

    const createAnother = () => {
        setState({
            title: null,
            reference: null,
            description: null,
            errors: [],
            file: null,
            sortOrder: 0,
        });

        setComponentState(UPLOAD);
    }

    const renderComponentState = () => {
        switch (componentState) {
            case UPLOAD:
                return <Upload
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleFileChange={handleFileChange}
                    file={state.file}
                    badge={state.badge}
                    errors={errors}
                />;
            case SUCCESS:
                return <Success
                    func={createAnother}
                />;
            case ERROR:
                return <Error />
            case UPLOADING:
                return <Uploading progress={progress} />
            default:
                return <Upload
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleFileChange={handleFileChange}
                    file={state.file}
                    badge={state.badge}
                />
        }
    }

    return (
        <>
            <Header heading="Create Course" subheading="Upload a course " icon="pencil-alt" />
            <div className="container-fluid content">
                <Card title="Create new">
                    {renderComponentState()}
                </Card>
            </div>
        </>
    );
};

export default UploadCourse;