import axios from 'axios';
import { baseUrl } from '../Infrastructure/Config/ApiConfig';
import { token } from './AuthService';

const controllerName = 'Config';

export const getThemeAsync = async () => {
    const response = await axios.get(`${baseUrl()}${controllerName}/GetConfig`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });

    return response.data;
}

export const updateThemeAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/UpdateConfig`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}