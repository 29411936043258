import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../Infrastructure/Context/ThemeContext';
import './Navbar.scss';
import { Fade as Hamburger } from 'hamburger-react';
import { blackOrWhite } from '../../Infrastructure/ManipulateColors';

const Navbar = () => {
    const context = useContext(ThemeContext);
    const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth > 992);

    useEffect(() => {
        let open = sidebarOpen;
        document.getElementById('sidebar')
            .classList.remove(!open ? 'sidebar-open' : 'sidebar-hidden');
        document.getElementById('sidebar')
            .classList.add(!open ? 'sidebar-hidden' : 'sidebar-open');

        !open ? document.getElementById('main-container').classList.remove('menu-toggle')
            : document.getElementById('main-container').classList.add('menu-toggle')

        !open ? document.getElementById('admin-navbar').classList.remove('menu-toggle')
            : document.getElementById('admin-navbar').classList.add('menu-toggle')
    }, [sidebarOpen])

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    }

    return (
        <div id="admin-navbar" className="admin-navbar menu-toggle">
            <div className="row no-gutters pl-2 d-flex justify-content-between" style={{ "background": context.secondaryNavColour, "height": "54px" }}>

                <div className="d-flex align-items-center">
                    <Hamburger rounded toggled={sidebarOpen} toggle={() => toggleSidebar()} color={context.secondaryNavColour ? blackOrWhite(context.secondaryNavColour) : "black"} />
                </div>
                {/* <div className="d-flex align-items-center">
                    <div className="profile-initials">
                        <p className="initials">ag</p>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Navbar;