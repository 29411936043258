import React, { useContext } from 'react';
import { ThemeContext } from '../../../Infrastructure/Context/ThemeContext';

import './StatCard.scss';

const StatCard = ({ title, stat, subtext, colour, fontSize }) => {
    const context = useContext(ThemeContext);
    return (
        <div className="col-12 col-sm-6 col-lg-4">
            <div className="stat-card mb-3">
                <div className="text">
                    <h4>{title}</h4>
                    <p>{subtext}</p>
                </div>
                <div className="stat" style={{ color: colour ? colour : context.iconColour }}>
                    <h3 style={{ fontSize: fontSize }}>{stat}</h3>
                </div>
            </div>
        </div>
    );
};

export default StatCard;