import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import LineIcon from 'react-lineicons';
import { blackOrWhite, menuHover } from '../../../../Infrastructure/ManipulateColors';
import { getCommunitiesAsync } from '../../../../Services/CommunityServie';

const CommunitiesNav = props => {
    const [communites, setCommunities] = useState([]);

    useEffect(() => {
        const getCommunities = async () => {
            let storedUser = JSON.parse(window.localStorage.getItem("user"));
            let response = await getCommunitiesAsync(storedUser.clientId);
            setCommunities(response.communites);
        }
        getCommunities();
    }, []);
    return (
        <>
            <li data-toggle="collapse" href="#communitiesDropdown" role="button" style={{ "--hover-color": menuHover(props.context.primaryNavColour) }}>
                <div className="chev">
                    <LineIcon name="chevron-right" style={{ color: props.context.sidebarIconColour }} />
                </div>
                <NavLink to="#" activeClassName='sidebar-active'>
                    <LineIcon name="network" style={{ color: props.context.sidebarIconColour }} />
                    <span className="item" style={{ color: props.context.primaryNavColour ? blackOrWhite(props.context.primaryNavColour) : "black" }}>Communities</span>
                </NavLink>
            </li>

            <div className="collapse" id="communitiesDropdown">
                <div className="sidebar-submenu">
                    <ol style={{ "backgroundColor": props.manipulateColours(props.context.primaryNavColour) }}>
                        <li style={{ "--sub-menu-hover-color": menuHover(props.manipulateColours(props.context.primaryNavColour)), "--ol-color": blackOrWhite(props.context.primaryNavColour) }}><NavLink activeClassName='sidebar-active' to="/communities"><span>Manage</span></NavLink></li>
                        {communites.length > 0 &&
                            communites.map((x, i) =>
                                <li key={i} style={{ "--sub-menu-hover-color": menuHover(props.manipulateColours(props.context.primaryNavColour)), "--ol-color": blackOrWhite(props.context.primaryNavColour) }}><NavLink activeClassName='sidebar-active' to={`/community/${x.id}`}><span>{x.name}</span></NavLink></li>
                            )}
                    </ol>
                </div>
            </div>
        </>
    );
};

export default CommunitiesNav;