import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getLearnerByUrlTokenAsync } from '../../Services/ClientService';
import Card from '../../Shared/Components/Card/Card';
import PrimaryButton from '../../Shared/Components/PrimaryButton/PrimaryButton';
import Table from '../../Shared/Components/Table';
import Header from './../../Shared/Components/Header/Header';
import { deleteUserAsync, updateUserAsync } from '../../Services/UserService';
import { showToast } from '../../Infrastructure/Notifications/ToastrConfig';
import { resendActivationEmailAsync } from '../../Services/AccountService';
import Modal from '../../Shared/Components/Modal/Modal';
import { validEmailRegex } from './../../Infrastructure/Config/Regexes';

const Learner = () => {

    const [learner, setLearner] = useState({ firstName: '', lastName: '', email: '' });
    const [courses, setCourses] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const history = useHistory();
    const { urlToken } = useParams();
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        const getLearner = async () => {
            let response = await getLearnerByUrlTokenAsync(urlToken);
            setLearner(response.user);
            setCourses(response.courses);
        }
        getLearner();
    }, [urlToken, editMode])

    const headings = () => {
        return (
            <>
                <th>Course title</th>
                <th>Progress</th>
                <th>Assessment score</th>
                <th>Result</th>
                <th>No. of Restarts</th>
            </>
        )
    }

    const rowData = () => {
        return (
            <>
                {courses.length > 0 ?
                    courses.map(
                        (c, i) =>
                            <tr key={i}>
                                <td>
                                    {c.course.title}
                                </td>
                                <td>
                                    {Math.round(c.progress)}%
                                </td>
                                <td>
                                    {c.scorePercentage}%
                                </td>
                                <td>
                                    {getStatusBadge(c.lessonStatus)}
                                </td>
                                <td>
                                    {c.numerOfRestarts}
                                </td>
                            </tr>
                    )
                    :
                    <tr>
                        <td className="no-data" colSpan="4">No courses to display</td>
                    </tr>
                }
            </>
        )
    }

    const handleValidation = () => {
        let fields = learner;
        let errors = {};
        let formIsValid = true;

        //firstName
        if (!fields["firstName"]) {
            formIsValid = false;
            errors["firstName"] = "Please enter a first name";
        }

        //lastName
        if (!fields["lastName"]) {
            formIsValid = false;
            errors["lastName"] = "Please enter a last name";
        }

        //email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Please enter an email address";
        } else {
            if (!validEmailRegex.test(fields["email"])) {
                formIsValid = false;
                errors["email"] = "Please enter a valid email address";
            }
        }

        setErrors(errors);
        return formIsValid;
    }

    const updateUser = async () => {
        if (handleValidation()) {
            let respose = await updateUserAsync(learner);
            if (respose.success) {
                showToast(true, "User updated");
                setEditMode(false);
            }
            else {
                showToast(false, respose.errors[0].error);
            }
        }
    }

    const deleteUser = async () => {
        let data = {
            id: learner.id
        }
        let respose = await deleteUserAsync(data);
        if (respose.success) {
            showToast(true, "User deleted");
            history.goBack();
        }
        else {
            showToast(false, respose.errors[0].error);
        }
    }

    const handleChange = evt => {
        setLearner({ ...learner, [evt.target.name]: evt.target.value });
    }

    const resendConfirmationEmail = async () => {
        let data = {
            userId: learner.id
        }
        let response = await resendActivationEmailAsync(data);
        if (response.success) {
            showToast(true, "Email sent")
        }
        else {
            showToast(false, response.errors[0].error)
        }
    }

    const getStatusBadge = (status) => {
        if (status === 4) {
            return <span className="badge badge-success">Passed</span>
        }
        else if (status === 3) {
            return <span className="badge badge-danger">Failed</span>
        }
        else {
            return <span className="badge badge-warning">Assessment Not taken</span>
        }
    }

    return (
        <>
            <Modal buttonFunc={deleteUser}
                modalId={"delete"}
                bodyText="Warning! Deleting users is ireversable! Are you sure you want to delete this user?"
                title={`Delete ${learner.firstName} ${learner.lastName}`}
                buttonText="Delete"
            />
            <Header heading="Learner" subheading="Learner Information" icon="layout" />
            <div className="container-fluid content">
                <Card classes="mb-5" title={learner.firstName + " " + learner.lastName}>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label>First Name</label>
                            <input type="text" onChange={handleChange} value={learner.firstName} className="form-control" name="firstName" disabled={!editMode} />
                            <small className="errorMessage">{errors.firstName}</small>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Last Name</label>
                            <input type="text" onChange={handleChange} value={learner.lastName} className="form-control" name="lastName" disabled={!editMode} />
                            <small className="errorMessage">{errors.lastName}</small>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label>Email</label>
                            <input onChange={handleChange} type="text" value={learner.email} className="form-control" name="email" disabled={!editMode} />
                            <small className="errorMessage">{errors.email}</small>
                        </div>
                    </div>
                    {!editMode ?
                        <PrimaryButton func={() => { setEditMode(true) }} text="Edit" classes="float-right mt-2" />
                        :
                        <>
                            <PrimaryButton func={updateUser} text="Save" classes="float-right mt-2" />
                            <PrimaryButton func={() => { setEditMode(false); setErrors([]); }} text="Cancel" classes="mr-3 float-right mt-2" />
                        </>
                    }
                    {!editMode ?
                        <>
                            {!learner.emailConfirmed &&
                                <PrimaryButton classes="mt-2" text="Resend confirmation" func={resendConfirmationEmail} />
                            }
                        </>
                        :
                        <button data-toggle="modal" data-target={`#delete`} className="btn btn-danger mt-2">Delete</button>
                    }
                </Card>

                <Card title="Courses">
                    <div className="row">
                        <div className="col-12">
                            <Table headings={headings} rowData={rowData} />
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default Learner;