import React, { useEffect, useState } from 'react';
import { getCourseFeedbackAsync } from '../../../Services/FeedbackFormService';
import Card from '../../../Shared/Components/Card/Card';
import Header from '../../../Shared/Components/Header/Header';
import { useParams } from 'react-router-dom';
import StatsQuestion from './components/StatsQuestion';
import ReactWordcloud from 'react-wordcloud';

const FeedbackStats = () => {
    const [feedbackForm, setFeedbackForm] = useState({
        questions: [{ answers: [] }], freeTextAnswers: [{ question: "", data: [] }],
        course: {}, wordCloud: { data: [{ count: "", word: "" }] }
    });
    const { id, clientId } = useParams();

    useEffect(() => {
        const getForm = async () => {
            if (clientId !== null) {
                let response = await getCourseFeedbackAsync(id, clientId);
                setFeedbackForm(response);
            }
            else {
                let response = await getCourseFeedbackAsync(id);
                setFeedbackForm(response);
            }
        };

        getForm();

    }, [])

    const options = {
        rotations: 2,
        rotationAngles: [0, 0],
        fontSizes: [12, 28],
        deterministic: true
    };
    const size = [600, 400];

    return (
        <>
            <Header heading={feedbackForm.title} subheading={feedbackForm.course && feedbackForm.course.title} icon="book" />
            {feedbackForm.course !== null ?
                <div className="container-fluid content">
                    {
                        feedbackForm.questions !== null &&
                        feedbackForm.questions.map((q, i) =>
                            <StatsQuestion key={i} question={q} />
                        )}

                    {
                        feedbackForm.freeTextAnswers.map((x, i) =>
                            <Card title={x.question} key={i}>
                                <div className='row'>
                                    <div className='col-12'>
                                        {

                                            <ReactWordcloud
                                                words={x.data}
                                                options={options}
                                                minSize={[600, 600]}
                                            />
                                        }
                                    </div>
                                </div>
                            </Card>
                        )
                    }
                    <Card title="Additional feedback">
                        <div className='row'>
                            <div className='col-12'>
                                {
                                    feedbackForm.wordCloud != null &&
                                    <ReactWordcloud
                                        words={feedbackForm.wordCloud.data}
                                        options={options}
                                        minSize={[600, 600]}
                                    />
                                }
                            </div>
                        </div>
                    </Card>
                </div>
                :
                <div className="container-fluid content">
                    <Card title={`${feedbackForm.title}`}>
                        <h1>Not assigned</h1>
                    </Card>
                </div>
            }
        </>
    );
};

export default FeedbackStats;