import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getMyPathwayAsync } from '../../../Services/DashboardService';
import Card from '../../../Shared/Components/Card/Card';
import Header from '../../../Shared/Components/Header/Header';
import Table from '../../../Shared/Components/Table';
import { getStatus } from '../../../Infrastructure/Utilities/LearnerProgressHelper';
import { getStatusBadge } from './../../../Infrastructure/Utilities/LearnerProgressHelper';
import { Line } from 'rc-progress';
import { Link } from 'react-router-dom';
import CompletionBadge from '../Courses/CompletionBadge';

const LearnerLearningPathway = () => {
    const { id } = useParams();
    const [pathway, setPathway] = useState({ courses: [] })

    useEffect(() => {
        const getPathway = async () => {
            let response = await getMyPathwayAsync(id);
            setPathway(response.learningPathway);
        }
        getPathway();
    }, [id])

    const headings = () => {
        return (<>
            <th>Course name</th>
            <th>Status</th>
            <th>Result</th>
            <th>Progress</th>
            <th>score</th>
            <th></th>
        </>)
    }

    const rowData = () => {
        return (
            <>
                {pathway.courses.map(
                    (x, i) =>
                        <tr key={i}>
                            <td>{x.course.title}</td>
                            <td>
                                {getStatus(x.completedDateTime, x.startedDateTime)}
                            </td>

                            <td>{getStatusBadge(x.lessonStatus)}</td>
                            {/* <td>{Math.round(x.progress)}%</td> */}
                            <td><Line percent={x.progress} strokeWidth="3" strokeColor="#009fdf" strokeLinecap="round" /></td>
                            <td>{Math.round(x.scorePercentage)}%</td>
                            <td>
                                <Link to={`/engage/${x.course.id}`}>
                                    <span className="view fas fa-eye float-right mr-3" title="View"></span>
                                </Link>

                                {/* {x.startedDateTime != null && x.lessonStatus != 4 && <span onClick={() => { setSelectedCourseId(x.id) }} data-target="#restart" data-toggle="modal"><PrimaryButton text="Restart" classes="ml-2 float-right action-btn" /></span>} */}

                            </td>
                        </tr>
                )
                }
            </>
        )
    }

    return (
        <>
            <Header heading={pathway.name} subheading={pathway.description} icon="bar-chart" />
            <div className="container-fluid content">
                <div className="row">
                    <div className="col-12">
                        <Card title="Courses" >
                            <Table headings={headings} rowData={rowData} />
                        </Card>
                    </div>
                    <div className="col-12">
                        <Card title="Badges" >
                            {pathway.courses.map(
                                (c, i) => <CompletionBadge key={i} completed={c.lessonStatus === 4} image={c.course.badgeImageUrl} courseId={c.course.id} />
                            )}
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LearnerLearningPathway;