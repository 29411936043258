import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getClientLearningPathwaysAsync } from '../../../Services/CourseService';
import Card from '../../../Shared/Components/Card/Card';
import Table from '../../../Shared/Components/Table';
import Header from './../../../Shared/Components/Header/Header';

const ClientLearningPathways = () => {

    const [pathways, setPathways] = useState([]);
    useEffect(() => {
        const fetchPathways = async () => {
            let result = await getClientLearningPathwaysAsync();
            if (result.learningPathways !== null) {
                setPathways(result.learningPathways);
            }
        }
        fetchPathways();
    }, []);

    const headings = () => {
        return (<>
            <th>Name</th>
            <th>Description</th>
            <th>No. of Courses</th>
            <th></th>
        </>)
    }

    const rowData = () => {
        return (<>
            {pathways.length > 0 ?
                pathways.map(
                    (u, i) =>
                        <tr key={i}>
                            <td>
                                {u.name}
                            </td>
                            <td>
                                {u.description}
                            </td>
                            <td>{u.numberOfCourses}</td>
                            <td>
                                <Link to={`/learning-pathway/${u.id}`}>
                                    <span className="view fas fa-eye float-right mr-3" title="View"></span>
                                </Link>
                            </td>
                        </tr>
                )
                :
                <tr>
                    <td className="no-data" colSpan="4">
                        No pathways to currently display
                    </td>
                </tr>
            }
        </>)
    }

    return (
        <>
            <Header heading="Learning Pathways" subheading="List of assigned pathways" icon="layers" />
            <div className="container-fluid content">
                <Card>
                    {/* <div className="col-12 p-0">
                        <div className="form-group float-left">
                            <input name="searchTerm" className="form-control" placeholder="Search" />
                        </div>
                        <div className="form-group float-right ml-3">
                            <select className="form-control" value="10" name="pageSize">
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={250}>250</option>
                                <option value={500}>500</option>
                            </select>
                        </div>
                    </div> */}
                    <Table headings={headings} rowData={rowData} />
                    <div className="mt-3 text-center">
                        {/* <div className="small mb-3">
                                        <span>Showing {learners.length} of {state.totalLearners}</span>
                                    </div>
                                    <PrimaryButton text="Load more"
                                        classes="clickable "
                                        func={loadMore}
                                        disabled={learners.length >= state.totalLearners} /> */}
                    </div>
                </Card>
            </div>
        </>
    );
};

export default ClientLearningPathways;