import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Shared/Components/Header/Header';
import Table from '../../Shared/Components/Table';
import Card from '../../Shared/Components/Card/Card';
import Button from '../../Shared/Components/Button/Button';
import { deleteCommunityAsync, getCommunitiesAsync } from '../../Services/CommunityServie';
import Modal from '../../Shared/Components/Modal/Modal';

const ManageCommunities = () => {
    const [communites, setCommunities] = useState([]);
    const [communityId, setCommunityId] = useState("");
    const [reload, setReload] = useState(false);

    useEffect(() => {
        const getCommunities = async () => {
            let storedUser = JSON.parse(window.localStorage.getItem("user"));
            let response = await getCommunitiesAsync(storedUser.clientId);
            setCommunities(response.communites);
        }
        getCommunities();
    }, [reload]);

    const deleteCommunity = async () => {
        await deleteCommunityAsync(communityId);
        setReload(!reload);
    }
    const headings = () => {
        return (<>
            <th>Name</th>
            <th>Description</th>
            <th></th>
        </>)
    }

    const rowData = () => {
        return (
            <>
                {communites.length > 0 ?
                    communites.map(
                        (c, i) =>
                            <tr key={i}>
                                <td>
                                    {c.name}
                                </td>
                                <td>
                                    {c.description}
                                </td>
                                <td>
                                    <span data-target="#deleteCommunity" data-toggle="modal" onClick={() => { setCommunityId(c.id) }} className="fa fa-trash"></span>
                                    <Link to={`/community/${c.id}`}>
                                        <span className="view fas fa-eye float-right mr-3" title="View"></span>
                                    </Link>
                                </td>
                            </tr>
                    )
                    :
                    <tr>
                        <td className="no-data" colSpan="4">
                            No communities to display
                        </td>
                    </tr>
                }
            </>)
    }

    return (
        <>
            <Header heading="Communities" subheading="Manage Communities" icon="folder" />
            <div className="container-fluid content">
                <Card>
                    <div className="float-left">
                        <Button text="Create New" classes="btn btn-success" link="/communities/create" />
                    </div>
                </Card>
                <Card>
                    <Table headings={headings} rowData={rowData} />

                </Card>
            </div>
            <Modal
                modalId="deleteCommunity"
                bodyText={`Are you sure you want to delete this community?`}
                buttonFunc={deleteCommunity}
                buttonText="Remove"
                title={`Delete Community`}
            />
        </>
    );
};

export default ManageCommunities;