import React from 'react';
import { NavLink } from 'react-router-dom';
import LineIcon from 'react-lineicons';
import { blackOrWhite, menuHover } from '../../../../Infrastructure/ManipulateColors';

const ELearningNav = props => {
    return (
        <>
            <li data-toggle="collapse" href="#elearningDropdown" role="button" style={{ "--hover-color": menuHover(props.context.primaryNavColour) }}>
                <div className="chev">
                    <LineIcon name="chevron-right" style={{ color: props.context.sidebarIconColour }} />
                </div>
                <NavLink to="#" activeClassName='sidebar-active'>
                    <LineIcon name="graduation" style={{ color: props.context.sidebarIconColour }} />
                    <span className="item" style={{ color: props.context.primaryNavColour ? blackOrWhite(props.context.primaryNavColour) : "black" }}>eLearning</span>
                </NavLink>
            </li>

            <div className="collapse" id="elearningDropdown">
                <div className="sidebar-submenu">
                    <ol style={{ "backgroundColor": props.manipulateColours(props.context.primaryNavColour) }}>
                        <li style={{ "--sub-menu-hover-color": menuHover(props.manipulateColours(props.context.primaryNavColour)), "--ol-color": blackOrWhite(props.context.primaryNavColour) }}><NavLink activeClassName='sidebar-active' to="/courses"><span>Courses</span></NavLink></li>
                        <li style={{ "--sub-menu-hover-color": menuHover(props.manipulateColours(props.context.primaryNavColour)), "--ol-color": blackOrWhite(props.context.primaryNavColour) }}><NavLink activeClassName='sidebar-active' to="/learning-pathways"><span>Learning Pathways</span></NavLink></li>
                        <li style={{ "--sub-menu-hover-color": menuHover(props.manipulateColours(props.context.primaryNavColour)), "--ol-color": blackOrWhite(props.context.primaryNavColour) }}><NavLink activeClassName='sidebar-active' to="/feedback-forms"><span>Feedback forms</span></NavLink></li>
                    </ol>
                </div>
            </div>
        </>
    );
};

export default ELearningNav;