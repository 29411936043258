import React, { useEffect, useState } from 'react';
import { getAllEmailsAsync } from '../../Services/EmailService';
import Header from '../../Shared/Components/Header/Header';
import moment from 'moment';
import Table from '../../Shared/Components/Table';
import PaginationControls from '../../Shared/Components/PaginationControls';
import Card from '../../Shared/Components/Card/Card';

const SentEmails = () => {
    const [emails, setEmails] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [toAddress, setToAddress] = useState("");
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        getEmails()
    }, [pageNumber, toAddress])

    const getEmails = async () => {
        let response = await getAllEmailsAsync(pageNumber, toAddress);
        setEmails(response.emailRecords);
        setTotalPages(response.totalPages)
    }

    const headings = () => {
        return (<>
            <th>To</th>
            <th>Name</th>
            <th>Template</th>
            <th>Sent</th>
        </>)
    }

    const nextPage = () => {
        setPageNumber(pageNumber + 1);
    }

    const previousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }

    const handleChange = evt => {
        setToAddress(evt.target.value);
    }

    const rowData = () => {
        return (
            <>
                {emails.length > 0 ?
                    emails.map(
                        (c, i) =>
                            <tr key={i}>
                                <td>
                                    {c.toAddress}
                                </td>
                                <td>
                                    {c.toName}
                                </td>
                                <td>
                                    {c.templateType}
                                </td>
                                <td>
                                    {moment(c.processedDateTime).format('DD-MM-YYYY')}
                                </td>
                            </tr>
                    )
                    :
                    <tr>
                        <td className="no-data" colSpan="4">
                            No emails to display
                        </td>
                    </tr>
                }
            </>)
    }

    return (
        <>
            <Header heading="Emails" subheading="Sent emails" icon="customer" />
            <div className="container-fluid content">
                <Card>
                    <div className="col-12 p-0">
                        <div className="form-group float-left">
                            <input onChange={handleChange} name="searchTerm" className="form-control" placeholder="Email..." />
                        </div>
                    </div>
                    <Table headings={headings} rowData={rowData} />
                    <PaginationControls next={nextPage} previous={previousPage} current={pageNumber} total={totalPages} />
                </Card>
            </div>
        </>
    );
};

export default SentEmails;