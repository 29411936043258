import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getFeedbackFormAsync, completeFeedbackFormAsync } from '../../../Services/FeedbackFormService';
import Header from '../../../Shared/Components/Header/Header';
import Card from '../../../Shared/Components/Card/Card';
import { showToast } from '../../../Infrastructure/Notifications/ToastrConfig';
import Button from './../../../Shared/Components/Button/Button';

const LearnerFeedback = () => {
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [errors, setErrors] = useState([]);
    const { id } = useParams();
    const [feedbackForm, setFeedbackForm] = useState({ questions: [{ answers: [] }], freeTextQuestions: [{}], course: {} });
    const [additionalFeedback, setAdditionalFeedback] = useState("");
    const history = useHistory();

    useEffect(() => {
        const getForm = async () => {
            let response = await getFeedbackFormAsync(id);
            setFeedbackForm(response);
        };

        getForm();

    }, [])

    const selectAnswer = (qId, aId) => {

        let answer = { questionId: qId, answerId: aId };
        if (containsAnswer(qId, aId)) {
            const index = selectedAnswers.findIndex(x => x.answerId === aId && x.questionId === qId);
            if (index > -1) {
                selectedAnswers.splice(index, 1);
                let newArray = [...selectedAnswers]
                setSelectedAnswers(newArray);
            }
        }
        else if (containsQuestion(qId)) {
            const index = selectedAnswers.findIndex(x => x.questionId === qId);
            if (index > -1) {
                selectedAnswers.splice(index, 1);
                let newArray = [...selectedAnswers, answer]
                setSelectedAnswers(newArray);
            }
        }
        else {
            setSelectedAnswers([...selectedAnswers, answer]);
        }
    }

    const containsAnswer = (qId, aId) => {
        var i;
        for (i = 0; i < selectedAnswers.length; i++) {
            if (selectedAnswers[i].questionId === qId && selectedAnswers[i].answerId == aId) {
                return true;
            }
        }

        return false;
    }

    const containsQuestion = (qId) => {
        var i;
        for (i = 0; i < selectedAnswers.length; i++) {
            if (selectedAnswers[i].questionId === qId) {
                return true;
            }
        }

        return false;
    }

    const validateForm = () => {
        let formErrors = [];
        feedbackForm.questions.forEach(q => {
            if (!containsQuestion(q.id)) {
                formErrors.push(q.id);
            }
        });
        feedbackForm.freeTextQuestions.forEach(q => {
            if (q.answerValue === "" || q.answerValue === null) {
                formErrors.push(q.id);
            }
        });
        setErrors(formErrors);
        return formErrors;
    }

    const handleSubmit = async () => {
        let errors = validateForm();
        if (errors.length === 0) {
            let answers = [];
            let freeTextAnswers = [];
            selectedAnswers.forEach(sa => { answers.push({ id: sa.answerId }) });
            feedbackForm.freeTextQuestions.forEach(q => { freeTextAnswers.push({ FreeTextQuestionId: q.id, value: q.answerValue }) })
            let data = {
                feedbackFormId: id,
                additionalFeedback: additionalFeedback,
                answers: answers,
                FreeTextAnswers: freeTextAnswers
            }

            await completeFeedbackFormAsync(data);
            history.goBack();
            showToast(true, "Thanks for your feedback");
        }
    }

    const handleAdditionalFeedbackChange = evt => {
        setAdditionalFeedback(evt.target.value);
    }

    const handleFreeTextChange = evt => {
        let id = evt.target.id;
        let value = evt.target.value;
        let newQuestions = feedbackForm.freeTextQuestions.map(x => x);

        newQuestions.forEach(x => {
            if (x.id === id) {
                x.answerValue = value;
            }
        });

        setFeedbackForm({ ...feedbackForm, freeTextQuestions: newQuestions });
    }

    return (
        <>
            <Header heading="Feedback" subheading="Module feedback" icon="customer" />
            <div className="container-fluid content">
                <Card classes="text-center p-3">
                    <p style={{ fontWeight: "700", textTransform: "uppercase", fontSize: "0.88rem" }}>Please complete the following feedback form.</p>
                    <span>On completion, your certification will be available to download.</span>
                </Card>
                {
                    feedbackForm.questions.map((q, i) =>
                        <Card title={q.value}>
                            <div className='form-row pb-2' key={i}>

                                {q.answers.map((fa, i) =>
                                    <div key={i} className="col-12 mb-2 form-check form-check-inline ml-1">
                                        <input onClick={() => { selectAnswer(q.id, fa.id) }} className="form-check-input" type="radio" value={fa.id} checked={containsAnswer(q.id, fa.id)} />
                                        <label className="form-check-label">{fa.value}</label>
                                    </div>
                                )}
                                <div className={`col-12 ${errors.includes(q.id) ? "" : "d-none"}`}>
                                    <p style={{ "color": "red" }}>Please choose an answer</p>
                                </div>
                            </div>
                        </Card>
                    )
                }
                {
                    feedbackForm.freeTextQuestions.map((q, i) =>
                        <Card title={q.value}>
                            <div className='form-row pb-2' key={i}>
                                <div className='col-12'>
                                    <textarea className='form-control' id={q.id} onChange={handleFreeTextChange} />
                                </div>
                                <div className={`col-12 ${errors.includes(q.id) ? "" : "d-none"}`}>
                                    <p style={{ "color": "red" }}>Please provide an answer</p>
                                </div>
                            </div>
                        </Card>
                    )
                }
                <Card>
                    <div className='form-row pb-3'>
                        <div className="col-12 pb-0">
                            <div className='form-group'>
                                <label>Additional comments</label>
                                <textarea onChange={handleAdditionalFeedbackChange} className="form-control" type="text-area" style={{ "min-height": "150px" }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <Button text="Submit" func={handleSubmit} classes="btn-primary mt-3 float-right" />
                    </div>
                </Card>
            </div>
        </>
    );
};

export default LearnerFeedback;