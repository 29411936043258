import axios from 'axios';
import { baseUrl } from '../Infrastructure/Config/ApiConfig';
import { getRole, token } from './AuthService';

const controllerName = 'Dashboard';

export const getDashboardAsync = async (role) => {

    const response = await axios.get(`${baseUrl()}${controllerName}/${role === undefined ? getRole() : role}Dashboard`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getMyPathwaysAsync = async () => {
    const response = await axios.get(`${baseUrl()}${controllerName}/GetMyPathways`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getMyPathwayAsync = async (id) => {
    const response = await axios.get(`${baseUrl()}${controllerName}/GetMyPathway/${id}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}