var Color = require('color');

const blackOrWhite = (hex) => {
    let color = Color(hex),
        isLight = color.isLight();

    if (isLight) {
        return "black";
    } else {
        return "white";
    }
}

const lightOrDark = (hex) => {
    let color = Color(hex),
        isLight = color.isLight(),
        lightness = color.lightness();

    if (isLight) {
        return color.lightness(lightness - 8);
    } else {
        return color.lightness(lightness + 5);
    }
}

const manipulateContrast = (hex) => {
    let color = Color(hex),
        isLight = color.isLight();

    if (isLight) {
        return color.darken(0.2);
    } else {
        return color.alpha(0.8);
    }
}

const menuHover = (hex) => {
    let color = Color(hex);

    return color.darken(0.2);

}

export { blackOrWhite, lightOrDark, manipulateContrast, menuHover }; 