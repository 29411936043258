import React, { useContext } from 'react';
import { ThemeContext } from '../../../Infrastructure/Context/ThemeContext';

const Title = ({ title }) => {
    const context = useContext(ThemeContext);

    const styles = {
        fontWeight: "700",
        textTransform: "uppercase",
        fontSize: "0.85rem",
        paddingLeft: "0.9rem",
        color: context.iconColour,
        paddingBottom: "1.25rem"
    }

    return (
        <div className="row no-gutters" style={styles}>
            {title}
        </div>
    );
};

export default Title;