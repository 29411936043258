import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { createBlogPostAsync } from '../../Services/BlogService';
import Card from '../../Shared/Components/Card/Card';
import Header from '../../Shared/Components/Header/Header';
import Button from './../../Shared/Components/Button/Button';
import { DefaultEditor } from 'react-simple-wysiwyg';

const CreateBlogPost = () => {
    const [image, setImage] = useState(null);
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const history = useHistory();

    const handleSubmit = async () => {
        let formData = new FormData();
        formData.append("Title", title);
        formData.append("Body", body);
        formData.append("Image", image);
        let response = await createBlogPostAsync(formData);
        history.push(`posts/${response.urlSlug}`);
    }

    const handleTitleChange = evt => {
        setTitle(evt.target.value);
    }

    const handleBodyChange = evt => {
        setBody(evt.target.value);
    }

    const handleImageChange = evt => {
        setImage(evt.target.files[0]);
    }

    return (
        <>
            <Header heading="Create new blog post" subheading="Please fill out the below details to create a new blog post" icon="folder" />
            <div className="container-fluid content">
                <Card title="Step 1">
                    <div className="form">
                        <div className="form-row">
                            <div className="form-group col-12">
                                <label>Title</label>
                                <input value={title} type="text" className="form-control" name="title" id="title" onChange={handleTitleChange} />
                            </div>
                            <div className="form-group col-12">
                                <label>Body</label>
                                {/* <textarea value={body} className="form-control" name="body" id="body" onChange={handleBodyChange} /> */}
                                <DefaultEditor value={body} onChange={handleBodyChange} />

                            </div>
                            <div className="form-group col-md-6">
                                <label>Image</label>
                                <div className="custom-file">
                                    <input className="custom-file-input" name="image" id="image" type="file" accept=".png,.jpg,.jpeg" onChange={handleImageChange} />
                                    <label className="custom-file-label">{image === null ? 'Browse' : image.name}</label>
                                </div>
                                {image !== null &&
                                    <img className="img img-fluid mt-3" src={URL.createObjectURL(image)} />
                                }
                            </div>
                        </div>
                    </div>
                    <Button
                        text="Next"
                        classes="btn btn-success float-right"
                        func={handleSubmit}
                    />
                </Card>

            </div>
        </>
    );
};

export default CreateBlogPost;