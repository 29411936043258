import React from 'react';
import { Route } from 'react-router-dom';
import Login from '../Shared/Login/Login';
import { createBrowserHistory } from 'history';
import { Router, Switch } from 'react-router-dom';
import ResetPassword from '../Shared/Account/ResetPassword';
import ConfirmAccount from '../Shared/Account/ConfirmAccount';
import ConfirmLearnerAccount from '../Shared/Account/ConfirmLearnerAccount';
import PrivacyPolicy from '../Pages/GDPR/PrivacyPolicy';
import Certificate from '../Pages/ELearning/Courses/Certificate';

const history = createBrowserHistory();

const AnnonymousRoutes = props => {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/">
                    <Login setIsLoggedIn={props.setIsLoggedIn} />
                </Route>
                <Route exact path="/Reset/:token/:userId" component={ResetPassword} />
                <Route exact path="/Confirm/:userId/:token" component={ConfirmAccount} />
                <Route exact path="/Register/:userId/:token" component={ConfirmLearnerAccount} />

                <Route exact path="/gdpr/privacy" component={PrivacyPolicy} />
                <Route exact path="/certificate/:courseId/:userId" component={Certificate} />
            </Switch>
        </Router>
    );
};

export default AnnonymousRoutes;