import axios from 'axios';
import { baseUrl } from '../Infrastructure/Config/ApiConfig';
import { token } from './AuthService';

const base = `${baseUrl()}ContactForm`;

export const getAllEnquiriesAsync = async () => {
    const response = await axios.get(`${base}/GetAll`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getEnquiryAsync = async (id) => {
    const response = await axios.get(`${base}/GetById/${id}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const markEnquiryAsReadAsync = async (id) => {
    let data = {
        enquiryId: id
    }

    const response = await axios.post(`${base}/MarkAsRead`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}