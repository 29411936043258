import axios from 'axios';
import { baseUrl } from '../Infrastructure/Config/ApiConfig';
import { token } from './AuthService';

const controllerName = 'FeedbackForm';

export const getAllFeedbackFormsAsync = async () => {
    const response = await axios.get(`${baseUrl()}${controllerName}/GetAllFeedbackForms`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getCourseFeedbackAsync = async (feedbackFormId, clientId) => {
    let cId = "";
    if (clientId !== undefined) {
        cId = `&clientId=${clientId}`
    }

    const response = await axios.get(`${baseUrl()}${controllerName}/GetFeedbackForCourse?feedbackFormId=${feedbackFormId}${cId}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getFeedbackFormAsync = async (id) => {
    const response = await axios.get(`${baseUrl()}${controllerName}/GetFeedbackForm?FeedbackFormId=${id}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const createNewFeedbackFormAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/CreateFeedbackForm`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const createCopyOfFeedbackFormAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/CreateCopyOfFeedbackForm`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const addQuestionToFormAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/AddQuestionToForm`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const addAnswerToQuestionAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/AddAnswerToQuestion`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}


export const deleteQuestionFromFeedbackFormAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/DeleteQuestionFromFeedbackForm`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const deleteAnswerFromQuestionAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/DeleteAnswer`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const deleteFeedbackFormAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/DeleteFeedbackForm`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const updateAnswersAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/UpdateAnswer`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const updateQuestionOrdinalsAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/UpdateQuestionOrdinals`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const completeFeedbackFormAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/completeFeedbackForm`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const assignFeedbackFormToCourseAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/AssignFeedbackFormToCourse`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const assignClientToFeedbackFormAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/AssignClientToFeedbackForm`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const RemoveClientFromFeedbackFormAsync = async (data) => {
    const response = await axios.post(`${baseUrl()}${controllerName}/RemoveClientFromFeedbackForm`, data, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

