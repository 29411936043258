import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { getAllCoursesAsync } from '../../../../Services/CourseService';
import { addAnswerToQuestionAsync, addQuestionToFormAsync, createCopyOfFeedbackFormAsync, deleteAnswerFromQuestionAsync, deleteQuestionFromFeedbackFormAsync, getFeedbackFormAsync, updateAnswersAsync } from '../../../../Services/FeedbackFormService';
import Card from '../../../../Shared/Components/Card/Card';
import Header from '../../../../Shared/Components/Header/Header';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Button from './../../../../Shared/Components/Button/Button';
import Answer from './Answer';

const Question = ({ q, deleteQuestion,
    handleAnswerChange, addAnswer,
    i, deleteAnswerFromQuestion, answerValue, setFeedbackForm, feedbackForm, collapsed, toggleCollapsed }) => {

    const [dragDisabled, setDragDisabled] = useState(true);

    const getListStyle = isDraggingOver => ({
        width: "100%"
    });

    const updateOrdinals = async (answers) => {
        let updatedAnswers = [];

        answers.forEach((a, i) => {
            updatedAnswers.push({ id: a.id, ordinal: i })
        });

        let data = {
            answers: updatedAnswers
        }
        await updateAnswersAsync(data);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            q.answers,
            result.source.index,
            result.destination.index
        );
        let updatedForm = { ...feedbackForm };
        updatedForm.questions[i].answers = items;
        updateOrdinals(items);
        setFeedbackForm(updatedForm);
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        ...draggableStyle
    });

    return (
        <Card>
            <div className="row question">
                <div className="col-12 ">
                    <strong className='mb-0'>
                        <div className="float-left drag">
                            <span className="icon clickable float-right fa fa-bars mr-3" title="Drag"></span>
                        </div>
                        <div>
                            <p>
                                {q.value} <span>({q.answers.length} answers)</span>
                                <span onClick={() => { toggleCollapsed() }} title="Show/Hide" className={`icon ml-3 clickable  ${!collapsed ? "fa fa-chevron-up" : "fa fa-chevron-down"}`}></span>
                                <span onClick={() => { deleteQuestion(q.id) }} title="Delete" className='fa fa-trash ml-3 clickable delete float-right'></span>
                            </p>
                        </div>
                    </strong>
                </div>
                {!collapsed &&
                    <>
                        <div className="answerWrapper">
                            {q.answers < 1 &&
                                <div className="alert alert-primary text-center mt-3" role="alert">
                                    Questions without answers will not be displayed on the feedback form.
                                </div>
                            }
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {q.answers.map((fa, i) =>
                                                <Draggable key={fa.id} draggableId={fa.id} index={i}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            <Answer isDragging={snapshot.isDragging} fa={fa} deleteAnswerFromQuestion={deleteAnswerFromQuestion} />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        <div className="col-12">
                            <div className="input-group mb-3">
                                <input onChange={handleAnswerChange} value={answerValue.questionId === q.id ? answerValue.value : ""} name={q.id} type="text" className="form-control" placeholder="Add Answer..." />
                                <div className="input-group-append">
                                    <Button text="Add" classes="btn btn-success btn-append" func={addAnswer} />
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </Card>
    );
};

export default Question;