import React, { useContext } from 'react';
import { ThemeContext } from '../../Infrastructure/Context/ThemeContext';

const Table = ({ headings, rowData }) => {
    const context = useContext(ThemeContext);

    return (
        <div className="table-responsive">
            <table className="table">
                <thead className="card-header" style={{ "color": context.iconColour }}>
                    <tr>
                        {headings()}
                    </tr>
                </thead>
                <tbody>
                    {rowData()}
                </tbody>
            </table>
        </div>
    );
};

export default Table;