import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { searchLearnersAsync } from '../../Services/ClientService';
import PrimaryButton from '../../Shared/Components/PrimaryButton/PrimaryButton';
import Table from '../../Shared/Components/Table';
import Header from './../../Shared/Components/Header/Header';
import Card from './../../Shared/Components/Card/Card';
import Button from './../../Shared/Components/Button/Button';
import Modal from '../../Shared/Components/Modal/Modal';
import { archiveUsersAsync, deleteUsersAsync, exportUsersAsync } from '../../Services/UserService';
import { showToast } from '../../Infrastructure/Notifications/ToastrConfig';

const NO_ACTION = "No Action";
const DELETE = "Delete";
const EXPORT = "Export";
const ARCHIVE = "Archive";

const ManageLearners = () => {

    const [learners, setLearners] = useState([]);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [actionSelected, setActionSelected] = useState(NO_ACTION);
    const [forceLoad, setForceLoad] = useState(false);

    const [state, setState] = useState({
        pageNumber: 1,
        pageSize: 10,
        searchTerm: '',
        totalLearners: 0
    });

    useEffect(() => {
        const search = async () => {
            let response = await searchLearnersAsync(state.pageNumber, state.pageSize, state.searchTerm);
            if (state.pageNumber === 1) {
                setLearners(response.users)
            }
            else {
                setLearners(learners.concat(response.users));
            }
            setState(s => ({
                totalLearners: response.totalPages,
                pageNumber: s.pageNumber,
                pageSize: s.pageSize,
                searchTerm: s.searchTerm
            }))
        }
        search();
    }, [state.pageNumber, state.pageSize, state.searchTerm, forceLoad]);

    const handleChange = async evt => {
        setState({ ...state, [evt.target.name]: evt.target.value, pageNumber: 1 });
    }

    const loadMore = () => {
        if (learners.length <= state.totalLearners) {
            setState({ ...state, pageNumber: state.pageNumber + 1 });
        }
    }

    const handleActionChange = evt => {
        setActionSelected(evt.target.value);
    }

    const handleSelectAll = evt => {
        if (evt.target.checked) {
            let ids = learners.map(u => u.id);
            setSelectedUserIds(ids);
        }
        else {
            setSelectedUserIds([])
        }
    }

    const deleteSelectedLearners = async () => {
        let data = {
            selectedUserIds: selectedUserIds
        }

        let response = await deleteUsersAsync(data);
        if (response.success) {
            showToast(true, "Users deleted");
            if (state.pageNumber !== 1) {
                setState({ ...state, pageNumber: 1, searchTerm: '' });
            }
            else {
                setForceLoad(!forceLoad);
            }

        }
        else {
            showToast(false, "Something went wrong")
        }
    }

    const archiveSelectedLearners = async () => {
        let data = {
            userIds: selectedUserIds
        }

        let response = await archiveUsersAsync(data);
        if (response.success) {
            showToast(true, "Users archived");
            if (state.pageNumber !== 1) {
                setState({ ...state, pageNumber: 1, searchTerm: '' });
            }
            else {
                setForceLoad(!forceLoad);
            }

        }
        else {
            showToast(false, "Something went wrong")
        }
    }

    const exportSelectedLearners = async () => {
        let data = {
            selectedUserIds: selectedUserIds
        }

        await exportUsersAsync(data);
    }

    const handleSelect = (id) => {
        if (selectedUserIds.includes(id)) {
            const index = selectedUserIds.indexOf(id);
            if (index > -1) {
                selectedUserIds.splice(index, 1);
                let newArray = [...selectedUserIds]
                setSelectedUserIds(newArray);
            }
        }
        else {
            selectedUserIds.push(id);
            let newArray = [...selectedUserIds]
            setSelectedUserIds(newArray);
        }
    }

    const headings = () => {
        return (<>
            <th>Name</th>
            <th>Email</th>
            <th><input onChange={handleSelectAll} className="float-right" type="checkbox" id="selectLearner" name="selectLearner" style={{ height: "20px" }} /></th>
        </>)
    }

    const rowData = () => {
        return (<>
            {learners.length > 0 ?
                learners.map(
                    (u, i) =>
                        <tr key={i}>
                            <td>
                                {u.name}
                            </td>
                            <td>
                                {u.email}
                            </td>
                            <td>
                                <input onChange={() => { handleSelect(u.id) }} className="float-right" type="checkbox" checked={selectedUserIds.includes(u.id)}
                                    id="selectLearner" name="selectLearner" style={{ height: "20px" }} />
                                <Link to={`/learner/${u.urlToken}`}>
                                    <span className="view fas fa-eye float-right mr-3" title="View"></span>
                                </Link>
                            </td>
                        </tr>
                )
                :
                <tr>
                    <td className="no-data" colSpan="3">
                        No learners to currently display
                    </td>
                </tr>
            }
        </>)
    }

    return (
        <>
            <Modal modalId="delete-users"
                title="Delete learners"
                bodyText={`Warning! Deleting users is ireversable! Are you sure you want to delete the ${selectedUserIds.length} selected users?`}
                buttonText={"Delete"}
                buttonFunc={deleteSelectedLearners}
            />
            <Modal modalId="archive-users"
                title="Delete learners"
                bodyText={`Are you sure you want to archive the ${selectedUserIds.length} selected users?`}
                buttonText={"Archive"}
                buttonFunc={archiveSelectedLearners}
            />
            <Modal modalId="export-users"
                title="Export learners"
                bodyText={`Are you sure you want to export the ${selectedUserIds.length} selected users?`}
                buttonText={"Export"}
                buttonFunc={exportSelectedLearners}
            />
            <Header heading="Learners" subheading="Manage current learners" icon="graduation" />
            <div className="container-fluid content">
                <Card>
                    <div className="float-left">
                        <Button text="Create New" classes="btn btn-success" link="/create-learner" />
                        <Button text="Upload CSV" classes="btn btn-success ml-2" link="/upload-learners" />
                    </div>
                </Card>
                <Card>
                    <div className="col-12 p-0">
                        <div className="form-group float-left">
                            <input name="searchTerm" className="form-control" placeholder="Search" onChange={handleChange} />
                        </div>
                        <div className="form-group float-left ml-3">
                            <select value={actionSelected} className="form-control" onChange={handleActionChange}>
                                <option value={NO_ACTION}>No action selected</option>
                                <option value={DELETE}>Delete Learners</option>
                                <option value={ARCHIVE}>Archive Learners</option>
                                <option value={EXPORT}>Export Learners</option>
                            </select>
                        </div>
                        <div className="form-group float-right ml-3">
                            <select className="form-control" value={state.pageSize} onChange={handleChange} name="pageSize">
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={250}>250</option>
                                <option value={500}>500</option>
                            </select>
                        </div>
                        {actionSelected === DELETE &&
                            <Button text="Delete learners" classes="btn btn-danger action-btn ml-2" dataTarget="delete-users" />
                        }
                        {actionSelected === EXPORT &&
                            <Button text="Export learners" classes="btn btn-success action-btn ml-2" dataTarget="export-users" />
                        }
                        {actionSelected === ARCHIVE &&
                            <Button text="Archive learners" classes="btn btn-success action-btn ml-2" dataTarget="archive-users" />
                        }
                    </div>
                    <Table headings={headings} rowData={rowData} />
                    <div className="mt-3 text-center">
                        <div className="small mb-3">
                            <span>Showing {learners.length} of {state.totalLearners}</span>
                        </div>
                        <PrimaryButton
                            text="Load more"
                            classes="clickable"
                            func={loadMore}
                            disabled={learners.length >= state.totalLearners}
                        />
                    </div>
                </Card>
            </div>
        </>
    );
};

export default ManageLearners;