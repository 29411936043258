import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    const [year, setYear] = useState(null);

    useEffect(() => {
        let date = new Date();
        setYear(date.getFullYear())
    }, [])

    return (
        <div className="text-center py-2" style={{ "background": "#ececec" }}>
            <p style={{ "fontSize": "0.8rem" }} className="m-0 p-0">&copy; {year} AcademyOne. All rights reserved - <Link to="/gdpr/privacy">Privacy policy</Link></p>
        </div>
    );
};

export default Footer;