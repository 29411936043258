import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getEnquiryAsync, markEnquiryAsReadAsync } from '../../Services/ContactFormService';
import Card from '../../Shared/Components/Card/Card';
import Header from '../../Shared/Components/Header/Header';
import PrimaryButton from '../../Shared/Components/PrimaryButton/PrimaryButton';

const Enquiry = () => {
    const { id } = useParams();
    const [enquiry, setEnquiry] = useState({});

    useEffect(() => {
        const getEnquiry = async () => {
            let enquiry = await getEnquiryAsync(id);
            setEnquiry(enquiry);
            if (enquiry.readDateTime === null) {
                await markEnquiryAsReadAsync(id);
            }
        }
        getEnquiry();
    }, [id])

    return (
        <>
            <Header heading={enquiry.name} subheading={enquiry.createdDateTime} icon="inbox" />
            <div className="container-fluid content">
                <div className="row">
                    <div className="col-12">
                        <Card title="Details">
                            <div className="row">
                                <div className="col-6">
                                    <p><strong>Name: </strong>{enquiry.name}</p>
                                </div>
                                <div className="col-6">
                                    <p><strong>Company: </strong>{enquiry.company}</p>
                                </div>
                                <div className="col-6">
                                    <p><strong>Email: </strong>{enquiry.email}</p>
                                </div>
                                <div className="col-6">
                                    <p><strong>Message: </strong>{enquiry.enquiry}</p>
                                </div>
                                <div className="col-6">
                                    <p><strong>Phone: </strong>{enquiry.phoneNumber}</p>
                                </div>
                                <div className="col-6">
                                    <p><strong>Recieved: </strong>{enquiry.createdDateTime}</p>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Enquiry;