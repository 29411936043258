import React, { useState } from 'react';
import './Login.scss';
import Logo from '../../Assets/AcademyOneLogoBlack.png';
import { passwordResetRequestAsync } from '../../Services/AccountService';
import Card from './../Components/Card/Card';
import TSALogo from '../../Assets/TSALogo.png';

const ForgotPassword = props => {
    const [email, setEmail] = useState(null);
    const [showMessage, setShowMessage] = useState(false);

    const handleChange = evt => {
        setEmail(evt.target.value);
    }

    const resetPassword = async () => {
        if (email !== null) {
            let data = {
                email: email
            }

            setShowMessage(true);
            await passwordResetRequestAsync(data);
        }
    }
    return (
        <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4 mx-auto flex-box">
            <Card classes="login-box">
                <div className="text-center">
                    {window.location.href.includes('thesustainabilityacademy') ?
                        <img alt="Login logo" className="img-fluid login-logo" src={TSALogo} />
                        :
                        <img alt="Login logo" className="img-fluid login-logo" src={Logo} />
                    }
                </div>
                <div className="text-center pb-4">
                    <span>Please enter your email address below and we’ll email you a link to set up a new password</span>
                </div>
                <div className="form-group">
                    <input className="form-control" name="username" id="username" onChange={handleChange} placeholder="Email address" autoComplete="true" />
                </div>
                <div className="form-group text-center">
                    {showMessage && <span>You will recieve an email with password reset instructions shortly.</span>}
                </div>
                <div className="form-group pt-4">
                    <button className="btn btn-secondary float-left" onClick={props.cancel}>Cancel</button>
                    {window.location.href.includes('thesustainabilityacademy') ?
                        <button className="btn login-btn-tsa float-right" onClick={resetPassword}>Reset Password</button>
                        :
                        <button className="btn login-btn float-right" onClick={resetPassword}>Reset Password</button>
                    }
                </div>
            </Card>
        </div>
    );
};

export default ForgotPassword;