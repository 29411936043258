import React from 'react';
import Button from './Button/Button';

const PaginationControls = ({ previous, next, current, total }) => {
    return (
        <div className="col-12 text-center">
            <div className="small mb-3">
                <span>Page {current} of {total}</span>
            </div>
            <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center">
                    <Button text="Previous" classes="btn-primary mr-2" func={previous} disabled={(current === 1)} />
                    <Button text="Next" classes="btn-primary" func={next} disabled={(current === total)} />
                </ul>
            </nav>
        </div>
    );
};

export default PaginationControls;