import React, { useContext } from 'react';
import './StatProgressCard.scss';
import { Line } from 'rc-progress';
import { ThemeContext } from '../../../Infrastructure/Context/ThemeContext';

const StatProgressCard = ({ title, stat, subtext, percentage, colour, footerText, footerStat }) => {
    const context = useContext(ThemeContext);
    return (
        <div className="col-12 col-sm-6 col-lg-4">
            <div className="stat-progress-card mb-3">
                <div className="stat-top">
                    <div className="text">
                        <h4>{title}</h4>
                        <p>{subtext}</p>
                    </div>
                    <div className="stat">
                        <h3>{stat}</h3>
                    </div>
                </div>
                <div className="stat-progress">
                    <Line percent={percentage} strokeWidth="2" strokeColor={colour ? colour : context.iconColour} />
                </div>
                <div className="stat-bottom">
                    <p>{footerText}</p>
                    <p>{footerStat}</p>
                </div>
            </div>
        </div>
    );
};

export default StatProgressCard;