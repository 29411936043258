import React from 'react';
import { Route } from 'react-router-dom';
import Client from '../Pages/Clients/Client';
import Clients from '../Pages/Clients/Clients';
import CreateClient from '../Pages/Clients/CreateClient';
import AdminDashboard from '../Pages/Dashboard/AdminDashboard';
import Course from '../Pages/ELearning/Courses/Course';
import Courses from '../Pages/ELearning/Courses/Courses';
import UploadCourse from '../Pages/ELearning/Courses/UploadCourse/UploadCourse';
import CreateLearningPathway from '../Pages/ELearning/LearningPathways/CreateLearningPathway';
import LearningPathway from '../Pages/ELearning/LearningPathways/LearningPathway';
import LearningPathways from '../Pages/ELearning/LearningPathways/LearningPathways';
import ThemeSettings from '../Pages/Settings/ThemeSettings';
import Enquiries from '../Pages/Website/Enquiries';
import Enquiry from '../Pages/Website/Enquiry';
import MyAccount from '../Shared/Account/MyAccount';
import CreateLearner from '../Pages/Learners/CreateLearner';
import Learner from '../Pages/Learners/Learner';
import ManageLearners from '../Pages/Learners/ManageLearners';
import UploadLearners from '../Pages/Learners/UploadLearners';
import LearnerProgress from '../Pages/Learners/LearnerProgress';
import AdminUser from '../Pages/User/AdminUser';
import Users from '../Pages/User/Users';
import CreateUser from '../Pages/User/CreateUser';
import PrivacyPolicy from '../Pages/GDPR/PrivacyPolicy';
import RegistrationDetails from '../Pages/Settings/RegistrationDetails';
import SentEmails from '../Pages/Emails/SentEmails';
import Insights from '../Pages/Reports/Insights';
import DevStatus from '../Pages/Status/DevStatus';
import Reporting from '../Pages/Reports/Reporting';
import Blog from '../Pages/Website/Blog';
import FeedbackForms from '../Pages/ELearning/FeedbackForms/FeedbackForms';
import FeedbackForm from '../Pages/ELearning/FeedbackForms/FeedbackForm';
import CreateFeedbackForm from '../Pages/ELearning/FeedbackForms/CreateFeedbackForm';
import CreateBlogPost from '../Pages/Website/CreateBlogPost';
import BlogPost from '../Pages/Website/BlogPost';
import FeedbackStats from '../Pages/ELearning/FeedbackForms/FeedbackStats';
import Wellness from '../Pages/Wellness/Wellness';
import Community from '../Pages/Community/Community';
import ManageCommunities from '../Pages/Community/ManageCommunities';
import CreateCommunity from '../Pages/Community/CreateCommunity';

const AdminRoutes = () => {
    return (
        <>
            <Route exact path="/" component={AdminDashboard} />
            <Route exact path="/my-account" component={MyAccount} />

            <Route exact path="/settings/theme" component={ThemeSettings} />

            <Route exact path="/registration-details" component={RegistrationDetails} />

            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog/new-post" component={CreateBlogPost} />
            <Route path="/blog/posts/:urlSlug" component={BlogPost} />

            <Route exact path="/upload-course" component={UploadCourse} />
            <Route exact path="/courses" component={Courses} />
            <Route exact path="/course/:id" component={Course} />
            <Route exact path="/create-pathway" component={CreateLearningPathway} />
            <Route exact path="/learning-pathways" component={LearningPathways} />
            <Route exact path="/learning-pathway/:id" component={LearningPathway} />
            <Route exact path="/feedback-forms" component={FeedbackForms} />
            <Route exact path="/feedback-form" component={CreateFeedbackForm} />
            <Route exact path="/feedback-form/:id" component={FeedbackForm} />
            <Route exact path="/feedback-stats/:id" component={FeedbackStats} />
            <Route exact path="/feedback-stats/:id/:clientId" component={FeedbackStats} />

            <Route exact path="/manage-learners" component={ManageLearners} />
            <Route exact path="/learner-progress" component={LearnerProgress} />
            <Route exact path="/upload-learners" component={UploadLearners} />
            <Route exact path="/learner/:urlToken" component={Learner} />
            <Route exact path="/create-learner" component={CreateLearner} />

            <Route exact path="/clients" component={Clients} />
            <Route exact path="/client/:urlToken" component={Client} />
            <Route exact path="/create-client" component={CreateClient} />

            <Route exact path="/enquiries" component={Enquiries} />
            <Route exact path="/enquiry/:id" component={Enquiry} />

            <Route exact path="/user/:urlToken" component={AdminUser} />
            <Route exact path="/users" component={Users} />
            <Route exact path="/create-user" component={CreateUser} />

            <Route exact path="/emails" component={SentEmails} />

            <Route exact path="/gdpr/privacy" component={PrivacyPolicy} />

            <Route exact path="/reporting/clients" component={Reporting} />
            <Route exact path="/reporting/user-insights" component={Insights} />

            <Route exact path="/devops" component={DevStatus} />
            <Route exact path="/wellness" component={Wellness} />

            <Route exact path="/communities" component={ManageCommunities} />
            <Route exact path="/communities/create" component={CreateCommunity} />
            <Route path="/community/:id" component={Community} />

        </>
    );
};

export default AdminRoutes;