import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { showToast } from '../../Infrastructure/Notifications/ToastrConfig';
import { previewUsersCsvAsync, uploadUsersCsvAsync } from '../../Services/ClientService';
import Card from '../../Shared/Components/Card/Card';
import PrimaryButton from '../../Shared/Components/PrimaryButton/PrimaryButton';
import Table from '../../Shared/Components/Table';
import Header from './../../Shared/Components/Header/Header';

const UploadLearners = () => {

    const [file, setFile] = useState(null);
    const [goodRows, setGoodRows] = useState([]);
    const [badRows, setBadRows] = useState([]);
    const [uploaded, setUploaded] = useState(false);
    const history = useHistory();
    const [uploading, setUploading] = useState(false);

    const handleFileChange = async evt => {
        setFile(evt.target.files[0]);
        let form = new FormData();
        form.append("csvFile", evt.target.files[0])
        let response = await previewUsersCsvAsync(form);
        setGoodRows(response.goodRows);
        setBadRows(response.badRows);
        setUploaded(true);
    }

    const uploadUsers = async () => {
        setUploading(true);
        let storedUser = JSON.parse(window.localStorage.getItem("user"));
        let data = {
            users: goodRows,
            clientId: storedUser.clientId
        }

        let response = await uploadUsersCsvAsync(data);
        if (response.success) {
            showToast(true, "Users uploaded");
            history.push("/manage-learners");
        }
        else {
            showToast(false, response.errors[0].error);
            setUploading(false);
        }
    }

    const headings = () => {
        return (
            <>
                <th>First name</th>
                <th>Last name</th>
                <th>Email</th>
            </>
        )
    }

    const goodRowData = () => {
        return (
            <>
                {goodRows.map(
                    (l, i) =>
                        <tr key={i}>
                            <td>
                                {l.firstName}
                            </td>
                            <td>
                                {l.lastName}
                            </td>
                            <td>
                                {l.email}
                            </td>
                        </tr>
                )}
            </>
        )
    }

    const badRowData = () => {
        return (
            <>
                {badRows.map(
                    (l, i) =>
                        <tr key={i}>
                            <td>
                                {l.firstName}
                            </td>
                            <td>
                                {l.lastName}
                            </td>
                            <td>
                                {l.email}
                            </td>
                        </tr>
                )}
            </>
        )
    }

    return (
        <>
            <Header heading="Upload Learners" subheading="Vitae elementum curabitur vitae nunc sed" icon="upload" />
            <div className="container-fluid content">
                <div className="row">
                    <div className="col-12">
                        <Card title="Choose CSV">
                            <div className="form-group">
                                <div className="custom-file">
                                    <input accept=".csv" className="custom-file-input" type="file" onChange={handleFileChange} />
                                    <label className="custom-file-label">{file && file.name}</label>
                                </div>
                            </div>
                            {uploaded &&
                                <p>{goodRows.length} users will be uploaded. {badRows.length} users were invalid</p>
                            }
                        </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {goodRows.length > 0 &&
                            <Card title="The following users will be uploaded">
                                <Table headings={headings} rowData={goodRowData} />
                                {uploading && <p>Uploading, please wait</p>}
                                <PrimaryButton text="Upload users" func={uploadUsers} classes="float-right mt-3" />
                            </Card>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {badRows.length > 0 &&
                            <Card title="The following users already exist, or the rows contained errors">
                                <Table headings={headings} rowData={badRowData} />
                            </Card>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default UploadLearners;