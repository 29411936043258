import axios from 'axios';
import { baseUrl } from '../Infrastructure/Config/ApiConfig';
import { token } from './AuthService';
import download from 'downloadjs';
import moment from 'moment';

const controllerName = 'Report';

export const getMonthlyOverviewAsync = async (fromDate, toDate) => {
    const response = await axios.get(`${baseUrl()}${controllerName}/MonthlyOverview?fromDate=${fromDate}&toDate=${toDate}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getClientMonthlyOverviewAsync = async (clientId, fromDate, toDate, clientName) => {

    let to = moment(toDate).format("DD-MM-YY");
    let from = moment(fromDate).format("DD-MM-YY");

    const response = await axios.get(`${baseUrl()}${controllerName}/ClientReport?fromDate=${fromDate}&toDate=${toDate}&clientId=${clientId}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    }).then(response => {
        const content = response.headers['content-type'];
        const fileName = `${clientName} ${from} to ${to}.csv`
        download(response.data, fileName, content);
        return true;
    })
        .catch(
            error => console.log(error)
        )

    return response;
}

export const getWellnessOverviewAsync = async () => {

    const response = await axios.get(`${baseUrl()}${controllerName}/wellness`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    }).then(response => {
        const content = response.headers['content-type'];
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        today = dd + '-' + mm + '-' + yyyy;
        const fileName = `wellness-results-${today}.csv`
        download(response.data, fileName, content);
        return true;
    })
        .catch(
            error => console.log(error)
        )

    return response;
}

export const getLearnerStatsAsync = async (date) => {
    const response = await axios.get(`${baseUrl()}${controllerName}/genderstats`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    }).then(response => {
        const content = response.headers['content-type'];
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        today = dd + '-' + mm + '-' + yyyy;

        const fileName = `gender-stats-${today}.csv`
        download(response.data, fileName, content);
        return true;
    })
        .catch(
            error => console.log(error)
        )
    return response;
}

export const getClientStatsAsync = async (date) => {
    const response = await axios.get(`${baseUrl()}${controllerName}/clientStats`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    }).then(response => {
        const content = response.headers['content-type'];
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        today = dd + '-' + mm + '-' + yyyy;

        const fileName = `clients-stats-${today}.csv`
        download(response.data, fileName, content);
        return true;
    })
        .catch(
            error => console.log(error)
        )
    return response;
}

export const getInsightsAsync = async (clientId) => {
    let qry = '';
    if (clientId !== '') {
        qry = `?clientId=${clientId}`
    }
    const response = await axios.get(`${baseUrl()}${controllerName}/insights${qry}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });

    return response.data;
}

export const getCompletedPathwaysAsync = async (clientId, pathwayId) => {
    const response = await axios.get(`${baseUrl()}${controllerName}/GetCompletedPathways?clientId=${clientId}&pathwayId=${pathwayId}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    }).then(response => {
        const content = response.headers['content-type'];
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        today = dd + '-' + mm + '-' + yyyy;

        const fileName = `completed-pathways-${today}.csv`
        download(response.data, fileName, content);
        return true;
    })
        .catch(
            error => console.log(error)
        )
    return response;
}