import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getLearningPathwayAsync } from '../../../Services/CourseService';
import Header from '../../../Shared/Components/Header/Header';
import Card from './../../../Shared/Components/Card/Card';
import Title from './../../../Shared/Components/Title/Title';
import Table from '../../../Shared/Components/Table';
import { playCourseUrl } from '../../../Infrastructure/Config/ApiConfig';

const ClientLearningPathway = () => {
    const { id } = useParams();
    const [pathway, setPathway] = useState({ courses: [] });

    useEffect(() => {
        const getPathway = async () => {
            let response = await getLearningPathwayAsync(id);
            setPathway(response);
        }
        getPathway();
    }, [id])

    const launchCourse = async (id, title) => {
        window.open(`${playCourseUrl()}preview/${id}`, title, 'toolbar=no,menubar=no,location=no')
    }

    const headings = () => {
        return (<>
            <th>Title</th>
            <th>Description</th>
            <th>Reference</th>
            <th></th>
        </>)
    }

    const rowData = () => {
        return (<>
            {pathway.courses.length > 0 ?
                pathway.courses.map(
                    (u, i) =>
                        <tr key={i}>
                            <td>
                                {u.title}
                            </td>
                            <td>
                                {u.description}
                            </td>
                            <td>{u.reference}</td>
                            <td>
                                <span onClick={() => { launchCourse(u.id, u.title) }} className="view fas fa-eye float-right mr-3" title="View"></span>
                            </td>
                        </tr>
                )
                :
                <tr>
                    <td className="no-data" colSpan="3">
                        No courses to currently display
                    </td>
                </tr>
            }
        </>)
    }

    return (
        <>
            <Header heading={pathway.name} subheading="Learning Pathway Information" icon="package" />
            <div className="container-fluid content">
                <Card>
                    <div className="row">
                        <Title title="Description"></Title>
                        <div className="col-12">
                            <p>{pathway.description}</p>
                        </div>
                    </div>
                </Card>
                <Card title="Courses">
                    <div className="col-12 p-0">
                        <div className="form-group float-left">
                            <input name="searchTerm" className="form-control" placeholder="Search" />
                        </div>
                        <div className="form-group float-right ml-3">
                            <select className="form-control" value="10" name="pageSize">
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={250}>250</option>
                                <option value={500}>500</option>
                            </select>
                        </div>
                    </div>
                    <Table headings={headings} rowData={rowData} />
                </Card>
            </div>
        </>
    );
};

export default ClientLearningPathway;