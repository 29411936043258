import React from 'react';
import { Route } from 'react-router-dom';
import LearnerDashboard from '../Pages/Dashboard/LearnerDashboard';
import Certificate from '../Pages/ELearning/Courses/Certificate';
import CourseBadge from '../Pages/ELearning/Courses/CourseBadge';
import Engage from '../Pages/ELearning/Courses/Engage';
import LearnerFeedback from '../Pages/ELearning/FeedbackForms/LearnerFeedback';
import LearnerLearningPathway from '../Pages/ELearning/LearningPathways/LearnerLearningPathway';
import LearnerLearningPathways from '../Pages/ELearning/LearningPathways/LearnerLearningPathways';
import PrivacyPolicy from '../Pages/GDPR/PrivacyPolicy';
import MyAccount from '../Shared/Account/MyAccount';

const LearnerRoutes = () => {
    return (
        <>
            <Route exact path="/my-account" component={MyAccount} />
            <Route exact path="/" component={LearnerDashboard} />
            <Route exact path="/engage/:id" component={Engage} />
            <Route exact path="/certificate/:courseId/:userId" component={Certificate} />
            <Route exact path="/badge" component={CourseBadge} />

            <Route exact path="/learning-pathways" component={LearnerLearningPathways} />
            <Route exact path="/learning-pathway/:id" component={LearnerLearningPathway} />

            <Route exact path="/feedback/:id" component={LearnerFeedback} />

            <Route exact path="/gdpr/privacy" component={PrivacyPolicy} />
        </>
    );
};

export default LearnerRoutes;