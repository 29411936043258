import React, { useEffect, useState } from 'react';
import PrimaryButton from './../../Components/PrimaryButton/PrimaryButton';
import Card from './../../Components/Card/Card';
import { getMyAccountAsync, updateAccountAsync } from './../../../Services/AccountService';
import { showToast } from '../../../Infrastructure/Notifications/ToastrConfig';
import Button from './../../Components/Button/Button';
import { validEmailRegex } from './../../../Infrastructure/Config/Regexes';

const UpdateUserDetails = () => {

    const [myAccount, setMyAccount] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: ''
    });
    const [editMode, setEditMode] = useState(true);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        fetchAccount();
    }, [])

    const fetchAccount = async () => {
        let account = await getMyAccountAsync();
        setMyAccount(account);
    }

    const toggleEditMode = () => {
        setEditMode(!editMode);
        setErrors([]);
    }

    const handleChange = evt => {
        setMyAccount({ ...myAccount, [evt.target.name]: evt.target.value });
    }

    const handleValidation = () => {
        let fields = myAccount;
        let errors = {};
        let formIsValid = true;

        //firstName
        if (!fields["firstName"]) {
            formIsValid = false;
            errors["firstName"] = "Please enter a first name";
        }

        //lastName
        if (!fields["lastName"]) {
            formIsValid = false;
            errors["lastName"] = "Please enter a last name";
        }

        //email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Please enter an email address";
        } else {
            if (!validEmailRegex.test(fields["email"])) {
                formIsValid = false;
                errors["email"] = "Please enter a valid email address";
            }
        }

        setErrors(errors);
        return formIsValid;
    }

    const updateUserDetails = async () => {
        if (handleValidation()) {
            let response = await updateAccountAsync(myAccount);
            if (response.success) {
                showToast(true, "Successfully updated details");
            }
            else {
                showToast(false, response.errors[0].error);
            }
            toggleEditMode();
        }
    }

    return (
        <div className="col-12 form">
            <Card title="Your Details" >
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label>First Name</label>
                        <input type="text" onChange={handleChange} value={myAccount.firstName} className="form-control" name="firstName" placeholder="First Name" disabled={true} />
                        <small className="errorMessage">{errors.firstName}</small>
                    </div>
                    <div className="form-group col-md-6">
                        <label>Last Name</label>
                        <input type="text" onChange={handleChange} value={myAccount.lastName} className="form-control" name="lastName" placeholder="LastName" disabled={true} />
                        <small className="errorMessage">{errors.lastName}</small>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label>Email</label>
                        <input type="text" onChange={handleChange} value={myAccount.email} className="form-control" name="email" placeholder="Email" disabled={editMode} />
                        <small className="errorMessage">{errors.email}</small>
                    </div>
                    <div className="form-group col-md-6">
                        <label>Phone Number</label>
                        <input type="text" onChange={handleChange} defaultValue={myAccount.phoneNumber} className="form-control" name="phoneNumber" placeholder="Phone Number" disabled={editMode} />
                    </div>
                </div>
                {editMode ?
                    <PrimaryButton text="Edit" func={toggleEditMode} classes="float-right mt-2" />
                    :
                    <>
                        <Button text="Update Details" func={updateUserDetails} classes="btn-success float-right mt-2" />
                        <PrimaryButton text="Cancel" func={toggleEditMode} classes="float-right mt-2 mr-3" />
                    </>
                }
            </Card>
        </div>
    );
};

export default UpdateUserDetails;