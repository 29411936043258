import React, { useState } from 'react';
import Header from '../../Shared/Components/Header/Header';
import Card from '../../Shared/Components/Card/Card';
import PrimaryButton from '../../Shared/Components/PrimaryButton/PrimaryButton';
import { createCommunityAsync } from '../../Services/CommunityServie';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const CreateCommunity = () => {
    const [state, setState] = useState({});
    const history = useHistory();
    const handleChange = evt => setState({ ...state, [evt.target.name]: evt.target.value });
    const handleSubmit = async () => {
        let storedUser = JSON.parse(window.localStorage.getItem("user"));

        await createCommunityAsync({
            ...state,
            clientId: storedUser.clientId
        })

        history.push("/communities");
        window.location.reload();

    }
    return (
        <>
            <Header heading="Create Community" subheading="Create new Community" icon="pencil-alt" />
            <div className="container-fluid content">
                <Card title="Create new">
                    <div className="row">
                        <div className="col-12">
                            <div className="form">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input className="form-control" name="name" id="name" onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea style={{ "minHeight": "150px" }} className="form-control" name="description" id="description" onChange={handleChange} />
                                </div>
                                <PrimaryButton text="Create" func={handleSubmit} classes="float-right mt-3" />

                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default CreateCommunity;