import React, { useEffect, useState } from 'react';
import { getInsightsAsync } from '../../Services/ReportService';
import Header from '../../Shared/Components/Header/Header';
import Card from '../../Shared/Components/Card/Card';
import { clientListAsync } from '../../Services/AdminService';
import './Insights.scss';

const Insights = () => {

    const [clientId, setClientId] = useState('');
    const [data, setData] = useState({ questions: [], genders: [], ethnicities: [], jobRoles: [], employmentStatuses: [] });
    const [clients, setClients] = useState([]);

    useEffect(() => {
        let getData = async function () {
            let response = await getInsightsAsync(clientId)
            setData(response)
        }

        getData();

    }, [clientId])

    useEffect(() => {
        let getClients = async function () {
            let response = await clientListAsync();
            setClients(response.clients)
        }

        getClients();

    }, [])

    const handleChange = evt => {
        setClientId(evt.target.value);
    }

    return (
        <>
            <Header heading="User insights" subheading="User analytics and insights" icon="stats-up" />
            <div className="container-fluid content insights">
                <Card title="Select client">
                    <div className="row">
                        <div className="col-12 pb-3">
                            <select className="form-control" value={clientId} onChange={handleChange}>
                                <option value="">All</option>
                                {clients.map(c => (
                                    <option key={c.id} value={c.id}>{c.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </Card>
                <Card title="Sign up questions">
                    <div className="row">
                        <div className="col-12">
                            {data.questions.map((q, i) => (
                                <p key={i}>{q.name}
                                    <span className="yes">{q.numberOfYes}</span>
                                    <span className="no"> {q.numberOfNo}</span>
                                </p>
                            ))}
                        </div>
                    </div>
                </Card>
                <Card title="Genders">
                    <div className="row">
                        <div className="col-12">
                            {data.genders.map(g => (
                                <p key={g.id}>{g.name}
                                    <span>{g.count}</span>
                                </p>
                            ))}
                        </div>
                    </div>
                </Card>
                <Card title="Ethnicities">
                    <div className="row">
                        <div className="col-12">
                            {data.ethnicities.map(e => (
                                <p key={e.id}>{e.name} <span>{e.count}</span></p>
                            ))}
                        </div>
                    </div>
                </Card>
                <Card title="Job roles">
                    <div className="row">
                        <div className="col-12">
                            {data.jobRoles.map(jr => (
                                <p key={jr.id}>{jr.name}  <span>{jr.count}</span></p>
                            ))}
                        </div>
                    </div>
                </Card>
                <Card title="Employment statuses">
                    <div className="row">
                        <div className="col-12">
                            {data.employmentStatuses.map(es => (
                                <p key={es.id}>{es.name}  <span>{es.count}</span></p>
                            ))}
                        </div>
                    </div>
                </Card>
            </div >
        </>
    );
};

export default Insights;