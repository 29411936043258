import axios from 'axios';
import { baseUrl } from '../Infrastructure/Config/ApiConfig';
import { token } from './AuthService';

const base = `${baseUrl()}Wellness`;

export const getAllWellnessResultsAsync = async () => {
    const response = await axios.get(`${base}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });

    return response.data;
}

export const getOrganisationsAsync = async () => {
    const response = await axios.get(`${base}/GetOrganisations`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getWellnessResultsByEmailAsync = async email => {
    const response = await axios.get(`${base}/Email/${email}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}

export const getWellnessResultsByOrgAsync = async orgName => {
    const response = await axios.get(`${base}/org/${orgName}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}