import React, { useEffect, useState } from 'react';
import { getAllFeedbackFormsAsync } from '../../../Services/FeedbackFormService';
import Card from '../../../Shared/Components/Card/Card';
import Header from '../../../Shared/Components/Header/Header';
import Button from './../../../Shared/Components/Button/Button';
import Table from '../../../Shared/Components/Table';
import { Link } from 'react-router-dom';

const FeedbackForms = () => {

    const [feedbackForms, setFeedbackForms] = useState([]);

    const headings = () => {
        return (<>
            <th>Title</th>
            <th>Description</th>
            <th></th>
            <th></th>
        </>)
    }

    const rowData = () => {
        return (
            <>
                {feedbackForms.length > 0 ?
                    feedbackForms.map(
                        (f, i) =>
                            <tr key={i}>
                                <td>
                                    {f.title}
                                </td>
                                <td>
                                    {f.description}
                                </td>
                                <td>
                                    <Link to={`/feedback-stats/${f.id}`}>
                                        <span className="view fas fa-chart-bar float-right mr-3" title="Results"></span>
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/feedback-form/${f.id}`}>
                                        <span className="view fas fa-eye float-right mr-3" title="View"></span>
                                    </Link>
                                </td>

                            </tr>
                    )
                    :
                    <tr>
                        <td className="no-data" colSpan="4">
                            No feedbackforms exist
                        </td>
                    </tr>
                }
            </>)
    }

    useEffect(() => {
        const getForms = async () => {
            let response = await getAllFeedbackFormsAsync();
            setFeedbackForms(response.feedbackForms);
        };

        getForms();
    }, [])



    return (
        <>
            <Header heading={"Feedback forms"} subheading={"Manage feedback forms"} icon="book" />
            <div className="container-fluid content">
                <Card>
                    <div className="float-left">
                        <Button text="Create New" classes="btn btn-success" link="/feedback-form" />
                    </div>
                </Card>
                <Card title="Feedback forms">
                    <Table headings={headings} rowData={rowData} />
                </Card>
            </div>
        </>
    );
};

export default FeedbackForms;