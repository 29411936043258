import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllClientsAsync } from '../../Services/AdminService';
import Table from '../../Shared/Components/Table';
import Header from './../../Shared/Components/Header/Header';
import Card from './../../Shared/Components/Card/Card';
import Button from './../../Shared/Components/Button/Button';
import PaginationControls from '../../Shared/Components/PaginationControls';

const Clients = () => {
    const [clients, setClients] = useState([]);
    const [state, setState] = useState({ pageNumber: 1, totalPages: 1 });

    useEffect(() => {
        const getClients = async () => {
            let response = await getAllClientsAsync(state.pageNumber);
            setClients(response.clients);
            setState(s => ({ totalPages: response.totalPages, pageNumber: state.pageNumber }))
        }
        getClients();
    }, [state.pageNumber])


    const next = () => {
        setState({ ...state, pageNumber: state.pageNumber + 1 })
    }

    const previous = () => {
        setState({ ...state, pageNumber: state.pageNumber - 1 })
    }

    const headings = () => {
        return (<>
            <th>Name</th>
            <th>Referal code</th>
            <th>Learner limit</th>
            <th className="text-right">Action</th>
        </>)
    }

    const rowData = () => {
        return (
            <>
                {clients.length > 0 ?
                    clients.map(
                        (c, i) =>
                            <tr key={i}>
                                <td>
                                    {c.name}
                                </td>
                                <td>
                                    {c.referalCode}
                                </td>
                                <td>
                                    {c.maximumNumberOfLearners === 0 ? <h3>&infin;</h3> : c.maximumNumberOfLearners}
                                </td>
                                <td>
                                    <Link to={`/client/${c.urlToken}`}>
                                        <span className="view fas fa-eye float-right mr-3" title="View"></span>
                                    </Link>
                                </td>
                            </tr>
                    )
                    :
                    <tr>
                        <td className="no-data" colSpan="4">
                            No clients to currently display
                        </td>
                    </tr>
                }
            </>)
    }

    return (
        <>
            <Header heading="Clients" subheading="List of all current clients" icon="customer" />
            <div className="container-fluid content">
                <Card>
                    <div className="float-left">
                        <Button text="Create New" classes="btn btn-success" link="/create-client" />
                    </div>
                </Card>
                <Card>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group float-left">
                                <input className="form-control" placeholder="Client name..." />
                            </div>
                            <div className="form-group float-right ml-3">
                                <select className="form-control" defaultValue="10" name="pageSize">
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={250}>250</option>
                                    <option value={500}>500</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <Table headings={headings} rowData={rowData} />
                    <PaginationControls next={next} previous={previous} total={state.totalPages} current={state.pageNumber} />
                </Card>
            </div>
        </>
    );
};

export default Clients;