import axios from 'axios';
import { baseUrl } from '../Infrastructure/Config/ApiConfig';
import { token } from './AuthService';

const controllerName = 'Email';

export const getAllEmailsAsync = async (pageNumber, toAddress) => {
    const response = await axios.get(`${baseUrl()}${controllerName}/getAllEmails?pageNumber=${pageNumber}&toAddress=${toAddress}`, {
        headers: {
            'Authorization': `Bearer ${token()}`
        }
    });
    return response.data;
}