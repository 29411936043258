import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllEnquiriesAsync } from '../../Services/ContactFormService';
import Card from '../../Shared/Components/Card/Card';
import Header from '../../Shared/Components/Header/Header';
import Table from '../../Shared/Components/Table';
import LineIcon from 'react-lineicons';
import moment from 'moment';

const Enquiries = () => {
    const [formSubmissions, setFormSubmissions] = useState([]);

    useEffect(() => {
        getEnquiries();
    }, []);

    const getEnquiries = async () => {
        let response = await getAllEnquiriesAsync();
        setFormSubmissions(response.enquiries);
    }

    const headings = () => {
        return (<>
            <th>Name</th>
            <th>Company</th>
            <th>Received</th>
            <th>Read</th>
            <th></th>
        </>)
    }

    const rowData = () => {
        return (
            <>
                {formSubmissions.length > 0 ?
                    formSubmissions.map(
                        (c, i) =>
                            <tr key={i}>
                                <td>
                                    {c.name}
                                </td>
                                <td>
                                    {c.company}
                                </td>
                                <td>
                                    {moment(c.createdDateTime).format("DD MMMM yyyy, hh:mm a")}
                                </td>
                                <td>
                                    {c.readDateTime ?
                                        <LineIcon name="checkmark" style={{ color: "green" }} />
                                        : <LineIcon name="close" style={{ color: "red" }} />
                                    }
                                </td>
                                <td>
                                    <Link to={`/enquiry/${c.id}`}>
                                        <span className="view fas fa-eye float-right mr-3" title="View"></span>
                                    </Link>
                                </td>
                            </tr>
                    )
                    :
                    <tr>
                        <td className="no-data" colSpan="5">
                            No enquiries to currently display
                        </td>
                    </tr>
                }
            </>)
    }

    return (
        <>
            <Header heading="Enquiries" subheading="List of all current enquiries recieved from the website" icon="comments" />
            <div className="container-fluid content">
                <Card>
                    <div className="col-12 p-0">
                        <div className="form-group float-left">
                            <input className="form-control" placeholder="Search company..." />
                        </div>
                        <div className="form-group float-right">
                            <p>No. of enquiries: {formSubmissions.length}</p>
                        </div>
                    </div>
                    <Table headings={headings} rowData={rowData} />
                </Card>
            </div>
        </>
    );
};

export default Enquiries;