import React from 'react';
import { Link } from 'react-router-dom';
import './CompletionBadge.scss';

const CompletionBadge = ({ completed, image, courseId }) => {
    return (
        <Link to={`/engage/${courseId}`}>
            {completed ?
                <img alt={"Completed"} className="img-fluid complete" src={image} title="Completed" />
                :
                <img alt={"Incomplete"} className="img-fluid incomplete" src={image} title="Incomplete" />
            }
        </Link>
    );
};

export default CompletionBadge;