import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { createNewFeedbackFormAsync } from '../../../Services/FeedbackFormService';
import Card from '../../../Shared/Components/Card/Card';
import Header from '../../../Shared/Components/Header/Header';
import Button from './../../../Shared/Components/Button/Button';

const CreateFeedbackForm = () => {

    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");

    const history = useHistory();

    const handleChange = event => {
        setDescription(event.target.value);
    }

    const handleTitleChange = event => {
        setTitle(event.target.value);
    }

    const createForm = async () => {

        let dataToPost = {
            title: title,
            description: description
        }

        let response = await createNewFeedbackFormAsync(dataToPost);
        history.push(`feedback-form/${response.id}`);
    }

    return (
        <>
            <Header heading={"Feedback form"} subheading={"Create new feedback form"} icon="book" />
            <div className="container-fluid content">
                <Card title="details">
                    <div className="row">
                        <div className="col-12">
                            <div className="form">
                                <div className="form-group">
                                    <label>Title</label>
                                    <input className="form-control" name="Title" type="text" onChange={handleTitleChange} value={title} />
                                </div>
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea className="form-control" name="Description" type="text" onChange={handleChange} value={description} />
                                </div>
                                <Button classes="btn-primary float-right" func={createForm} text="Create" />
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default CreateFeedbackForm;