import React from 'react';
import Card from '../../../../Shared/Components/Card/Card';

const FreeTextQuestion = ({ q, deleteQuestion }) => {
    return (
        <Card>
            <div className="row question">
                <div className="col-12 ">
                    <strong className='mb-0'>
                        <div className="float-left drag">
                            <span className="icon clickable float-right fa fa-bars mr-3" title="Drag"></span>
                        </div>
                        <div>
                            <p>
                                {q.value} <span>(Free text)</span>
                                <span onClick={() => { deleteQuestion(q.id) }} title="Delete" className='fa fa-trash ml-3 clickable delete float-right'></span>
                            </p>
                        </div>
                    </strong>
                </div>
            </div>
        </Card>
    );
};

export default FreeTextQuestion;