import React, { useContext } from 'react';
import { ThemeContext } from './../../../Infrastructure/Context/ThemeContext';
import './Header.scss';
import LineIcon from 'react-lineicons';
import { useHistory } from 'react-router';

const Header = ({ heading, subheading, icon, showClose }) => {
    const context = useContext(ThemeContext);
    const history = useHistory();

    return (
        <div className="row no-gutters page-heading text-center text-sm-left">
            <div className="icon">
                <LineIcon name={icon} style={{ color: context.iconColour }} />
            </div>
            <div className="heading">
                <h1>{heading}</h1>
                <p>{subheading}</p>
            </div>
            {showClose &&
                <div className="close">
                    <span className="float-right clickable" onClick={history.goBack}><LineIcon size='xs' name="close" style={{ color: context.primaryButtonColour }} /></span>
                </div>
            }
        </div>
    );
};

export default Header;