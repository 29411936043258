import React, { useContext } from 'react';
import { ThemeContext } from '../../../Infrastructure/Context/ThemeContext';
import './Card.scss';

const Card = (props) => {
    const context = useContext(ThemeContext);

    return (
        <div className={`card ${props.classes}`}>
            {props.title &&
                <div className="card-heading" style={{ color: context.iconColour }}>
                    {props.title}
                    {props.close &&
                        <span className="fa fa-close float-right clickable" onClick={props.close}></span>
                    }
                </div>
            }
            <div className="card-body">
                {props.children}
            </div>
        </div>

    );
};

export default Card;