import React from 'react';
import { NavLink } from 'react-router-dom';
import LineIcon from 'react-lineicons';
import { blackOrWhite, menuHover } from '../../../../Infrastructure/ManipulateColors';

const DashboardNav = props => {
    return (
        <li style={{ "--hover-color": menuHover(props.context.primaryNavColour) }}>
            <NavLink activeClassName='sidebar-active' to="/" style={{ width: "100%" }}>
                <LineIcon name="dashboard" style={{ color: props.context.sidebarIconColour }} />
                <span className="item" style={{ color: props.context.primaryNavColour ? blackOrWhite(props.context.primaryNavColour) : "black" }}>Dashboard</span>
            </NavLink>
        </li>
    );
};

export default DashboardNav;