import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../../Infrastructure/Context/ThemeContext';
import './PrimaryButton.scss';
import { manipulateContrast } from '../../../Infrastructure/ManipulateColors';

const PrimaryButton = ({ link, func, text, disabled, classes, children, properties }) => {
    const context = useContext(ThemeContext);

    return (
        <>
            {link === undefined ?
                <button
                    className={`btn btn-primary primary-button ${classes}`}
                    style={{
                        backgroundColor: context.primaryButtonColour,
                        color: context.primaryNavFontColour,
                        border: `1px solid ${context.primaryButtonColour}`,
                        "--hover-color": manipulateContrast(context.primaryButtonColour),
                        "--hover-text-color": context.primaryNavFontColour
                    }}
                    onClick={func}
                    disabled={disabled}
                >
                    {children}
                    {text}
                </button>
                :
                <Link to={{ pathname: link, state: properties }}>
                    <button
                        className={`btn btn-primary primary-button ${classes}`}
                        style={{
                            backgroundColor: context.primaryButtonColour,
                            color: context.primaryNavFontColour,
                            border: `1px solid ${context.primaryButtonColour}`,
                            "--hover-color": manipulateContrast(context.primaryButtonColour),
                            "--hover-text-color": context.primaryNavFontColour
                        }}
                    >
                        {text}
                    </button>
                </Link>
            }
        </>
    );
};

export default PrimaryButton;